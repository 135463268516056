export { default as sample } from './sample.json';
export { default as admin } from './admin.json';
export { default as common } from './common.json';
export { default as login } from './login.json';
export { default as backup } from './backup.json';
export { default as signUp } from './signUp.json';
export { default as resetPassword } from './resetPassword.json';
export { default as createConfiguration } from './createConfiguration.json';
export { default as forgot } from './forgot.json';
export { default as app } from './app.json';
export { default as apps } from './apps.json';
export { default as build } from './build.json';
export { default as role } from './role.json';
export { default as project } from './project.json';
export { default as document } from './document.json';
export { default as configuration } from './configuration.json';
export { default as media } from './media.json';
export { default as payment } from './payment.json';
export { default as pdfTemplate } from './pdf-template.json';
export { default as staticPage } from './static-page.json';
export { default as systemPage } from './system-page.json';
export { default as template } from './template.json';
export { default as user } from './user.json';
export { default as support } from './support.json';
export { default as header } from './header.json';
export { default as crud } from './crud.json';
export { default as dashboard } from './dashboard.json';
export { default as demos } from './demos.json';
export {default as language } from './language.json';
export { default as sms } from './sms.json';
export {default as theme } from './theme.json';
export {default as permission } from './permission.json';
export {default as parts } from './parts.json';
export {default as products } from './products.json';
export {default as productServices } from './product-services.json';
export {default as seo } from './seo.json';
export {default as register} from './register.json';
export {default as test } from './test.json';
export {default as sider } from './sider.json';
export {default as days } from './days.json';