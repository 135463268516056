import React from "react";
import ApiTrigger from "../containers/api-trigger";
import { api } from "../redux/config";
import DuplicateForm from "containers/duplicate-entity";
export default {
  entityName: "Configurations",
  entity: "configuration",
  pagination: {
    type: "server",
  },
  selection: false,
  addAction: false,
  defaultSort: {
    sort: "createdAt",
    sortType: "desc",
  },
  deleteAction: false,
  editAction: !window.app.isDemoEnabled,
  rowActions: [
    (props) => {
      const { record, getData } = props;
      const { key } = record;
      return (
        <DuplicateForm
          entityName="configuration"
          entityId={key}
          getData={getData}
          disabled={window.app.isDemoEnabled}
          disabledText={window.app.demoDisabledText}
        />
      );
    },
  ],
  columns: ({ sharedState: { translate } }) => {
    return [
      {
        dataIndex: "key",
        title: translate("configuration.key.label"),
      },
      {
        title: translate("configuration.type.label"),
        dataIndex: "type",
      },
      {
        title: translate("configuration.value.label"),
        dataIndex: "value",
        render: (value, { type }) => {
          return type === "file" ? (
            <div>
              <a target="_blank" href={value}>
                Download
              </a>
              <div className="image">
                <img
                  src={value}
                  style={{
                    width: 100,
                  }}
                />
              </div>
            </div>
          ) : (
            <div>
              {value !== undefined && value !== null ? value.toString() : ""}
            </div>
          );
        },
      },
      {
        title: translate("configuration.description.label"),
        dataIndex: "description",
      },
    ];
  },
  search: {
    filters: [
      {
        title: "Key",
        type: "input",
        key: "key",
      },
    ],
    searchOnSubmit: true,
  },
  headerActions: [
    () => (
      <ApiTrigger
        url={`${
          window.app.basename ? "/" + window.app.basename : ""
        }/refresh-config`}
        title="Refresh Config"
        icon="sync"
        successMessage="Refreshing Config initiated. Might take about a minute"
      />
    ),
    () => (
      <ApiTrigger
        url={api.REFRESH_INDEX}
        title="Refresh Indexes"
        icon="sync"
        successMessage="Refreshing indexes initiated. Might take about a minute"
      />
    ),
    () => (
      <ApiTrigger
        url={api.REFRESH_CACHE}
        title="Refresh Cache"
        icon="sync"
        successMessage="Refreshing Cache initiated. Might take about a minute"
      />
    ),
  ],
  formId: "ConfigurationForm",
};
