import React, { Component } from "react";
import ComponentView from "./view";
import preProcess from "containers/preprocess";
import { goToRoute } from "../../routes";
import { mobileAndTabletcheck } from "../../utils/web";
import { createAction, ActionNames } from "../../redux/actions";
import MenuKeys from './config'
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      menuKeys: []
    };
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }

  /**
   * ComponentDidMount Hook
   */
  componentDidMount() {
    this.attachSwipeGestures();
  }
  attachSwipeGestures() {
    const { setDrawer } = this.props;
    if (mobileAndTabletcheck()) {
      /**
       * Hammer is not compatible
       */
      if (window.document) {
        const Hammer = require("hammerjs");
        let hammerObj = new Hammer(document.querySelector(".home-layout"));
        hammerObj.on("swipeleft", e => {
          this.closeDrawer();
        });
        hammerObj.on("swiperight", e => {
          this.openDrawer();
        });
        hammerObj = new Hammer(
          document.querySelector(".ant-layout-sider-children")
        );
        hammerObj.on("swipeleft", e => {
          this.closeDrawer();
        });
        hammerObj.on("swiperight", e => {
          this.openDrawer();
        });
      }
    }
  }

  openDrawer() {
    const Hammer = require("hammerjs");
    const { setDrawer } = this.props;
    setDrawer(true);
    setTimeout(() => {
      let hammerObj = new Hammer(document.querySelector(".home .overlay"));
      hammerObj.on("swipeleft", e => {
        this.closeDrawer();
      });
    });
  }

  closeDrawer() {
    const { setDrawer } = this.props;
    setDrawer(false);
  }

  /**
   * onClick menu navigate to specific route
   * @returns {*}
   */
  handleMenuClick(e) {
    goToRoute(e.key);
    mobileAndTabletcheck() && this.closeDrawer();
  }

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = dispatch => {
  return {
    setDrawer: drawer => {
      return dispatch(
        createAction(ActionNames.UI_SET_PROPERTY, {
          name: "drawer",
          value: drawer
        })
      );
    }
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ user, ui }) => {
  const { permissions,role } = user || {};
  const { drawer } = ui;
  return {
    permissions,
    drawer,
    role
  };
};
Main.displayName = "Sider-Component";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true
});
