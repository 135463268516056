import React from 'react'
import './styles.scss'
import { Col, Icon, Row } from 'antd'
import { getAllISOCodes } from 'iso-country-currency'
import { nFormatter } from '../../utils/common'
import Link from '../../components/link'

var view = function () {
    const {dashboard, CURRENCY, enableClick} = this.props;
    const {data} = this.state;
    const {symbol} =
    getAllISOCodes().find((item) => {
        return item.currency === CURRENCY
    }) || {}
    const array = [
        {
            icon: 'bar-chart',
            label: 'Paid Invoices',
            color: 'green',
            ...data.paid
        },
        {
            icon: 'bar-chart',
            label: 'Unpaid Invoices',
            color: 'primary',
            ...data.unpaid
        },
        {
            icon: 'bar-chart',
            label: 'Overdue Invoices',
            color: 'red',
            ...data.overdue
        }
    ]
    return (
        <React.Fragment>
            {array.map((item, index) => {
                const {icon, label, count, amount, color} = item
                return (
                    <Col key={index} xs={24} sm={12} md={12} lg={12} xl={6}>
                        <Link routeKey={enableClick ? 'home.invoice' : undefined}>
                            <div className={'card ' + color}>
                                <div className="info">
                                    <div className="icon">
                                        <Icon type={icon}/>
                                    </div>
                                    <div className="details">
                                        <p className="count">{symbol + nFormatter(amount || 0)}</p>
                                        <p className="label">
                                            {count ? count : 0} {label}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </Col>
                )
            })}
        </React.Fragment>
    )
}
export default view
