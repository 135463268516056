import React from 'react'
import { Button } from 'antd'
//import './styles.scss';
import razorpay from '../../../../images/razorpay.png'

/**
 * Returns the JSX Markup for the view
 * @returns {XML}
 */
var view = function () {
    return (
        <div className="gateway">
            <p className="label">Pay with Razorpay</p>
            <Button className="razorpay" onClick={this.onPay}>pay with <img src={razorpay} alt={"Razorpay"}/></Button>
        </div>
    )
}
export default view
