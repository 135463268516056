import React from "react";
import { convertPriceToCurrency } from "containers/container-helpers/common";
import CsvGenerator from "containers/csv-generator";
import CsvUpload from "containers/csv-upload";
function swap(json) {
  var ret = {};
  for (var key in json) {
    ret[json[key]] = key;
  }
  return ret;
}
const keys = {
  key: "Part Id (Unique not be edited, Leave blank for new record)",
  partKey: "Part Code",
  partName: "Description",
  partCost: "Part Cost",
  supplier: "Supplier",
  supplierCode: "Supplier Code",
  quantity: "Quantity",
  customerMarkup: "Customer Markup (%age)",
};
export default {
  entity: "parts",
  entityName: "Parts",
  formId: "PartForm",
  pagination: {
    type: "server",
  },
  defaultSort: {
    sort: "createdAt",
    sortType: "desc",
  },
  search: {
    searchOnSubmit: true,
    filters: [
      {
        title: "Name",
        type: "input",
        key: "name",
      },
      {
        title: "Part Code",
        type: "input",
        key: "partKey",
      },
    ],
  },
  columns: ({ sharedState }) => {
    return [
      {
        dataIndex: "partKey",
        title: "Part Code",
      },
      {
        dataIndex: "partName",
        title: "Part Description",
      },
      {
        dataIndex: "partCost",
        title: "Part Cost",
        render: convertPriceToCurrency,
      },
      {
        dataIndex: "supplier",
        title: "Supplier",
      },
      {
        dataIndex: "supplierCode",
        title: "Supplier Code",
      },
      {
        dataIndex: "customerMarkup",
        title: "Customer Markup",
      },
      ...(sharedState.role === "ENGINEER"
        ?[{
            dataIndex: "quantity",
            title: "Quantity",
            render : (item)=>{
              return (item || []).find(({userId})=>{
                return userId === sharedState.user.uid
              }).quantity
            }
          }]
        : []),
    ];
  },
  headerActions: (parentProps) => [
    (props) => {
      return parentProps.role === "admin" ? (
        <CsvUpload
          api={"parts/importCsv"}
          columns={swap(keys)}
          formatData={(item) => {
            return {
              ...item,
              quantity: (item.quantity || "")
                .trim()
                .split(",")
                .map((item) => {
                  const parts = item.split("-");
                  return {
                    userId: parts[0],
                    quantity: parseInt(parts[1]),
                  };
                }),
            };
          }}
          onSuccess={parentProps.getTableData}
          onError={parentProps.getTableData}
        />
      ) : null;
    },
    (props) => {
      return (
        <CsvGenerator
          api={"parts"}
          columns={keys}
          query={{
            ...parentProps.getRequestQuery(),
            from: -1,
          }}
          formatData={(data) => {
            return data.map((item) => {
              return {
                ...item,
                quantity: (item.quantity || [])
                  .map(
                    ({ user, quantity }) =>
                      `${user ? user.email : "User not found"}-${quantity}`
                  )
                  .join(","),
              };
            });
          }}
        />
      );
    },
  ],
};
