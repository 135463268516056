import React from "react";
import "./styles.scss";
import { Button, Input, Form,Select } from "antd";

const { TextArea } = Input;
const { Item } = Form;
var view = function () {
  const { form, record } = this.props;
  const { getFieldDecorator } = form;
  const { billTo, email, phoneNo } = this.validations;
  return (
    <div className="billto">
      <div className="form">
        <Form onSubmit={this.handleSubmit}>
          <Item hasFeedback={true} label="Bill To">
            {getFieldDecorator(
              "billTo",
              billTo
            )(<TextArea placeholder="Bill To" />)}
          </Item>
          <div className="inline">
            <Item hasFeedback={true} label="Email">
              {getFieldDecorator(
                "email",
                email
              )(
                <Select className="select" placeholder="Select Email">
                  {[record.email, ...(record.emailAddresses || [])].map(
                    (item, index) => {
                      return (
                        <Select.Option key={index} value={item}>
                          {item}
                        </Select.Option>
                      );
                    }
                  )}
                </Select>
              )}
            </Item>
            {/* <Item hasFeedback={true} label="Phone Number">
                            {getFieldDecorator(
                                'phoneNo',
                                phoneNo
                            )(<Input className={'input'} placeholder="Phone Number"/>)}
                        </Item> */}
          </div>
          <div className="actions">
            <Button type="primary" className="btn green-btn" htmlType="submit">
              Send
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default view;
