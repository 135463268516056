import React, { Component } from 'react'
import ComponentView from './view'
import preProcess from '../preprocess'
import { createAction, ActionNames } from '../../redux/actions'
import { getCurrentRoute } from '../container-helpers/routing'

/**
 * @description Home Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {

    /**
     * Container
     * @param props
     */
    constructor (props) {
        super(props)
        this.state = {
            confirm: false
        }
        this.getCurrentRoute = getCurrentRoute.bind(this)
    }

    closeDrawer () {
        const {toggleDrawer, drawer} = this.props
        toggleDrawer(drawer)
    }

    /**
     * ComponentDidMount Hook
     */
    async componentDidMount () {
    }

    /**
     * Render Method
     * @returns {*}
     */
    render () {
        return (ComponentView.bind(this))()
    }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = dispatch => {
    return {
        toggleDrawer: (drawer) => {
            return dispatch(createAction(ActionNames.UI_SET_PROPERTY, {
                name: 'drawer',
                value: !drawer
            }))
        },
    }
}
/**
 * Bind State to props
 * @returns {{Object}}
 * @param state
 */
const mapStateToProps = (state) => {
    return {
        horizontalMenu: state.ui.horizontalMenu,
        drawer : !!state.ui.drawer
    }
}
Main.displayName = 'Home'
//Pre process the container with Redux Plugins
export default preProcess(Main, {
    connect: [mapStateToProps, bindAction],
    localize: true,
    firebase: true
})
