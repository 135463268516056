import React from "react";
import "./styles.scss";
import {
  Form,
  Input,
  InputNumber,
  Icon,
  Button,
  Select,
  DatePicker,
  Row,
  Col,
} from "antd";
import FilePicker from "core-components/file-picker";
import CustomerForm from "../customer-form";
import SignatureCapture from "core-components/signature-capture";
import { getAllISOCodes } from "iso-country-currency";
import AutoSearch from "../../autoSearch";
import ClientSearch from "../../clientSearch";
import { parseJSON } from "utils/web";
import { isUserAllowed } from "../../with-security";
import {
  APPLIANCE,
  APPLIANCE_TYPE,
  CATEGORY,
  CONNECTION,
  JOB_STATUSES,
  QUOTE_STATUSES,
  SERVICE_TYPES,
} from "../../container-helpers/service-job";
import { isPrivateCustomer } from "../../container-helpers/customer";
import MultiRecordForm from "../../multi-record-form";
import moment from "moment";

const { TextArea } = Input;
const { Item } = Form;
const { Option } = Select;

function disabledDate(current) {
  // Can not select days before today and today
  return current < moment().startOf("day");
}

var view = function () {
  this.setValidations();
  const {
    form,
    hideModal,
    data,
    translate,
    fetchingCustomers,
    view,
    role,
    permissions,
    CURRENCY,
  } = this.props;
  const { symbol } =
    getAllISOCodes().find((item) => {
      return item.currency === CURRENCY;
    }) || {};
  const { customers } = this.state;
  const {
    customer,
    key,
    date,
    jobStatus,
    generalRemarks,
    discount,
    siteAddress,
    customerJobNumber,
    customerPoNumber,
    manualAdjustment,
    jobNumber
  } = this.validations;
  const { getFieldDecorator, getFieldValue } = form;
  let initialCustomerValue = "";
  if (data) {
    initialCustomerValue =
      typeof data.customer === "object" ? "create-new" : data.customer;
  }
  const jobStatuses = [...QUOTE_STATUSES];
  const customerValue = getFieldValue("customer") || initialCustomerValue;
  let productValue = getFieldValue("product") || (data && data.product);
  if (productValue) {
    productValue = parseJSON(productValue);
  }

  const isTradeCustomer = !isPrivateCustomer(this.state.customerType);
  const disabled = view;
  const partsValue = getFieldValue("parts") || (data && data.parts) || [];
  const array = [
    ...partsValue.map((item) => {
      const { partName, partCost,quantity } = item;
      return {
        label: partName,
        value: parseFloat(isNaN(partCost) ? 0 : partCost) * (isNaN(quantity) ? 0 : quantity),
      };
    }),
  ];

  const adjustment = getFieldValue("manualAdjustment") || 0;
  const discountValue = getFieldValue("discount") || 0;
  const total = array.reduce((sum, item) => {
    const { value } = item;
    return sum + value;
  }, 0);
  return (
    <div className="new-form form quote">
      <Form onSubmit={this.handleSubmit.bind(this)}>
        <div className="section">
          <p className="label">Quote Details</p>
          <div className="content">
            <div className="inline">
            <Item hasFeedback={true} label="Quote Number">
                {getFieldDecorator(
                  "key",
                  key
                )(
                  <Input
                    className="input"
                    disabled={disabled}
                    placeholder="Enter Quote Number"
                  />
                )}
              </Item>
              <Item hasFeedback={true} label="ASG Job Number">
                {getFieldDecorator(
                  "jobNumber",
                  jobNumber
                )(
                  <Input
                    className="input"
                    disabled={disabled}
                    placeholder="Enter Asg Job Number"
                  />
                )}
              </Item>
              <Item hasFeedback={true} label="Customer Job Number">
                {getFieldDecorator(
                  "customerJobNumber",
                  customerJobNumber
                )(
                  <Input
                    disabled={disabled}
                    className="input"
                    placeholder="Enter Customer Job Number"
                  />
                )}
              </Item>
              <Item hasFeedback={true} label="Customer PO Number">
                {getFieldDecorator(
                  "customerPoNumber",
                  customerPoNumber
                )(
                  <Input
                    disabled={disabled}
                    className="input"
                    placeholder="Enter Customer PO Number"
                  />
                )}
              </Item>
            </div>
            <div className="inline">
              <Item hasFeedback={true} label="Date Added">
                {getFieldDecorator(
                  "date",
                  date
                )(<DatePicker disabled={disabled} />)}
              </Item>
            </div>

            <div className="inline">
              <Item hasFeedback={true} label="Customer Name">
                {getFieldDecorator(
                  "customer",
                  customer
                )(
                  <Select
                    showSearch={true}
                    onSearch={this.onSearch}
                    onChange={this.getCustomerDetails}
                    disabled={disabled}
                    className="select"
                    placeholder="Search by Name or Phone"
                    showArrow={false}
                    filterOption={false}
                    loading={fetchingCustomers}
                  >
                    {[
                      ...(customers || []).map((customer, index) => {
                        const { key, customerName } = customer;
                        return (
                          <Option key={index} value={key}>
                            {customerName + " (" + key + ")"}
                          </Option>
                        );
                      }),
                    ]}
                    {isUserAllowed(
                      {
                        permissions: ["CUSTOMER_CREATE"],
                      },
                      {
                        permissions,
                        role,
                      }
                    ) && (
                      <Option
                        placeholder="Search Customer By Mobile"
                        value={"create-new"}
                        key="create-new"
                      >
                        {"Or Create New Customer"}
                      </Option>
                    )}
                  </Select>
                )}
              </Item>
            </div>
            {(customerValue === "create-new" || view || disabled) && (
              <div className="appended-form">
                <p>Customer Form</p>
                <CustomerForm
                  service={true}
                  disabled={disabled}
                  translate={translate}
                  onProductChange={(products) => {
                    this.setState({ customerProducts: products });
                  }}
                  onCustomerTypeChange={(type) => {
                    this.setState({ customerType: type });
                  }}
                  data={(function () {
                    try {
                      if (!data) {
                        return;
                      }
                      let customer = {
                        ...data,
                      };
                      if (typeof data.customer === "string") {
                        let customerData = customers.find(
                          (item) => item.key === data.customer
                        );
                        customer = {
                          ...customerData,
                          ...data,
                        };
                      } else {
                        customer = {
                          ...customer,
                          ...data.customer,
                        };
                      }
                      if (data.customerKey) {
                        customer = {
                          ...customer,
                          key: data.customerKey,
                        };
                      }
                      return customer;
                    } catch (e) {
                      console.log(e);
                    }
                  })()}
                  wrappedComponentRef={(ref) => {
                    this.customerFormRef = ref;
                  }}
                />
              </div>
            )}
            {/* <div className="inline">
              {isTradeCustomer && (
                <Item hasFeedback={true} label="Site Address">
                  {getFieldDecorator(
                    "siteAddress",
                    siteAddress
                  )(
                    <Input
                      disabled={disabled}
                      className="input"
                      placeholder="Enter Site Address"
                    />
                  )}
                </Item>
              )}
            </div> */}
          </div>
        </div>
        {customerValue && (
          <React.Fragment>
            <div className="section">
              <p className="label">Job Status</p>
              <div className="content">
                <div className="inline">
                  <Item hasFeedback={true} label="Quote Status">
                    {getFieldDecorator(
                      "jobStatus",
                      jobStatus
                    )(
                      <Select
                        disabled={disabled}
                        className="select"
                        placeholder={"Select Job Status"}
                      >
                        {jobStatuses.map((status, index) => {
                          return (
                            <Option key={index} value={status}>
                              {status}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Item>
                </div>
              </div>
            </div>
            <div className="section">
              <p className="label">Item Details</p>
              <div className="content">
                <div className="inline">
                  <Item label="Items" hasFeedback>
                    <MultiRecordForm
                      disabled={disabled}
                      wrappedComponentRef={(ref) => {
                        this.partsForm = ref;
                      }}
                      data={partsValue || []}
                      fields={(translate) => [
                        {
                          key: "quantity",
                          label: "Quantity",
                          placeholder: "Quantity",
                          type: "number",
                        },
                        {
                          key: "partCost",
                          label: "Item Cost (" + symbol + ")",
                          placeholder: "Item Cost",
                          type: "number",
                        },
                        {
                          key: "partName",
                          label: "Item Description",
                          placeholder: "Item Description",
                        },
                      ]}
                      getRules={(key, num, translate, { label }) => [
                        {
                          required: true,
                          message: label + " is required",
                        },
                      ]}
                    />
                  </Item>
                </div>
              </div>
            </div>

            <div className="section">
              <p className="label">Quote Notes</p>
              <div className="content">
                <Item hasFeedback={true} label="Enter Quote Notes">
                  {getFieldDecorator(
                    "generalRemarks",
                    generalRemarks
                  )(
                    <TextArea
                      disabled={disabled}
                      placeholder="Enter Remarks"
                      className="input"
                    />
                  )}
                </Item>
              </div>
            </div>

            <div>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="section">
                    <p className="label">Credit</p>
                    <div className="content">
                      <div className="inline">
                        <Item
                          hasFeedback={true}
                          label={"Credit (" + symbol + ")"}
                        >
                          {getFieldDecorator(
                            "discount",
                            discount
                          )(
                            <InputNumber
                              disabled={disabled}
                              className="input"
                              min={0}
                              max={total}
                              placeholder={"Enter Credit (" + symbol + ")"}
                            />
                          )}
                        </Item>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="estimation">
                    <div className="heading">
                      <p>Cost Estimation</p>
                    </div>
                    <div className="list">
                      {array.map((item, index) => {
                        const { label, value } = item;
                        return (
                          <div key={index} className="item">
                            <p className="label">{label}</p>
                            <p className="value">
                              {symbol} {value}
                            </p>
                          </div>
                        );
                      })}
                      {discountValue ? (
                        <div className="item">
                          <p className="label">Credit</p>
                          <p className="value">
                            {symbol} {discountValue}{" "}
                          </p>
                        </div>
                      ) : null}
                      {array.length === 0 ? (
                        <p className="empty">No Items Added</p>
                      ) : null}
                    </div>
                    <div className="section">
                      <p className="label">Finalize Cost</p>
                      <div className="content">
                        <div className="inline">
                          <Item
                            hasFeedback={true}
                            label={"Price Adjustment (" + symbol + ")"}
                          >
                            {getFieldDecorator(
                              "manualAdjustment",
                              manualAdjustment
                            )(
                              <InputNumber
                                disabled={disabled}
                                className="input"
                                min={0}
                                max={total - (discountValue || 0)}
                                placeholder={
                                  "Price Adjustment (" + symbol + ")"
                                }
                              />
                            )}
                          </Item>
                        </div>
                      </div>
                    </div>
                    <div className="total">
                      <p className="label">VAT</p>
                      <p className="value">
                        {symbol}
                        {(0.2 * (total - (discountValue || 0) - adjustment)).toFixed(
                            2
                          )}
                      </p>
                    </div>
                    <div className="total">
                      <p className="label">Total</p>
                      <p className="value">
                        {symbol}{" "}
                        {(1.2 *
                          (total - (discountValue || 0) - adjustment)).toFixed(
                            2
                          )}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </React.Fragment>
        )}
        {!disabled && (
          <div className="actions">
            <Button htmlType={"submit"} className="btn green-btn save">
              Save
            </Button>
            <Button
              htmlType={"button"}
              className="btn red-btn-text"
              onClick={hideModal}
            >
              Cancel
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};
export default view;
