import React from "react";
import { convertPriceToCurrency } from "containers/container-helpers/common";
import withSecurity from "../containers/with-security";
import EnabledSwitch from "../containers/enabled-switch";
export default {
  entity: "product-services",
  entityName: "Offered Services",
  formId: "ProductServiceForm",
  pagination: {
    type: "server",
  },
  defaultSort: {
    sort: "createdAt",
    sortType: "desc",
  },
  search: {
    searchOnSubmit: true,
    filters: [
      {
        title: "Title",
        type: "input",
        key: "title",
      },
    ],
  },
  columns: ({ sharedState: { translate }, getTableData }) => [
    {
      dataIndex: "title",
      title: translate("product-services.title.label"),
    },
    {
      dataIndex: "description",
      title: translate("product-services.description.label"),
    },
    {
      dataIndex: "price",
      title: "Price",
      render: convertPriceToCurrency,
    },
    {
      title: "Enabled",
      dataIndex: "enabled",
      width: 100,
      render: (value, record) => {
        const Component = withSecurity(EnabledSwitch, {
          permissions: ["PRODUCT-SERVICES_EDIT"],
          DefaultComponent: () => {
            return <span>{record.enabled ? "Yes" : "No"}</span>;
          },
        });
        return (
          <Component
            entityName="product-services"
            record={record}
            afterUpdate={getTableData}
          />
        );
      },
    },
  ],
};
