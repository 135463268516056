//Changes for razzle
if (typeof window == "undefined") {
    require("../../../browser")
}
export const SERVER = window.app.server;

export const SEND_EMAIL = SERVER + "email";
export const ADMIN_ENTITIES = SERVER + "admin";
export const NORMAL_ENTITIES = SERVER + "admin";
export const PUSH_NOTIFICATIONS = "send-notifications";
export const PASSWORD_RESET = SERVER + "password-reset";
export const CONFIRM_EMAIL = SERVER + "confirm-email";
export const AUTHENTICATE = SERVER + "authenticate";
export const REGISTER = SERVER + "register";
export const GET_CONFIGS = SERVER + "web-client-config/all";
export const GET_MEMBERSHIPS = SERVER + "memberships";
export const UPDATE_CURRENT_USER = SERVER + "users/current/update";
export const GET_CURRENT_USER = SERVER + "users/current/get";
export const STORAGE = SERVER + "storage";
export const APPS = SERVER + "apps";
export const DASHBOARD = SERVER + "dashboard";
export const REFRESH_INDEX = SERVER + "initialize-indexes";
export const REFRESH_CACHE = SERVER + "initialize-cache";
export const SOCIAL_LOGIN = AUTHENTICATE + '/social/';
