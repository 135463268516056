import React, { Component } from "react";
import ComponentView from "./view";
import preProcess from "../../preprocess";
import { Form, message } from "antd";
import { getObjectsDiff } from "utils/common";
import { createAction, ActionNames } from "app-redux/actions";
import moment from "moment";
import {
  DEFAULT_CUSTOMER_TYPE,
  isPrivateCustomer,
} from "../../container-helpers/customer";
import { isUserAllowed } from "../../with-security";
const spinningSelector = ".new-form.quote";

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      partOptions: [],
      serviceOptions: [],
      customerProducts: [],
      users: [],
      customers: [],
      customer: null,
      fetchingCustomers: false,
      customerType: DEFAULT_CUSTOMER_TYPE,
      quoteNumber : ""
    };
    this.onSearch = this.onSearch.bind(this);
    this.getCustomerDetails = this.getCustomerDetails.bind(this);
  }

  /**
   * get Customer Parts
   */
  async getCustomerDetails(customerValue) {
    const { getSingleCustomer, getProducts } = this.props;
    try {
      if (customerValue) {
        if (customerValue !== "create-new") {
          const { error, payload } = await getSingleCustomer(customerValue);
          if (error) {
            throw payload.response;
          }
          let { products } = payload.data;
          products = products || [];
          if (!Array.isArray(products)) {
            products = [products];
          }
          this.setState({
            customerProducts: products.map(({ key, make, model, colour }) => {
              return { key, make, model, colour };
            }),
            customer: payload.data,
            customers: [payload.data],
            customerType: payload.data.customerType || DEFAULT_CUSTOMER_TYPE,
          });
        } else {
          this.setState({
            customerProducts: [],
            customer: null,
            customers: [],
            customerType: DEFAULT_CUSTOMER_TYPE,
          });
        }
      }
    } catch (e) {
      console.error("Error in fetching Customer Products", { e });
    }
  }

  async onSearch(search) {
    const { getCustomers } = this.props;
    try {
      if (search) {
        this.setState({
          fetchingCustomers: true,
        });
        const { error, payload } = await getCustomers(search);
        if (error) {
          throw payload.response;
        }
        this.setState({
          customers: payload.data.data,
          fetchingCustomers: false,
        });
      }
    } catch (e) {
      console.error("Error in searching customers", { e });
      this.setState({
        fetchingCustomers: false,
      });
    }
  }

  /**
   * Sets Validations for fields
   */
  setValidations() {
    const { data } = this.props;
    const { quoteNumber } = this.state;
    this.validations = {
      key: {
        rules: [
          {
            required: true,
            message: "Enter Quote Number",
          },
        ],
        initialValue: data ? data.key : quoteNumber,
      },
      jobNumber: {
        rules: [
          {
            required: true,
            message: "Enter Asg Job Number",
          },
        ],
        initialValue: data ? data.jobNumber : null,
      },
      // corporateNumber: {
      //   initialValue: data ? data.jobNumber : null,
      // },
      customer: {
        rules: [
          {
            required: true,
            message: "Select Customer",
          },
        ],
        initialValue:
          data && data.customer && typeof data.customer === "string"
            ? data.customer
            : undefined,
      },
      date: {
        rules: [
          {
            required: true,
            message: "Enter Date Added",
          },
        ],
        initialValue: data ? moment(new Date(data.date)) : moment(new Date()),
      },

      jobStatus: {
        rules: [
          {
            required: true,
            message: "Select Quote Status",
          },
        ],
        ...(data
          ? {
              initialValue: data.jobStatus,
            }
          : { initialValue: "Draft" }),
      },
      sign: {
        initialValue: data ? data.sign : null,
      },
     
      generalRemarks: {
        initialValue: data ? data.generalRemarks : null,
      },
      /**
       * Remember if initialValue is not defined, setFieldsValue and resetFields also doesn't work somehow
       * also don't use data && data.parts as it still doesn't work that way
       */
      parts: {
        initialValue: data ? data.parts : [],
      },
    
      discount: {
        initialValue: data ? data.discount : undefined,
      },
      customerJobNumber: {
        initialValue: data ? data.customerJobNumber : undefined,
      },
      customerPoNumber: {
        initialValue: data ? data.customerPoNumber : undefined,
      },
      siteAddress: {
        rules: [
          {
            required: !isPrivateCustomer(this.state.customerType),
            message: "Enter Site Address",
          },
        ],
      },
      manualAdjustment : {
        initialValue: data ? data.manualAdjustment : undefined,
      }
    };
  }

  /**
   * ComponentDidMount Hook
   */
  async componentDidMount() {
    const {  data,getQuoteNumber } = this.props;
    try {
      if (!data) {
        const { error, payload } = await getQuoteNumber();
        if (error) {
          throw payload.response;
        }
        this.setState({
          quoteNumber: payload.data,
        });
      }
      if (data && data.customer && typeof data.customer === "string") {
        this.getCustomerDetails(data.customer);
      }
    } catch (e) {
      console.error("Error while mounting service job", { e });
    }
  }

  getCustomerFormData() {
    return new Promise((resolve) => {
      try {
        this.customerFormRef.props.form.validateFieldsAndScroll(
          (errors, values) => {
            resolve({
              errors,
              values,
            });
          }
        );
      } catch (e) {
        resolve(e);
      }
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    const {
      role,
      getTableData,
      hideModal,
      createRecord,
      updateRecord,
      uploadImage,
      data,
      form,
    } = this.props;
    const { validateFieldsAndScroll } = form;
    validateFieldsAndScroll(async (errors, values) => {
      const {errors : partErrors, values : partValues} = await this.partsForm.validate();
      const form = await this.getCustomerFormData("customer");
      errors = partErrors ? partErrors : errors;
      errors = form.errors
        ? {
            ...form.errors,
          }
        : errors;
      if (errors) {
        return;
      }
      if (values.customer === "create-new") {
        values.customer = form.values;
      } 
      values.parts = partValues;
      values.saveCustomer = true;
      values.discount = values.discount || 0;
      values.manualAdjustment = values.manualAdjustment || 0;
      window.startSpinning(spinningSelector);
      try {
        if (data) {
          const { error, payload } = await updateRecord({
            entityId: data.key,
            ...getObjectsDiff(values, data),
            parts: values.parts || [],
          });
          if (error) {
            throw payload.response;
          }
        } else {
          const { error, payload } = await createRecord(values);
          if (error) {
            throw payload.response;
          }
        }
        if (hideModal instanceof Function) {
          hideModal();
        }
        if (getTableData instanceof Function) {
           getTableData();
        }
        if (data) {
          message.success("Quote Updated Successfully");
        } else {
          message.success("Quote Created Successfully");
        }
      } catch (e) {
        console.log(e);
        if (data) {
          message.error("Error while Updating Quote");
        } else {
          if (e.status === 404) {
            message.error("Quote with this Job Number already Exists.");
          } else {
            message.error("Error while Creating Quote");
          }
        }
      }
     window.stopSpinning(spinningSelector);
    });
  }

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    getUsers: (entityId) => {
      return dispatch(
        createAction(ActionNames.GET_ENTITY, {
          entityName: "users",
          entityId,
        })
      );
    },
    getProducts: (entityId) => {
      return dispatch(
        createAction(ActionNames.GET_ENTITY, {
          entityName: "products",
          entityId,
        })
      );
    },
    getSingleCustomer: (entityId) => {
      return dispatch(
        createAction(ActionNames.GET_ENTITY, {
          entityId,
          entityName: "customer",
        })
      );
    },
    getQuoteNumber: () => {
      return dispatch(
        createAction(ActionNames.GET_ENTITY, {
          entityName: "quotes",
          entityId: "getQuoteNumber",
        })
      );
    },
    searchEntity: (data) => {
      return dispatch(createAction(ActionNames.GET_ENTITIES, data));
    },
    createRecord: (data) => {
      const action = createAction(ActionNames.CREATE_ENTITY, {
        entityName: "quotes",
        ...data,
      });
      action.type = action.type + "_quotes";
      return dispatch(action);
    },
    updateRecord: ({ entityId, ...data }) => {
      const action = createAction(ActionNames.UPDATE_ENTITY, {
        entityName: "quotes",
        entityId,
        ...data,
      });
      action.type = action.type + "_quotes";
      return dispatch(action);
    },
    uploadImage: (file) => {
      return dispatch(
        createAction(ActionNames.CREATE_ENTITY, {
          entityName: "storage",
          ...file,
          fileCode: "serviceAttachment",
        })
      );
    },
    getJobNumber: () => {
      return dispatch(
        createAction(ActionNames.GET_ENTITY, {
          entityName: "service",
          entityId: "getJobNumber",
        })
      );
    },
    getCustomers: (search) => {
      const action = createAction(ActionNames.GET_ENTITIES, {
        entityName: "customer",
        searchField: "customerName",
        from: -1,
        search,
      });
      action.type = action.type + "_customer";
      return dispatch(action);
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ user, customer, config }) => {
  const { configuration } = config || {};
  const { CURRENCY } = configuration || {};
  const { data } = customer || {};
  const { role, permissions, uid } = user || {};
  return {
    customers: data,
    role,
    CURRENCY,
    permissions,
    uid,
  };
};
Main.displayName = "Quote-Form";
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
