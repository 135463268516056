import React, { Component } from "react";
import ComponentView from "./view";
import { Form, message } from "antd";
import { ActionNames, createAction } from "app-redux/actions";
import preProcess from "../preprocess";
const { create } = Form;
const spinningSelector = ".meta-tags-form";
/**
 * @description Sample Component
 * @type Component
 * @author Inderdeep
 */
class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      partsIndex: [],
    };
    this.removePart = this.removePart.bind(this);
    this.addPart = this.addPart.bind(this);
    this.validate = this.validate.bind(this);
    this.onChange = this.onChange.bind(this)
  }

  addPart() {
    const { partsIndex } = this.state;
    this.setState({
      partsIndex: [       
        ...partsIndex,
        partsIndex.length === 0 ? 0 : Math.max.apply(Math, partsIndex) + 1
      ],
    },this.onChange);
    if(this.props.onAdd instanceof Function){
      this.props.onAdd()
    }
  }

  removePart(index) {
    let { partsIndex } = this.state;
    partsIndex.splice(index, 1);
    this.setState({
      partsIndex,
    },this.onChange);
  }

  async validate() {
    const { validateFieldsAndScroll } = this.props.form;
    return new Promise((resolve, reject) => {
      validateFieldsAndScroll(async (errors, values) => {
        return resolve({
          errors,
          values: values ? values.data : [],
        });
      });
    });
  }

  getValues() {
    const { getFieldsValue } = this.props.form;
    return getFieldsValue();
  }

  /**
   * ComponentDidMount Hook
   */
  componentDidMount() {
    const { data } = this.props;
    if (data && data.length) {
      this.setState({
        partsIndex: Array(data.length)
          .fill()
          .map((x, i) => i),
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { data } = nextProps;    
    if (data && data.length) {
      this.setState({
        partsIndex: Array(data.length)
          .fill()
          .map((x, i) => i),
      });
    }
  }

  onChange() {
    const { onChange } = this.props;
    setTimeout(() => {
      onChange instanceof Function && onChange((this.getValues().data || []).filter(ele => !!ele));
    });
  }

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

Main.displayName = "multi-record-Form";
//Pre process the container with Redux Plugins
export default preProcess(create()(Main), {
  //localize: true
});
