import React from "react";

import { formatDate } from "utils/date-util";
import { Button, Select, Icon, Tooltip, Row } from 'antd'
import SendJobForm from "../containers/send-service";
import ServiceForm from "../containers/forms/quote-form";
import { ModalTrigger } from "core-components/modal";
import Print from "core-components/print";
import { getServiceCostSummary } from "containers/container-helpers/common";
import withSecurity from "containers/with-security";
import { JOB_STATUSES, QUOTE_STATUSES, SERVICE_TYPES } from "containers/container-helpers/service-job";
import InvoiceHeader from 'containers/quoteHeader'

const { Option } = Select;
export const ViewJob = ({ record }) => {
  return (
    <ModalTrigger
      content={<ServiceForm view={true} data={record} />}
      modalProps={{
        className: "add-record-modal ServiceForm",
        footer: null,
        title: "View",
      }}
    >
      <Tooltip placement="top" title={"View"}>
        <Button className="btn orange-btn-text">
          <Icon type="eye" />
        </Button>
      </Tooltip>
    </ModalTrigger>
  );
};

export const SendJob = ({ record,getTableData }) => {
  return (
    <ModalTrigger
      content={<SendJobForm record={record} getTableData = {getTableData} entityName = "quotes" />}
      modalProps={{
        footer: null,
        title: "Send Email/SMS",
        className: "send-job-form-modal small-width",
      }}
    >
      <Tooltip placement="top" title={"Send Email or SMS"}>
        <Button className="btn orange-btn-text">
          <Icon type="mail" />
        </Button>
      </Tooltip>
    </ModalTrigger>
  );
};

export const PrintJob = ({ record }) => {
  const { key, customer,customerType, date } = record;
  return (
    <Print
      template={"QUOTE" + (customerType || "").toUpperCase()}
      data={{
        invoiceKey: key,
        serviceKey: key,
        ...record,
        customer:
          typeof customer === "string"
            ? {
                ...record,
                customerKey: customer,
              }
            : customer,
        invoiceKey: record.key,
        ...getServiceCostSummary(record),
        date: formatDate(date, "DD-MM-YYYY"),
      }}
    />
  );
};

export default {
  entityName: "Quotes",
  entity: "quotes",
  formId: "QuoteForm",

  search: (props) => {
    const { location } = props;
    const { query } = location || {};
    const { jobStatus } = query || {};
    return {
      ...(jobStatus
        ? {
            isSubmitted: true,
            selectedFilter: 3,
            value: jobStatus,
          }
        : {}),
      filters: [
        {
          title: "Quote Number",
          type: "input",
          key: "key",
        },
        {
          title: "Customer Name",
          type: "input",
          key: "customerName",
        },
        // {
        //   title: "Month/Year",
        //   key: "monthYear",
        //   type: "input",
        //   props: {
        //     placeholder: "MM/YYYY",
        //   },
        // },
        // {
        //   title: "Year",
        //   key: "year",
        //   type: "input",
        // },
        // {
        //   title: "Month",
        //   key: "month",
        //   type: "input",
        // },
        // {
        //   title: "Serial Number",
        //   type: "input",
        //   key: "serialNumber",
        // },
        // {
        //   title: "EW/Customer/Corporate Number",
        //   type: "input",
        //   key: "corporateNumber",
        // },
        {
          title: "Job Number",
          type: "input",
          key: "jobNumber",
        },
        // {
        //   title: "Model",
        //   type: "input",
        //   key: "model",
        // },
        // {
        //   title: "Mobile Number",
        //   type: "input",
        //   key: "customer",
        // },
        // {
        //   title: "Home Number",
        //   type: "input",
        //   key: "homeNumber",
        // },
        // {
        //   title: "Office Number",
        //   type: "input",
        //   key: "officeNumber",
        // },
        {
          title: "Status",
          key: "jobStatus",
          type: "select",
          options: [...QUOTE_STATUSES].map((status, index) => {
            return (
              <Option key={index} value={status}>
                {status}
              </Option>
            );
          }),
        },
        // {
        //   title: "Service Type",
        //   key: "serviceType",
        //   type: "select",
        //   options: [...SERVICE_TYPES].map((status, index) => {
        //     return (
        //       <Option key={index} value={status}>
        //         {status}
        //       </Option>
        //     );
        //   }),
        // },
        // {
        //   title: "Phone Number",
        //   type: "input",
        //   key: "phoneNumber",
        // },
      ],
      searchOnSubmit: true,
    };
  },
  pagination: {
    type: "server",
  },
  columns: [
    {
      title: "Quote Number",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Customer Name",
      dataIndex: "customer",
      key: "customerName",
      render: (customer, { customerName }) => {
        if (typeof customer === "object") {
          customer = customer.customerName;
        } else {
          customer = customerName;
        }
        return <p>{customer}</p>;
      },
    },

    {
      title: "Job Number",
      dataIndex: "jobNumber",
    },

    {
      title: "Status",
      dataIndex: "jobStatus",
      render: (status) => {
        return <p className={"status " + (status || "").toLowerCase()}>{status}</p>; /* classNames : declined - approved - sent - draft*/
      },
    },
    {
      title: "Date Added",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return <p>{formatDate(date)}</p>;
      },
    },
  ],
  defaultSort: {
    sort: "timestamp",
    sortType: "desc",
  },
  headerActions: [
    ({ props }) => {
      return (
          <div className="invoice-header">
            <Row gutter={16}>
              <InvoiceHeader {...props} />
            </Row>
          </div>
      );
    },
  ],
  rowActions: [
    ViewJob,
    SendJob,
    PrintJob,
  ],
};
