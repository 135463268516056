export default {
  entity: "theme",
  entityName: "Themes",
  formId: "ThemeForm",
  deleteAction: false,
  addAction: false,
  selection: false,
  defaultSort: {
    sort: "createdAt",
    sortType: "asc",
  },
  pagination: {
    type: "server",
  },
  search: {
    searchOnSubmit: true,
    filters: [
      {
        title: "Key",
        key: "key",
        type: "input",
      },
    ],
  },
  columns: [
    {
      title: "Code",
      dataIndex: "key",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Color",
      dataIndex: "value",
    },
  ],
};
