import React from "react";
/**
 * HOC to disable the feature in case of demo
 */
export default (Component, { disabled, DefaultComponent }) => {
  let enabled = window.app.isDemoEnabled ? !disabled : true;
  return ({ ...props }) => {
    return enabled ? (
      <Component {...props} />
    ) : DefaultComponent ? (
      <DefaultComponent {...props} />
    ) : null;
  };
};
