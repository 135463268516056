import React from "react";

import EnabledSwitch from "../containers/enabled-switch";
export default {
  entity: "gateway",
  pagination: {
    type: "server",
  },
  defaultSort: {
    sort: "createdAt",
    sortType: "desc",
  },
  selection: false,
  addAction: false,
  deleteAction: false,
  entityName: "Payment Gateways",
  formId: "GatewayForm",
  search: false,
  columns: ({ sharedState: { translate }, getTableData }) => [
    {
      title: translate("payment.key.label"),
      dataIndex: "key",
    },
    {
      title: translate("payment.enabled.label"),
      dataIndex: "enabled",
      render: (enabled, record) => {
        return (
          <EnabledSwitch
            entityName="gateway"
            record={record}
            afterUpdate={getTableData}
          />
        );
      },
    },
  ],
};
