import './styles.scss';

var view = function () {
    const {loading} = this.state
    const {getChildren} = this.props;
    if(getChildren instanceof Function){
        return getChildren({
            ...this.props,
            loading,
            updateEntity : this.updateEntity.bind(this)
        });
    }
    return null;
};
export default view;
