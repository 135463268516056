import React, {Component} from 'react';
import ComponentView from './view';
import preProcess from '../../preprocess';
import {Form, message} from 'antd';
import {getObjectsDiff} from '../../../utils/common';
import {PROFILE_IMAGES_PATH} from '../../../config'
import {getEmail, uploadImage} from 'utils/firebase';
import {createAction, ActionNames} from '../../../redux/actions'

const spinningSelector = ".new-form"

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {

    /**
     * Container
     * @param props
     */
    constructor(props) {
        super(props);
        this.setValidations();
    }

    /**
     * Sets Validations for fields
     */
    setValidations() {
        const {data,translate} = this.props;
        this.validations = {
            key:{
                rules:[{
                    required:true,
                    message:translate('configuration.key.required')
                },{
                    pattern : /^[a-z0-9-_]+$/i,
                    message: "Key can only be alphanumeric and can contain only hyphens (-)"
                  }],
                initialValue:data?data.key:null
            },
            type:{
                rules:[{
                    required:true,
                    message:translate('configuration.type.required')
                }],
                ...data?{
                    initialValue:data.type
                }:{}
            },
            file:{
                rules:[{
                    required:true,
                    message:translate('configuration.media.required')
                }],
                initialValue:data?data.value:null
            },
            value:{
                rules:[{
                    required:true,
                    message:translate('configuration.value.required')
                }],
                initialValue:data?data.value:null
            },
            description:{
                rules:[{
                    required:true,
                    message:translate('configuration.description.required')
                }],
                initialValue:data?data.description:null
            },
            boolean: {
                initialValue:data&&data.boolean,
                rules:[],
                valuePropName:'checked'
            }
        }
    }

    /**
     * ComponentDidMount Hook
     */
    componentDidMount() {

    }

    handleSubmit(event) {
        event.preventDefault();
        const {form,translate, createRecord,refreshConfig, hideModal,createStorage, updateRecord, data, getTableData} = this.props;
        const {validateFieldsAndScroll}=form;
        validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return;
            }
            const {key,file,value}=values;
            values.key=key.toUpperCase();
            values.value=value||false;
            window.startSpinning(spinningSelector);
            try {
                let action;
                if(file&&file.dataUri&&file.type&&file.name){
                    const {dataUri,type,name}=file;
                    action=await createStorage({
                        file:dataUri,
                        type,
                        name
                    });
                    if(action.error){
                        throw action.payload.response;
                    }
                    values.value=encodeURI(action.payload.data.url);
                }
                delete values.file;
                if (data) {
                    let updatedValues = getObjectsDiff(values, data);
                    action = await updateRecord({
                        ...updatedValues,
                        key: data.key
                    });
                } else {
                    values.key=values.key.toUpperCase();
                    action = await createRecord(values);
                }
                if (!action.error) {
                    message.success(translate("common.changes.save.success"));
                    hideModal();
                } else {
                    throw action.payload.response;
                }
                if (getTableData instanceof Function) {
                    getTableData();
                }
            } catch (e) {
                if(e&&e.status===404){
                    message.error(translate('configuration.record.exist'));
                } else if(e&&e.status===409){
                    message.error(translate("configuration.demo.error"));
                } else {
                    message.error(translate("common.changes.save.error"));
                }
            }
            refreshConfig();
            window.stopSpinning(spinningSelector);

        });
    }


    /**
     * Render Method
     * @returns {*}
     */
    render() {
        return (ComponentView.bind(this))();
    }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {
        createRecord: (data) => {
            return dispatch(createAction(ActionNames.CREATE_ENTITY, {
                entityName: "configuration",
                ...data
            }))
        },
        refreshConfig: () => {
            return dispatch(createAction(ActionNames.API_REQUEST, {
                url : "/refresh-config"
            }))
        },
        updateRecord: ({entityId, ...data}) => {
            const key = data.key;
            delete data.key;
            return dispatch(createAction(ActionNames.UPDATE_ENTITY, {
                entityName: "configuration",
                entityId: key,
                ...data
            }))
        },
        createStorage:({file,type,name})=>{
            return dispatch(createAction(ActionNames.CREATE_ENTITY,{
                entityName:'storage',
                file,
                type,
                name,
                fileCode : "configurationFile"
            }));
        }
    }
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {

    return {}
};
Main.displayName = "User-Form";
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
    connect: [mapStateToProps, bindAction],
    localize: true
});
