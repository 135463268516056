import React from 'react'
import './styles.scss'
import SupportForm from './supportForm'

/**
 * Returns the JSX Markup for the view
 * @returns {XML}
 */
var view = function () {
    const { translate } = this.props;
    return (
        <div className="support-form">
            <div className="heading">
                <p className="page-title">{translate('support.title')}</p>
                <p className="description">{translate('support.subTitle')}</p>
            </div>
            <SupportForm />
        </div>
    )
}
export default view
