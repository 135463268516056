export function convertPriceToCurrency(price) {
  if (price && price !== "") {
    return `${window.app.currencySymbol} ${price}`;
  }
  return "";
}

export function getServiceCostSummary(service) {
  const { parts, services, labourTransportCosts, discount, manualAdjustment } =
    service;

  const totalPartsCost =
    (parts || []).reduce((sum, item) => {
      const { partCost, quantity } = item;
      return (
        sum +
        parseFloat(isNaN(partCost) ? 0 : partCost) *
          (isNaN(quantity) ? 0 : quantity)
      );
    }, 0) || 0;
  const totalServicesCost =
    (services || []).reduce((sum, item) => {
      const { price } = item;
      return sum + parseFloat(price);
    }, 0) || 0;
  const totalPartsAndServicesCost = +(
    totalPartsCost + totalServicesCost
  ).toFixed(2);
  const total = +(
    totalPartsAndServicesCost +
    (labourTransportCosts || 0) -
    (manualAdjustment || 0)
  ).toFixed(2);
  const totalPayable = +(total - (discount || 0)).toFixed(2);

  return {
    totalPartsCost,
    totalServicesCost,
    totalPartsAndServicesCost,
    total: total,
    totalPayable: totalPayable * 1.2,
    vat: 0.2 * totalPayable,
    discount: discount || 0,
  };
}
