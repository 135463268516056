import React from "react";

import { formatDate } from "../utils/date-util";
import { Button, Select, Icon, Tooltip } from "antd";
import SendJobForm from "../containers/send-service";
import CreateInvoice from "../containers/createInvoice";
import ServiceForm from "../containers/forms/service-form";
import { ModalTrigger } from "core-components/modal";
import Print from "../components/print";
import { getServiceCostSummary } from "containers/container-helpers/common";
import withSecurity from "containers/with-security";
import {
  JOB_STATUSES,
  SERVICE_TYPES,
} from "containers/container-helpers/service-job";
import CsvGenerator from "../containers/csv-generator";
import autoSearch from "containers/autoSearch";

const { Option } = Select;
export const ViewJob = ({ record }) => {
  return (
    <ModalTrigger
      content={<ServiceForm view={true} data={record} />}
      modalProps={{
        className: "add-record-modal ServiceForm",
        footer: null,
        title: "View",
      }}
    >
      <Tooltip placement="top" title={"View"}>
        <Button className="btn orange-btn-text">
          <Icon type="eye" />
        </Button>
      </Tooltip>
    </ModalTrigger>
  );
};

export const SendJob = ({ record }) => {
  return (
    <ModalTrigger
      content={<SendJobForm entityName="service" record={record} />}
      modalProps={{
        footer: null,
        title: "Send To",
        className: "send-job-form-modal small-width",
      }}
    >
      <Tooltip placement="top" title={"Send To"}>
        <Button className="btn orange-btn-text">
          <Icon type="mail" />
        </Button>
      </Tooltip>
    </ModalTrigger>
  );
};

export const PrintJob = withSecurity(
  ({ record }) => {
    const { key, customer, date } = record;
    return (
      <Print
        template="service"
        data={{
          invoiceKey: key,
          serviceKey: key,
          ...record,
          customer:
            typeof customer === "string"
              ? {
                  ...record,
                  customerKey: customer,
                }
              : customer,
          invoiceKey: record.key,
          ...getServiceCostSummary(record),
          date: formatDate(date, "DD-MM-YYYY"),
        }}
      />
    );
  },
  {
    role: ["ADMIN", "ENGINEER"],
  }
);

const keys = {
  appliance: "Appliance",
  applianceBrand: "Brand",
  applianceType: "Type",
  applianceConnection: "Connection",
  applianceModelNumber: "Model",
  applianceSerialNumber: "Serial",
  assignedEmails: "Assigned To",
  customerType: "Customer Type",
  email: "Email",
  customerName: "Customer Name",
  serviceType: "Service Type",
  generatedDate: "Date Added",
  key: "ASG Job Number",
  customerType: "Customer Type",
  address: "Customer Address",
  phoneNumber: "Customer Phone",
  category: "Category",
  preferredAppointmentDate: "Preferred Date",
  jobStatus: "Job Status",
  repairCompletedDate : "Completed Date",
  invoiced : "Invoiced"
};

export default {
  entityName: "Service Jobs",
  entity: "service",
  formId: "ServiceForm",
  getRequestParams: (props) => {
    return {
      ...props.location.query,
    };
  },
  search: (props) => {
    const { location } = props;
    const { query } = location || {};
    const { jobStatus } = query || {};
    return {
      ...(jobStatus
        ? {
            isSubmitted: true,
            selectedFilter: 2,
            value: jobStatus,
          }
        : {}),
      filters: [
        {
          title: "Customer Name",
          type: "input",
          key: "customerName",
        },
        // {
        //   title: "Month/Year",
        //   key: "monthYear",
        //   type: "input",
        //   props: {
        //     placeholder: "MM/YYYY",
        //   },
        // },
        // {
        //   title: "Year",
        //   key: "year",
        //   type: "input",
        // },
        // {
        //   title: "Month",
        //   key: "month",
        //   type: "input",
        // },
        // {
        //   title: "Serial Number",
        //   type: "input",
        //   key: "serialNumber",
        // },
        // {
        //   title: "EW/Customer/Corporate Number",
        //   type: "input",
        //   key: "corporateNumber",
        // },
        {
          title: "Job Number",
          type: "input",
          key: "key",
        },
        // {
        //   title: "Model",
        //   type: "input",
        //   key: "model",
        // },
        // {
        //   title: "Mobile Number",
        //   type: "input",
        //   key: "customer",
        // },
        // {
        //   title: "Home Number",
        //   type: "input",
        //   key: "homeNumber",
        // },
        // {
        //   title: "Office Number",
        //   type: "input",
        //   key: "officeNumber",
        // },
        {
          title: "Status",
          key: "jobStatus",
          type: "select",
          options: [...JOB_STATUSES].map((status, index) => {
            return (
              <Option key={index} value={status}>
                {status}
              </Option>
            );
          }),
          operator: "equals",
        },
        {
          title: "Service Type",
          key: "serviceType",
          type: "select",
          options: [...SERVICE_TYPES].map((status, index) => {
            return (
              <Option key={index} value={status}>
                {status}
              </Option>
            );
          }),
        },
        {
          title: "Invoiced",
          type: "select",
          key: "invoiced",
          options: ["Yes", "No"].map((item, index) => {
            return (
              <Option key={index} value={(item === "Yes").toString()}>
                {item}
              </Option>
            );
          }),
        },
        {
          title: "Site Contact Name",
          type: "input",
          key: "siteContactName",
        },
        {
          title: "Claim Number",
          type: "input",
          key: "claimNumber",
        },
        {
          title: "Plan Number",
          type: "input",
          key: "policyNumber",
        },
        ...(props.role === "admin"
          ? [
              {
                title: "Assigned To",
                type: "custom",
                key: "assignedTo",
                element: autoSearch,
                props: {
                  placeholder: "Type to Search Assignees",
                  entityName: "users",
                  searchField: "displayName",
                  className: "assigned-to-filter",
                  formatter: (item) => {
                    const { uid, displayName, email } = item;
                    return {
                      label: `${displayName} (${email})`,
                      value: uid,
                    };
                  },
                  filterData: ({ role, emailVerified }) => {
                    return (
                      !!emailVerified &&
                      (role || "").toLowerCase() !== "admin" &&
                      (role || "").toLowerCase() !== "customer"
                    );
                  },
                },
              },
            ]
          : []),
        // {
        //   title: "Phone Number",
        //   type: "input",
        //   key: "phoneNumber",
        // },
      ],
      searchOnSubmit: true,
    };
  },
  pagination: {
    type: "server",
  },
  columns: [
    {
      title: "Customer Name",
      dataIndex: "customer",
      key: "customerName",
      render: (customer, { customerName }) => {
        if (typeof customer === "object") {
          customer = customer.customerName;
        } else {
          customer = customerName;
        }
        return <p>{customer}</p>;
      },
    },

    {
      title: "Job Number",
      dataIndex: "key",
    },

    {
      title: "Status",
      dataIndex: "jobStatus",
    },
    {
      title: "Date Added",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return <p>{formatDate(date)}</p>;
      },
    },
    {
      title: "Service Type",
      dataIndex: "serviceType",
      key: "serviceType",
    },
    {
      title: "Invoiced",
      dataIndex: "invoiced",
      key: "invoiced",
      render: (invoiced) => {
        return invoiced ? "Yes" : "No";
      },
    },
    // {
    //   title: "Post Code",
    //   dataIndex: "postCode",
    //   key: "postCode",
    // },
  ],
  defaultSort: {
    sort: "timestamp",
    sortType: "desc",
  },
  headerActions: (parentProps) => [
    (props) => {
      return (
        <CsvGenerator
          api={"service"}
          columns={keys}
          query={{
            ...parentProps.getRequestQuery(),
            from: -1,
          }}
          formatData={(data) => {
            return data.map((item) => {
              return {
                ...item,
                repairCompletedDate : formatDate(item.repairCompletedDate, "DD MMM YYYY"),
                assignedEmails: (item.assignedUsers || [])
                  .map(({ email, displayName }) => `${displayName} (${email})`)
                  .join(","),
                preferredAppointmentDate: formatDate(
                  item.preferredAppointmentDate,
                  "DD MMM YYYY"
                ),
                generatedDate: formatDate(item.createdAt, "DD MMM YYYY"),
                invoiced : item.invoiced ? "Yes" : "No"
              };
            });
          }}
        />
      );
    },
  ],
  rowActions: [
    ViewJob,
    withSecurity(SendJob, {
      permissions: ["SERVICE_EDIT"],
    }),
    PrintJob,
    CreateInvoice,
  ],
  actionsWidth: 300,
};
