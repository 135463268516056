export const JOB_STATUSES = [
  "Pending",
  "Allocated",
  "Scheduled",
  "Parts Required",
  "Revisit Pending",
  "Revisit Scheduled",
  "Quote Required",
  "Completed",
  "Cancelled",
];
export const QUOTE_STATUSES = ["Draft", "Sent", "Approved", "Declined"];

export const SERVICE_TYPES = ["Repair", "Installation", "Recall", "BER"];

export const CATEGORY = ["Laundry", "Dishwashing", "Cooking", "Cooling"];

export const APPLIANCE = [
  "Washing Machine",
  "Washer Dyer",
  "Tumble Dryer",
  "Dishwasher",
  "Cooker",
  "Range Cooker",
  "Oven",
  "Microwave Oven",
  "Hob",
  "Cooker Hood",
  "Fridge",
  "Freezer",
  "Fridge Freezer",
  "American Fridge Freezer",
  "Wine Cooler",
];

export const APPLIANCE_TYPE = ["Freestanding", "Integrated/built-In"];

export const CONNECTION = ["Electric", "Gas", "Dual Fuel"];

export const PARTS_PREORDERED = ["Yes", "No"];
export const ENGINEER_ETA = [
  "Yes - call 30 mins ETA",
  "Yes - call 1 hour ETA",
  "No - Send text with ETA",
  "No - Doesn't need ETA]",
];

export const CALLOUT_PART = {
  quantity : 1,
  partCost : 56.65,
  partName : 'Call Out & Labour Charge'
}

export function isDomesticAndGeneral(customer){
  return customer && customer.key === '8005978608' && customer.customerType === 'Trade'
}

export function getDomesticAndGeneralPartCost(cost){
  return cost ? Math.round((cost + 6.95)* 1.25 * 100)/100 : 0
}