import React, { Component } from "react";
import ComponentView from "./view";
import { resolve } from "bluebird";
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
export default class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || ""
    };
    this.onChange = this.onChange.bind(this);
    this.fileUploadUrl = Main.fileUploadUrl;
  }

  /**
   * ComponentDidMount Hook
   */
  componentDidMount() {}

  /**
   * On Editor Ready
   * @param {On Editor Ready} param0 
   */
  async onReady({ editor }) {
    const {
      uploadRequestData,
      uploadHeaders,
      uploadUrl,
      uploadRequestFormatter,
      uploadResponseFormatter,
      onUploadError,
      onUploadSuccess
    } = this.props;
    editor.on(
      "fileUploadRequest",
      function(evt) {
        const fileLoader = evt.data.fileLoader;
        var xhr = evt.data.fileLoader.xhr;
        let formData = new FormData();
        xhr.open("POST", uploadUrl, true);
        (uploadHeaders || []).forEach(({ name, value }) => {
          xhr.setRequestHeader(name, value);
        });
        formData.append("file", fileLoader.file);
        formData.append("name", fileLoader.file.name);
        formData.append("type", fileLoader.file.type);
        (uploadRequestData || []).forEach(({ name, value }) => {
          formData.append(name, value);
        });
        if (uploadRequestFormatter instanceof Function) {
          formData = uploadRequestFormatter(formData);
        }
        xhr.send(formData);
        evt.stop();
      },
      null,
      null,
      4
    );

    editor.on("fileUploadResponse", (evt) => {
      // Prevent the default response handler.
      evt.stop();

      // Get XHR and response.
      var data = evt.data,
        xhr = data.fileLoader.xhr,
        response = xhr.responseText.split("|");
      if (response[1]) {
        // An error occurred during upload.
        data.message = response[1];

        if (onUploadError instanceof Function) {
          onUploadError(response[1]);
        }
        evt.cancel();
      } else {
        if (onUploadSuccess instanceof Function) {
          onUploadSuccess(response[0]);
        }
        if (uploadResponseFormatter instanceof Function) {
          data.url = uploadResponseFormatter(response[0]);
        } else {
          data.url = this.parseJson(response[0]).url;
        }
      }
    });
  }

  parseJson(resp) {
    try {
      return JSON.parse(resp);
    } catch (e) {
      return resp;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.onChange({
        editor: {
          getData: () => nextProps.value
        }
      });
    }
  }

  onChange(event) {
    const { onChange } = this.props;
    const value = event.editor.getData();
    this.setState({ value });
    if (onChange instanceof Function) {
      onChange(value);
    }
  }

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}
