import React from 'react'
import './styles.scss'
import { Form, Button, Input } from 'antd'

const {TextArea} = Input
const {Item} = Form
var view = function () {
    const {form, hideModal, data, translate} = this.props
    const {template, templateVariables, from, key} = this.validations
    const {getFieldDecorator} = form
    return (
        <Form onSubmit={this.handleSubmit.bind(this)}>
            <div className="new-form">
                <div className="form">
                    <Item hasFeedback={true} label={translate('sms.key.label')}>
                        {
                            getFieldDecorator('key', key)(
                                <Input maxLength={100}
                                       disabled={!!data}
                                       className='input uppercase'
                                       placeholder={translate('sms.key.placeholder')}
                                />
                            )
                        }
                    </Item>
                    <Item hasFeedback={true} label={translate('sms.from.label')}>
                        {
                            getFieldDecorator('from', from)(
                                <Input maxLength={200}
                                       className='input'
                                       placeholder={translate('sms.from.placeholder')}
                                />
                            )
                        }
                    </Item>
                    <Item hasFeedback={true} label={translate('sms.templateVariables.label')}>
                        {
                            getFieldDecorator('templateVariables', templateVariables)(
                                <TextArea maxLength={2000}
                                          placeholder={translate('sms.templateVariables.placeholder')}
                                />
                            )
                        }
                    </Item>
                    <Item hasFeedback={true} label={translate('sms.template.label')}>
                        {
                            getFieldDecorator('template', template)(
                                <TextArea maxLength={2000}
                                          placeholder={translate('sms.template.placeholder')}
                                />
                            )
                        }
                    </Item>
                </div>
                <div className="actions">
                    <Button htmlType={'submit'} type="primary"
                            className="btn green-btn">{translate('media.submit')}</Button>
                    <Button htmlType={'button'} onClick={hideModal}
                            className="btn red-btn-text">{translate('media.cancel')}</Button>
                </div>
            </div>
        </Form>
    )
}
export default view
