export default {
    entity: 'products',
    entityName: 'Products',
    formId: 'ProductForm',
    pagination: {
        type: 'server'
    },
    defaultSort: {
        sort: 'createdAt',
        sortType: 'desc'
    },
    search: {
        searchOnSubmit: true,
        filters: [{
            title: 'Key',
            type: 'input',
            key: 'key'
        }, {
            title: 'Make',
            type: 'input',
            key: 'make'
        }, {
            title: 'Model',
            type: 'input',
            key: 'model'
        }]
    },
    columns: ({ sharedState: { translate } }) => [{
        dataIndex:'key',
        title:translate('products.key.label')
    },{
        dataIndex: 'make',
        title: translate('products.make.label')
    }, {
        dataIndex: 'model',
        title: translate('products.model.label')
    }]
};