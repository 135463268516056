import React, { Component } from "react";
import ComponentView from "./view";

/**
 * @description Sample Component
 * @type Component
 * @author Inderdeep
 */
export default class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
    this.onPay = this.onPay.bind(this);
  }

  onPay() {
    const { apiKey, onSuccess, additionalData } = this.props;
    const {
      amount,
      CURRENCY,
      WEBSITELOGO,
      description,
      name,
      email,
      phoneNumber,
    } = additionalData || {};
    try {
      const razorpay = new window.Razorpay({
        key: apiKey,
        image: WEBSITELOGO,
        amount: 100 * amount,
        currency: CURRENCY,
        name,
        description,
        prefill: {
          name,
          contact: phoneNumber,
          email,
        },
        handler: (data) => {
          onSuccess({
            ...data,
            amount: 100 * amount,
            currency: CURRENCY,
          });
        },
      });
      razorpay.open();
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * ComponentDidMount Hook
   */
  componentDidMount() {}

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

Main.displayName = "Sample-Component";
