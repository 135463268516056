import React from "react";
import { convertPriceToCurrency } from "containers/container-helpers/common";
import withSecurity from "../containers/with-security";
import EnabledSwitch from "../containers/enabled-switch";
export default {
  entity: "parts",
  entityName: "Parts",
  formId: "PartForm",
  pagination: {
    type: "server",
  },
  defaultSort: {
    sort: "createdAt",
    sortType: "desc",
  },
  search: {
    searchOnSubmit: true,
    filters: [
      {
        title: "Name",
        type: "input",
        key: "name",
      },
      {
        title: "Make",
        type: "input",
        key: "make",
      },
      {
        title: "Model",
        type: "input",
        key: "model",
      },
    ],
  },
  columns: ({ sharedState: { translate }, getTableData }) => [
    {
      dataIndex: "name",
      title: "Name",
    },
    {
      dataIndex: "make",
      title: translate("parts.make.label"),
    },
    {
      dataIndex: "model",
      title: translate("parts.model.label"),
    },
    {
      dataIndex: "price",
      title: "Price",
      render: convertPriceToCurrency,
    },
    {
      title: "Enabled",
      dataIndex: "enabled",
      width: 100,
      render: (value, record) => {
        const Component = withSecurity(EnabledSwitch, {
          permissions: ["PARTS_EDIT"],
          DefaultComponent: () => {
            return <span>{record.enabled ? "Yes" : "No"}</span>;
          },
        });
        return (
          <Component
            entityName="parts"
            record={record}
            afterUpdate={getTableData}
          />
        );
      },
    },
  ],
};
