import React from "react";
import "./styles.scss";
import { Layout } from "antd";
import Sider from "../sider";
import { mobileAndTabletcheck } from "utils/web";
import Link from 'core-components/link'
var view = function() {
  const { children, horizontalMenu, routes,drawer } = this.props;
  return (
    <div className="home">
      <Layout style={{ minHeight: "100vh" }}>
        {drawer && mobileAndTabletcheck() ? (
          <Link className="overlay" onClick={this.closeDrawer.bind(this)} />
        ) : null}
        {!horizontalMenu ? <Sider routes={routes} /> : null}
        <Layout className="home-layout">{children}</Layout>
      </Layout>
    </div>
  );
};
export default view;
