import React, { useState, useEffect, useRef } from "react";
import preProcess from "../../preprocess";
import {
  Form,
  Button,
  Input,
  message,
  Select,
  Switch,
  InputNumber,
} from "antd";
import { createAction, ActionNames } from "app-redux/actions";
import { getAllISOCodes } from "iso-country-currency";
import "./styles.scss";
import MultiRecordForm from "../../multi-record-form";
import AutoSearch from "../../autoSearch";

const { Option } = Select;
const { create, Item } = Form;
const spinningSelector = ".parts-form";
const Main = (props) => {
  const {
    form,
    data,
    translate,
    hideModal,
    CURRENCY,
    updateRecord,
    createRecord,
    getTableData,
  } = props;
  const { getFieldDecorator, validateFieldsAndScroll } = form;
  const [quantity, setQuantity] = useState(((data && data.quantity) || []).map(({userId, quantity})=>({userId, quantity})));
  const quantityForm = useRef(null);
  const { symbol } =
    getAllISOCodes().find((item) => {
      return item.currency === CURRENCY;
    }) || {};

  const validations = {
    partCost: {
      rules: [
        {
          required: true,
          message: "Please enter the part cost",
        },
      ],
      initialValue: data && data.partCost,
    },
    partKey: {
      rules: [
        {
          required: true,
          message: "Please enter the part code",
        },
      ],
      initialValue: data && data.partKey,
    },
    partName: {
      rules: [
        {
          required: true,
          message: "Please enter the part description",
        },
      ],
      initialValue: data && data.partName,
    },
    supplier: {
      initialValue: data && data.supplier,
    },
    supplierCode: {
      initialValue: data && data.supplierCode,
    },
    customerMarkup: {
      initialValue: data ? data.customerMarkup : 0,
    },
  };
  const onSubmit = (event) => {
    event.preventDefault();
    validateFieldsAndScroll(async (errors, values) => {
      const { errors: quantityErrors, values: quantityValues } =
        quantityForm.current
          ? await quantityForm.current.validateFieldsAndScroll()
          : {};
      errors = quantityErrors ? quantityErrors : errors;
      if (errors) {
        return;
      }
      window.startSpinning(spinningSelector);
      try {
        if (data) {
          const { error, payload } = await updateRecord(data.key, {
            ...values,
            quantity,
          });
          if (error) {
            throw payload.response;
          }
        } else {
          const { error, payload } = await createRecord({
            ...values,
            quantity,
          });
          if (error) {
            throw payload.response;
          }
        }
        if (data) {
          message.success(translate("parts.update.success"));
        } else {
          message.success(translate("parts.create.success"));
        }
        if (hideModal instanceof Function) {
          hideModal();
        }
        if (getTableData instanceof Function) {
          getTableData();
        }
      } catch (e) {
        console.log(e);
        if (data) {
          message.error(translate("parts.update.fail"));
        } else {
          message.error(translate("parts.create.fail"));
        }
      }
      window.stopSpinning(spinningSelector);
    });
  };
  return (
    <div className="form parts-form">
      <Form onSubmit={onSubmit}>
        <div className="inline">
          <Item label={"Part Code"} hasFeedback>
            {getFieldDecorator(
              "partKey",
              validations.partKey
            )(<Input className="input" />)}
          </Item>
          <Item label={"Part Cost " + symbol} hasFeedback>
            {getFieldDecorator(
              "partCost",
              validations.partCost
            )(<InputNumber className="input" min={0} />)}
          </Item>
          <Item label={"Supplier"} hasFeedback>
            {getFieldDecorator(
              "supplier",
              validations.supplier
            )(<Input className="input" />)}
          </Item>
          <Item label={"Supplier Code"} hasFeedback>
            {getFieldDecorator(
              "supplierCode",
              validations.supplierCode
            )(<Input className="input" />)}
          </Item>
        </div>
        <div className="inline">
          <Item label={"Part Description"} hasFeedback>
            {getFieldDecorator(
              "partName",
              validations.partName
            )(<Input className="input" />)}
          </Item>
          <Item label={"Customer Markup (%) "} hasFeedback>
            {getFieldDecorator(
              "customerMarkup",
              validations.customerMarkup
            )(<InputNumber className="input" min={0} max={100} />)}
          </Item>
        </div>
        <Item label="Quantity" hasFeedback>
          <MultiRecordForm
            ref={quantityForm}
            onChange={setQuantity}
            data={quantity}
            fields={(translate) => [
              {
                key: "quantity",
                label: "Quantity",
                type: "number",
                min : 0
              },
              {
                key: "userId",
                label: "User",
                type: "custom",
                Component: AutoSearch,
                inputProps: {
                  placeholder: "Type to Search User",
                  entityName: "users",
                  searchField: "displayName",
                  formatter: (item) => {
                    const { uid, displayName, email } = item;
                    return {
                      label: `${displayName} (${email})`,
                      value: uid,
                    };
                  },
                  filterData: ({ role, emailVerified }) => {
                    return (
                      !!emailVerified &&
                      (role || "").toLowerCase() === "engineer"
                    );
                  },
                  mode: "default",
                },
              },
            ]}
            getRules={(key, num, translate, { label }) => [
              {
                required: true,
                message: label + " is required",
              },
            ]}
          />
        </Item>
        <div className="actions">
          <Button type="primary" className="btn green-btn" htmlType="submit">
            {translate("parts.submit")}
          </Button>
          <Button className="btn red-btn-text" onClick={hideModal}>
            {translate("parts.cancel")}
          </Button>
        </div>
      </Form>
    </div>
  );
};
const mapStateToProps = ({ config }) => {
  const { configuration } = config || {};
  const { CURRENCY } = configuration || {};
  return {
    CURRENCY,
  };
};
const bindAction = (dispatch) => {
  return {
    createRecord: (data) => {
      return dispatch(
        createAction(ActionNames.CREATE_ENTITY, {
          entityName: "parts",
          ...data,
        })
      );
    },
    updateRecord: (entityId, data) => {
      return dispatch(
        createAction(ActionNames.UPDATE_ENTITY, {
          entityId,
          entityName: "parts",
          ...data,
        })
      );
    },
  };
};
Main.displayName = "Part-Form";
export default preProcess(create()(Main), {
  localize: true,
  connect: [mapStateToProps, bindAction],
});
