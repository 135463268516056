import React, { Component } from "react";
import ComponentView from "./view";
import preProcess from "../../preprocess";
import { Form, message } from "antd";
import { getObjectsDiff } from "../../../utils/common";
import { createAction, ActionNames } from "../../../redux/actions";
import moment from "moment";
import { DEFAULT_CUSTOMER_TYPE, isPrivateCustomer } from "../../container-helpers/customer";
const spinningSelector = ".new-form";

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      fetching: false,
    };
  //  this.setValidations();
  }

  isPrivate() {
    const { data, form } = this.props;
    const { getFieldValue } = form;
    return isPrivateCustomer(
      getFieldValue("customerType") || (data && data.customerType) || "Private"
    );
  }

  /**
   * Sets Validations for fields
   */
  setValidations() {
    const { data, form } = this.props;
    const isPrivate = this.isPrivate();
    this.validations = {
      customerName: {
        rules: [
          {
            required: true,
            message: "Enter Customer Name",
          },
        ],
        initialValue: data ? data.customerName : null,
      },
      address: {
        rules: [
          {
            required: true,
            message: "Enter Address",
          },
        ],
        initialValue: data ? data.address : null,
      },
      homeNumber: {
        rules: [
          {
            min: 9,
            max: 15,
            message: "Mobile Number must be at between 9 digits and 15 digits",
          },
        ],
        initialValue: data ? data.homeNumber : null,
      },
      key: {
        rules: [
          {
            required: true,
            message: "Enter Mobile Number",
          },
          {
            min: 9,
            max: 15,
            message: "Mobile Number must be at least 9 digits long",
          },
          {
            validator: (rule, value, callback) => {
              if (value && isNaN(value.replace("+", ""))) {
                callback("Number must not contain any alphabet");
              } else {
                callback();
              }
            },
          },
        ],
        initialValue: data ? data.key : null,
      },
      officeNumber: {
        rules: [
          {
            min: 9,
            max: 15,
            message: "Mobile Number must be at least 9 digits long",
          },
        ],
        initialValue: data ? data.officeNumber : null,
      },
      email: {
        rules: [
          {
            required: true,
            message: "Email is Required",
          },
          {
            type: "email",
            message: "Enter Valid Email",
          },
        ],
        initialValue: data ? data.email : null,
      },
      products: {
        // rules: [{
        //     required: true,
        //     message: 'Products is Required'
        // }],
        initialValue:
          data &&
          data.products &&
          (Array.isArray(data.products) ? data.products : [data.products]).map(
            (item) => {
              const { key } = item;
              return key;
            }
          ),
      },
      createUser: {
        valuePropName: "checked",
      },
      customerType: {
        rules: [
          {
            required: true,
            message: "Customer Type is Required",
          },
        ],
        initialValue: data ? data.customerType : DEFAULT_CUSTOMER_TYPE,
      },
      companyName: {
        rules: [
          {
            required: !isPrivate,
            message: "Company Name is Required",
          },
        ],
        initialValue: data ? data.companyName : null,
      },
      emailAddresses: {
        initialValue: data ? data.emailAddresses : [],
      },
      dueTerm: {
        initialValue: data ? data.dueTerm : [],
      },
      automaticInvoice: {
        valuePropName: "checked",
        initialValue: data ? !!data.automaticInvoice : true,
      },
      manualInvoicing: {
        valuePropName: "checked",
        initialValue: data ? !!data.manualInvoicing : true,
      },
      autoPaidInvoices: {
        valuePropName: "checked",
        initialValue: data ? !!data.autoPaidInvoices : true,
      }
    };
  }

  /**
   * ComponentDidMount Hook
   */
  async componentDidMount() {
    const { data, getProducts } = this.props;
    const { products } = data || {};
    try {
      if (products) {
        if (Array.isArray(products)) {
          if (typeof products[0] === "string") {
            const { error, payload } = await getProducts(products.join(","));
            if (error) {
              throw payload.response;
            }
            if (Array.isArray(payload.data)) {
              this.setState({
                options: payload.data.map((item, index) => ({
                  key: products[index],
                  ...item,
                })),
              });
            } else {
              this.setState({
                options: [{ ...payload.data, key: products[0] }],
              });
            }
          } else {
            this.setState({ options: products });
          }
        } else {
          this.setState({
            options: [products],
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const { validateFieldsAndScroll } = this.props.form;
    const { getTableData, hideModal, createRecord, updateRecord, data } =
      this.props;
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return;
      }
      if (data && data.jobStatus === "Completed") {
        message.error("This Job is already completed");
        if (hideModal instanceof Function) {
          hideModal();
        }
        return;
      }
      window.startSpinning(spinningSelector);
      try {
        if (data) {
          const { error, payload } = await updateRecord({
            entityId: data.key,
            ...getObjectsDiff(values, data),
            products: values.products,
            customerType : values.customerType,
            emailAddresses : values.emailAddresses
          });
          if (error) {
            throw payload.response;
          }
        } else {
          const { error, payload } = await createRecord(values);
          if (error) {
            throw payload.response;
          }
        }
        if (hideModal instanceof Function) {
          hideModal();
        }
        if (getTableData instanceof Function) {
          getTableData();
        }
        if (data) {
          message.success("Customer Updated Successfully");
        } else {
          message.success("Customer Created Successfully");
        }
      } catch (e) {
        console.log(e);
        if (e && e.status === 404) {
          message.error("Customer Already Exists with this phone number");
        } else {
          message.error("Error while saving Customer");
        }
      }
      window.stopSpinning(spinningSelector);
    });
  }

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    createRecord: (data) => {
      const action = createAction(ActionNames.CREATE_ENTITY, {
        entityName: "customer",
        ...data,
      });
      action.type = action.type + "_customer";
      return dispatch(action);
    },
    updateRecord: ({ entityId, ...data }) => {
      const action = createAction(ActionNames.UPDATE_ENTITY, {
        entityName: "customer",
        entityId,
        ...data,
      });
      action.type = action.type + "_customer";
      return dispatch(action);
    },
    getProducts: (entityId) => {
      return dispatch(
        createAction(ActionNames.GET_ENTITY, {
          entityName: "products",
          entityId,
        })
      );
    },
    searchProduct: (search) => {
      return dispatch(
        createAction(ActionNames.GET_ENTITIES, {
          entityName: "products",
          searchField: "make",
          search,
        })
      );
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ config,user }) => {
  const { users } = config;
  const { roles } = users || {};
  const { role} = user || {};
  return {
    roles,
    role 
  };
};
Main.displayName = "User-Form";
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
