import React, { useState } from "react";
import { createAction, ActionNames } from "app-redux/actions";
import preProcess from "../preprocess";
import {  parse } from "papaparse";
import { Button, Icon, Upload, message } from "antd";

const CsvUpload = ({ upload, columns = {}, onSuccess, onError, formatData }) => {
  const [loading, setLoading] = useState(false);
  const fileRef = React.useRef();
  const convertData = (data, columns) => {
    return data.map((obj) => {
      let output = {};
      for (let key in obj) {
        output[columns[key]] = obj[key];
      }
      if (formatData instanceof Function) {
        output = formatData(output);
      }
      return output;
    });
  };
  const uploadFile = (e) => {
    parse(e.target.files[0], {
      delimiter: ",",
      newline: "",
      header: true,
      dynamicTyping: true,
      complete: async (result) => {
        setLoading(true)
        try {
          const {error} = await upload(convertData(result.data, columns));
          if(error){
            throw error
          }
          message.success("Import has been processed successfully")
          onSuccess instanceof Function && onSuccess();
        } catch (e) {
          console.error(e);
          message.error("Error while processing this file, Some records might not have been processed");
          onError instanceof Function && onError();
        }
        setLoading(false)
      },
    });
  };

  return (
    <React.Fragment>
      <Button
        className="add"
        type="primary"
        htmlType={"button"}
        onClick={() => {
          fileRef.current.click();
        }}
      >
        <span className="icon">
          <Icon type={loading ? "sync" : "upload"} theme="outlined" />
        </span>
        <span className="text">Import</span>
      </Button>
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileRef}
        accept="text/csv"
        onChange={uploadFile}
      />
    </React.Fragment>
  );
};

const bindAction = (dispatch, props) => {
  return {
    upload: (data) => {
      return dispatch(
        createAction(ActionNames.CREATE_ENTITY, {
          entityName: props.api,
          data,
        })
      );
    },
  };
};

export default preProcess(CsvUpload, {
  connect: [null, bindAction],
  localize: false,
});
