import React from "react";
import { Switch } from "antd";
import UpdateEntity from "containers/update-entity";
export default ({
  entityName,
  entityIdPropName,
  record,
  afterUpdate,
  enabledPropName,
  ...props
}) => {
  return record ? (
    <UpdateEntity
      entityName={entityName}
      entityIdPropName={entityIdPropName || "key"}
      record={record}
      getChildren={({ loading, updateEntity, record }) => {
        return (
          <Switch
            loading={loading}
            checked={record[enabledPropName || "enabled"]}
            onChange={async (checked) => {
              await updateEntity({ [enabledPropName || "enabled"]: checked });
              afterUpdate instanceof Function && afterUpdate(checked);
            }}
          />
        );
      }}
    />
  ) : null;
};
