import React from 'react'
import './styles.scss'
import { Row, Col, Icon, DatePicker, Button } from 'antd'
import Link from '../../../components/link'
import moment from 'moment'

var view = function () {
    const {dashboard} = this.props
    let {customers, users, service} = dashboard || {}
    service = service || {}
    const array = [
        {
            count: service['Pending'] || 0,
            label: 'Pending Jobs',
            route: 'home.my-jobs',
            jobStatus: 'Pending',
            icon: 'file-sync',
            color: 'primary',
        },
        {
            count: service['Allocated'] || 0,
            label: 'Allocated Jobs',
            route: 'home.my-jobs',
            jobStatus: 'Allocated',
            icon: 'file-done',
            color: 'primary',
        },
        {
            count: service['Scheduled'] || 0,
            label: 'Scheduled Jobs',
            route: 'home.my-jobs',
            jobStatus: 'Scheduled',
            icon: 'calendar',
            color: 'primary',
        },
        {
            count: service['Revisit Pending'] || 0,
            label: 'Revisit Pending Jobs',
            route: 'home.my-jobs',
            jobStatus: 'Revisit Pending',
            icon: 'copy',
            color: 'primary',
        },
        {
            count: service['Revisit Scheduled'] || 0,
            label: 'Revisit Scheduled Jobs',
            route: 'home.my-jobs',
            jobStatus: 'Revisit Pending',
            icon: 'snippets',
            color: 'primary',
        },
        {
            count: service['Parts Required'] || 0,
            label: 'Parts Required',
            route: 'home.my-jobs',
            jobStatus: 'Parts Required',
            icon: 'snippets',
            color: 'primary',
        },
        {
            count: service['Completed'] || 0,
            label: 'Completed jobs',
            route: 'home.my-jobs',
            jobStatus: 'Completed',
            icon: 'file-done',
            color: 'green',
        },
        {
            count: service['Cancelled'] || 0,
            label: 'Cancelled Jobs',
            route: 'home.my-jobs',
            jobStatus: 'Cancelled',
            icon: 'exception',
            color: 'red',
        },
        {
            count: service['Quote Required'] || 0,
            label: 'Quote Required',
            route: 'home.service',
            queryParams : {
                jobStatus: 'Quote Required',
            },
            icon: 'snippets',
            color: 'primary',
        },
        {
            count: service['today'] || 0,
            label: 'Jobs Added Today',
            route: 'home.my-jobs',
            icon: 'file-add',
            color: 'primary',
        },
        {
            count: service['outstanding'] || 0,
            label: 'Total Outstanding Jobs',
            route: 'home.my-jobs',
            icon: 'file-search',
            color: 'primary',
        },        
    ]
    return (
        <div className="dashboard">
            <div className="content">
                <p className="page-title mobile-only">Dashboard</p>
                <div className="dashboard-header form">
                    <div className="item">
                        <Button
                            onClick={() => {
                                this.setState({
                                    fromDate: moment().subtract(7, 'days').valueOf(),
                                    toDate: moment().valueOf(),
                                }, this.getDashboard)
                            }}
                            className="default-btn"
                            
                        >
                            Last 7 Days
                        </Button>
                    </div>
                    <div className="item">
                        <Button
                            onClick={() => {
                                this.setState({
                                    fromDate: moment().subtract(30, 'days').valueOf(),
                                    toDate: moment().valueOf(),
                                }, this.getDashboard)
                            }}
                            className="default-btn"
                        
                        >
                            Last 30 Days
                        </Button>
                    </div>
                    <div className="item">
                        <DatePicker.RangePicker
                            onChange={(date, dateString) => {
                                this.setState({
                                    fromDate: date[0].valueOf(),
                                    toDate: date[0].valueOf(),
                                }, this.getDashboard)
                            }}
                            placeholder="Select Date Range"
                        />
                    </div>
                    <div className="item">
                        <DatePicker.MonthPicker
                            onChange={(date, dateString) => {
                                this.setState({
                                    fromDate: moment(date).startOf('month').valueOf(),
                                    toDate: moment(date).endOf('month').valueOf(),
                                }, this.getDashboard)
                            }}
                            placeholder="Select month"
                        />
                    </div>
                </div>
                <Row gutter={16}>
                    {array.map((item, index) => {
                        const {count, label, route, jobStatus, color} = item
                        return (
                            <Col key={index} xs={24} sm={12} md={12} lg={12} xl={6}>
                                <Link
                                    routeKey={route}
                                    {...(jobStatus && {queryParams: {jobStatus}})}
                                >
                                    <div className={'card ' + color}>
                                        <div className="info">
                                            <div className="icon">
                                                <Icon type={item.icon}/>
                                            </div>
                                            <div className="details">
                                                <p className="count">{count || 0}</p>
                                                <p className="label">{label}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                        )
                    })}
                </Row>
            </div>
        </div>
    )
}
export default view
