/**
 * Contains Routing for the application
 */
import React from "react";
import { Route, IndexRoute, Router, Redirect } from "react-router";
import { history } from "./redux/config";
import { browserHistory } from "react-router";
import Crud from "./containers/crud";
import Dashboard from "./containers/dashboard";
import Error404 from "./containers/errors/404";
import Error401 from "./containers/errors/401";
import Error500 from "./containers/errors/500";
import ForgotPassword from "./containers/forgotPassword";
import Home from "./containers/home";
import Login from "./containers/login";
import Main from "./containers/main";
import Settings from "./containers/settings";
import ResetPassword from "./containers/resetPassword";
import Support from "./containers/support";
import UnderConstruction from "./containers/underConstruction";
import Pay from "./containers/pay";
import { ModalUtils } from "core-components/modal";
import { compile } from "path-to-regexp";

import CrudEntityRoutes from "./crud-entity-config";

//Import Modals here
import UserForm from "containers/forms/user-form";
import RoleForm from "containers/forms/role-form";
import ConfigurationForm from "containers/forms/configuration-form";
import GatewayForm from "containers/forms/payment-gateway-form";
import PdfTemplateForm from "containers/forms/pdf-template-form";
import TemplateForm from "containers/forms/template-form";
import SMSTemplateForm from "containers/forms/sms-form";
import ThemeForm from "containers/forms/theme-form";
import PermissionForm from "containers/forms/permission-form";
import ServiceForm from "./containers/forms/service-form";
import CustomerForm from "./containers/forms/customer-form";
import PartForm from "./containers/forms/part-form";
import ProductForm from "./containers/forms/products-form";
import ProductServiceForm from "./containers/forms/product-service-form";
import QuoteForm from "./containers/forms/quote-form";
import Scheduler from "containers/calendar";
import { TargetMonitor } from "containers/target-monitor";


/**
 * Route keys & Paths Map
 * @type {{Object}}
 */
const MainRoute = {
  component: Main,
  children: {
    home: {
      url: "home",
      component: Home,
      routeProps: {
        header: "secured",
      },
      children: {
        dashboard: {
          url: "",
          component: Dashboard,
        },
        calendar: {
          url: "calendar",
          component: Scheduler,
        },
        targetMonitor: {
          url: "target-monitor",
          component: TargetMonitor,
        },
        settings: {
          url: "settings",
          component: Settings,
        }
      },
    },
    login: {
      url: "login",
      component: Login,
      routeProps: {
        public: true,
        guestOnly: true,
      },
    },
    pay: {
      url: "pay/:id",
      component: Pay,
      routeProps: {
        public: true,
      },
    },
    resetPassword: {
      url: "reset-password",
      component: ResetPassword,
      routeProps: {
        public: true,
        guestOnly: true,
      },
    },
    forgotPassword: {
      url: "forgot-password",
      component: ForgotPassword,
      routeProps: {
        public: true,
      },
    },
    error404: {
      url: "error404",
      component: Error404,
      routeProps: { public: true },
    },
    error500: {
      url: "error500",
      component: Error500,
      routeProps: { public: true },
      header: true,
    },
    error401: {
      url: "error401",
      component: Error401,
      routeProps: { public: true },
    },
    underConstruction: {
      url: "underConstruction",
      component: UnderConstruction,
      routeProps: { public: true },
    },
    support: {
      url: "support",
      component: Support,
      routeProps: {
        public: true,
      },
    },
  },
};

/**
 * Configure Crud Entity Screens
 */
Object.keys(CrudEntityRoutes).map((key) => {
  const permissionMap = {
    read: key.toUpperCase() + "_READ",
    create: key.toUpperCase() + "_CREATE",
    edit: key.toUpperCase() + "_EDIT",
    delete: key.toUpperCase() + "_REMOVE",
  };
  let config = {
    url: key,
    component: Crud,
    routeProps: {
      permissionMap,
      permissions:
        typeof CrudEntityRoutes[key].permissions !== "undefined"
          ? CrudEntityRoutes[key].permissions
          : Object.values(permissionMap),
      ...CrudEntityRoutes[key],
      ...MainRoute.children.home.routeProps,
      crudEntityKey: key,
    },
  };

  if (CrudEntityRoutes[key].entityPage) {
    config.children = {};
    config.children["details"] = {
      url: ":id",
      ...CrudEntityRoutes[key].entityPage,
      ...MainRoute.children.home.routeProps,
    };
  }
  MainRoute.children.home.children[key] = config;
});

/**
 * Constructs a route based on route data and parent Route URL
 */
const constructRoutes = (routeObjects, routeUrl, parentKey, parentProps) => {
  routeObjects = routeObjects || {};
  const routeKeys = Object.keys(routeObjects);
  routeUrl = routeUrl || "";
  if (!routeKeys || !Array.isArray(routeKeys) || routeKeys.length <= 0) {
    return null;
  }

  let output = routeKeys.map((routeKey, index) => {
    let route = routeObjects[routeKey];
    const routeProps = route.routeProps || {};
    let props = {
      key: index,
      /**
       * Create HOC to pass route props
       * disable createContainer to disable the creation of default div with class route-container
       * @param componentProps
       * @returns {*}
       */
      component: !route.to
        ? (componentProps) => {
            return route.createContainer === false ? (
              <route.component {...componentProps} {...routeProps} />
            ) : (
              <div className={"route-container page"}>
                <route.component {...componentProps} {...routeProps} />
              </div>
            );
          }
        : undefined,
    };
    if (route.url != "") {
      props.path = routeUrl + "/" + route.url;
    }

    props = {
      ...props,
      ...routeProps,
      pageConfiguration: getPageConfiguration(props.path),
    };
    if (typeof parentProps != "undefined") {
      props.header = parentProps.header || props.header;
    }
    props.routeKey = parentKey ? parentKey + "." + routeKey : routeKey;
    return route.url !== "" ? (
      <Route {...props}>
        {route.to && <Redirect to={route.to} />}
        {constructRoutes(route.children, props.path, routeKey, routeProps)}
      </Route>
    ) : (
      <IndexRoute {...props}>
        {constructRoutes(route.children, props.path, routeKey, routeProps)}
      </IndexRoute>
    );
  });
  return output;
};

/**
 * todo : might not work for nested urls
 */
export function getRouteUrls() {
  const children = getAllRoutes();
  let urls = [];
  Object.keys(children).forEach((key) => {
    const { url, children: routeChildren } = children[key];
    if (url && urls.indexOf(url) === -1) {
      urls.push(url);
    }
    if (routeChildren) {
      Object.keys(routeChildren).forEach((key) => {
        const { url: childUrl } = routeChildren[key];
        if (url && childUrl) {
          urls.push(url + "/" + childUrl);
        }
      });
    }
  });
  return urls;
}

/**
 * Route Declarations
 * Set publicHeader = false to disable publicHeader
 * Set public = true to allow guest access to a route
 * @type {XML}
 */
export const routes = (store) => {
  return (
    <Router>
      <Route path="/" component={MainRoute.component}>
        {constructRoutes(MainRoute.children)}
        <Route path="*" component={Error404} />
      </Route>
    </Router>
  );
};

/**
 * Returns the route link url for a given route key
 * @param key
 * @returns {*}
 */
export function getRouteUrl(key, params) {
  if (!key) {
    return null;
  }
  let links = (key || "").split(".");
  let url = "";
  let routes = getAllRoutes();
  if (!routes[links[0]]) {
    return null;
  }
  links.map((link) => {
    if (routes[link]) {
      if (url === "/") {
        url = url + routes[link].url;
      } else {
        url = url + "/" + routes[link].url;
      }
      //getLinkUrl(routes[link].url);
      routes = routes[link].children || {};
    } else {
      console.log(
        "No such route key present " + link + " while parsing routeKey - " + key
      );
    }
  });
  url = url.replace("//", "/");
  if (params) {
    return compile(url)(params);
  } else {
    return url;
  }
}

export function getLinkUrl(url) {
  let parts = url.split("(");
  if (parts.length > 1) {
    return parts[0];
  }
  parts = url.split(":");
  if (parts.length > 1) {
    return parts[0];
  }
  return url;
}

/**
 * Returns the route Object route key
 * @param key
 * @returns {*}
 */
export function getRoute(key) {
  if (!key) {
    return null;
  }
  let links = (key || "").split(".");
  let route = null;
  let routes = getAllRoutes();
  if (!routes[links[0]]) {
    return null;
  }
  links.map((link) => {
    if (routes[link]) {
      route = routes[link];
      routes = routes[link].children || {};
    } else {
      console.log(
        "No such route key present " + link + " while parsing routeKey - " + key
      );
      route = null;
    }
  });
  return route;
}

/**
 * Goes to a specific route
 * @param route
 * @param config - Config for Browser History
 */
export function goToRoute(route, config) {
  config = config || {};
  let url = getRouteUrl(route, config.routeParams || {});
  if (url && url != "") {
    if (config.forceRefresh) {
      window.location.pathname = window.app.basename ? window.app.basename  +  url : url;
      if (config.search) {
        window.location.search = config.search;
      }
    } else {
      history.push({
        pathname: url,
        search: "",
        ...config,
      });
    }
  } else {
    //404 Handling
    //browserHistory.push("/404");
    goToRoute("home");
  }
}

/**
 * Go Back
 */
export function goBack() {
  history.goBack();
}

/**
 * Add Listener to route change
 * it is the responsibility of the
 * component which adds listener to remove
 * listener using unlisten method that is
 * returned
 * @param listener
 * @returns {*}
 */
export function addOnRouteChange(listener) {
  if (history && listener) {
    return history.listen((action, location) => {
      /**
       * This is done because the current route in
       * react-redux-router prop routes in component is
       * not updated in time
       */
      setTimeout(listener.bind(this, action, location));
    });
  }
}

const Modals = {
  RoleForm,
  UserForm,
  ConfigurationForm,
  GatewayForm,
  ServiceForm,
  CustomerForm,
  TemplateForm,
  PdfTemplateForm,
  SMSTemplateForm,
  ThemeForm,
  PermissionForm,
  PartForm,
  ProductForm,
  ProductServiceForm,
  QuoteForm
};
setTimeout(() => {
  ModalUtils.setModalScenes(Modals);
});

function getAllRoutes() {
  return MainRoute.children;
}

export function getPageConfiguration(path) {
  let config = (window.app.config.pageConfigurations || []).find((config) => {
    return "/" + config.routeUrl === path;
  });
  config = config || {};
  let output = {};
  (config.properties || []).forEach(({ name, value }) => {
    output[name] = value;
  });
  return output;
}
