import React, { useState, useEffect } from "react";
import { Select, Spin } from "antd";
import preProcess from "../preprocess";
import { createAction, ActionNames } from "app-redux/actions";
const { Option } = Select;
const Main = (props) => {
  const {
    getAllData,
    disabled,
    placeholder,
    filterData,
    formatter,
    filterOption,
    onChangeValue,
    mode = "multiple"
  } = props;
  const isSingle  = mode === "default";
  const [value, setValue] = useState(
    isSingle ? props.value : (props.value || []).map((item) => {
      return typeof item != "string" ? JSON.stringify(item) : item;
    })
  );
  const [data, setData] = useState([]);
  //const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    setLoading(true);
    try {
      const { error, payload } = await getAllData();
      if (error) {
        throw payload.response;
      }
      let { data } = payload.data;
      setData(data);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };
  const onChange = (value) => {
    setValue(value);
    const formatter = (item) => {
      try {
        const obj = JSON.parse(item);
        if (typeof obj === "object") {
          return obj;
        } else {
          return item;
        }
      } catch (e) {
        return item;
      }
    }
    const valueData = isSingle ? formatter(value) : value.map(formatter);
    if (props.onChange instanceof Function) {
      props.onChange(valueData, setValue);
    }
    onChangeValue instanceof Function && onChangeValue(valueData, data,setValue);
  };
  const getOptions = () => {
    let options = [];
    let optionsData = data;
    if (formatter instanceof Function) {
      if (filterData instanceof Function) {
        optionsData = data.filter(filterData);
      }
      options = optionsData.map((item) => {
        const { label, value } = formatter(item);
        return {
          label,
          value: typeof value !== "string" ? JSON.stringify(value) : value,
        };
      });
    } else {
      options = optionsData;
    }
    return options;
  };
  return (
    <Select
      mode={mode}
      className="select"
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      filterOption={(query, item) => {
        const { key, props } = item;
        if (filterOption instanceof Function) {
          return filterOption(query, data[key]);
        } else {
          return props.children.toLowerCase().indexOf(query.toLowerCase()) >= 0;
        }
      }}
      notFoundContent={loading && <Spin size="small" />}
    >
      {getOptions().map((option, index) => {
        const { label, value } = option;
        return (
          <Option key={index} value={value}>
            {label}
          </Option>
        );
      })}
    </Select>
  );
};
const bindAction = (dispatch, ownProps) => {
  const { entityName } = ownProps;
  return {
    getAllData: () => {
      return dispatch(
        createAction(ActionNames.GET_ENTITIES, {
          entityName,
          from: -1,
        })
      );
    },
  };
};
export default preProcess(Main, {
  localize: true,
  connect: [null, bindAction],
});
