import React from "react";
import "./styles.scss";
import { Form, Button, Input, Icon, Tooltip, Select, InputNumber } from "antd";
const { Option } = Select;
const { TextArea } = Input;
const { Item } = Form;
/**
 * Returns the JSX Markup for the view
 * @returns {XML}
 */
var view = function () {
  const { partsIndex } = this.state;
  const {
    form,
    getRules,
    data,
    translate,
    title,
    className,
    disabled,
    disableAdd,
  } = this.props;

  const { getFieldDecorator } = form;
  return (
    <div className={"meta-tags-form " + className}>
      <Form onSubmit={this.handleSubmit}>
        <div className="new-form seo-form">
          <div className="form">
            <div className="form-section">
              <p className="section-label">{title}</p>
              <div className="content">
                <div className="crud">
                  {!(disabled || disableAdd) && (
                    <div className="header">
                      <p></p>
                      <Button className="green-btn" onClick={this.addPart}>
                        <Icon type="plus" />
                        Add New
                      </Button>
                    </div>
                  )}
                  <div className="crud-content">
                    {partsIndex.map((num, index) => {
                      let { fields } = this.props;
                      if (fields instanceof Function) {
                        fields = fields(translate, data ? data[num] : undefined,num);
                      }
                      return (
                        <div key={num} className="inline">
                          {fields.map((field, index2) => {
                            const {
                              type,
                              key,
                              label,
                              placeholder,
                              options,
                              inputProps = {},
                              itemProps
                            } = field;
                            const rules = getRules(key, num, translate, field);

                            const initialValue =
                              data && data[num] && data[num][key];
                            inputProps.onChange = this.onChange;
                            let Component = (
                              <Input
                                disabled={disabled}
                                className="input"
                                placeholder={placeholder}
                                {...inputProps}
                              />
                            );
                            if (type === "select") {
                              Component = (
                                <Select
                                  disabled={disabled}
                                  className="select"
                                  placeholder={placeholder}
                                  {...inputProps}
                                >
                                  {(options || []).map((item, index3) => {
                                    const { label, value } = item;
                                    return (
                                      <Option key={index3} value={value}>
                                        {label}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              );
                            } else if (type === "number") {
                              Component = (
                                <InputNumber
                                  disabled={disabled}
                                  className="input"
                                  placeholder={placeholder}
                                  {...inputProps}
                                />
                              );
                            } else if (type === "custom") {
                              Component = <field.Component {...inputProps} />;
                            }
                            return (
                              <Item
                                key={index2}
                                hasFeedback={true}
                                label={label}
                                {...itemProps}
                              >
                                {getFieldDecorator("data[" + num + "]." + key, {
                                  rules,
                                  ...(initialValue && { initialValue }),
                                })(Component)}
                              </Item>
                            );
                          })}
                          {!disabled && (
                            <Tooltip title={"Delete"}>
                              <Button
                                className="red-btn-text delete-btn"
                                onClick={() => {
                                  this.removePart(index);
                                }}
                              >
                                <Icon type="delete" />
                              </Button>
                            </Tooltip>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default view;
