import React, { useState } from "react";
import { createAction, ActionNames } from "app-redux/actions";
import preProcess from "../preprocess";
import { unparse } from "papaparse";
import { Button, Icon } from "antd";

const CsvGenerator = ({ api, columns = {}, getJsonObject, formatData }) => {
  const [loading, setLoading] = useState(false);

  const convertData = (data) => {
    const obj = Object.keys(columns);
  
    if(formatData instanceof Function){
      data=formatData(data)
    }

    const selectedColumn = data.map((item) => {
      let newObj = {};
      for (let key in item) {
        if (obj.includes(key)) {
          const keyname = columns[key];
          const keyvalue = item[key];
          newObj[keyname] = keyvalue;
        }
      }
      return newObj;
    });

    const csv = unparse(selectedColumn, {
      columns : Object.values(columns)
    });
    const Filename = `${api}.csv`;

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, Filename);
    } else {
      let csvLink = document.createElement("a");
      if (csvLink.download !== undefined) {
        let url = URL.createObjectURL(blob);
        csvLink.setAttribute("href", url);
        csvLink.setAttribute("download", Filename);
        csvLink.style.visibility = "hidden";
        document.body.appendChild(csvLink);
        csvLink.click();
        document.body.removeChild(csvLink);
      }
    }
    setLoading(false);
  };

  const convertTocsv = async () => {
    setLoading(true);
    const data = await getJsonObject(api);
    if (data.payload && data.payload.data) convertData(data.payload.data.data);
    return setLoading(false);
  };

  return (
    <Button
      className="add"
      type="primary"
      htmlType={"button"}
      onClick={convertTocsv}
    >
      <span className="icon">
        <Icon
          spin={loading}
          type={loading ? "sync" : "download"}
          theme="outlined"
        />
      </span>
      <span className="text">Export</span>
    </Button>
  );
};

const bindAction = (dispatch, props) => {
  return {
    getJsonObject: (api) => {
      return dispatch(
        createAction(ActionNames.GET_ENTITIES, {
          entityName: api,
          sort: "timestamp",
          sortType: "desc",
          from: -1,
          ...props.query
        })
      );
    },
  };
};

export default preProcess(CsvGenerator, {
  connect: [null, bindAction],
  localize: false,
});
