var menuKeys = [
  {
    key: "home.dashboard",
    name: "Dashboard",
    icon: "desktop",
    isAllowed: (permissions, role) => ["admin","service","engineer"].includes(role.toLowerCase()),
  },
  {
    key: "home.service",
    name: "Service Jobs",
    icon: "tool",
    isAllowed: (permissions, role) => ["admin","service"].includes(role.toLowerCase()),
  },
  {
    key: "home.calendar",
    name: "Calendar",
    icon: "calendar",
    isAllowed: (permissions, role) =>  ["admin","service","engineer"].includes(role.toLowerCase()),
  },
  {
    key: "home.targetMonitor",
    name: "Target Monitor",
    icon: "rise",
    isAllowed: (permissions, role) =>  ["admin","service","engineer"].includes(role.toLowerCase()),
  },
  {
    key: "home.quotes",
    name: "Quotes",
    icon: "tags",
    isAllowed: (permissions, role) =>  ["admin","service"].includes(role.toLowerCase()),
  },
  {
    key: "home.my-jobs",
    name: "My Service Jobs",
    icon: "container",
  },

  {
    key: "home.invoice",
    name: "Invoices",
    icon: "red-envelope",
    isAllowed: (permissions, role) => ["admin","service"].includes(role.toLowerCase()),
  },
  {
    key: "home.my-invoices",
    name: "My Invoices",
    icon: "money-collect",
    isAllowed : (permissions,role) => role && role.toLowerCase() === 'customer',
  },
  {
    key: "home.customer",
    name: "Customers",
    icon: "solution",
    isAllowed: (permissions, role) => ["admin","service"].includes(role.toLowerCase()),
  },
  {
    key: "home.products",
    name: "Products",
    icon: "appstore",
    isAllowed: (permissions, role) => ["admin","service"].includes(role.toLowerCase()),
  },
  {
    key: "home.product-services",
    name: "Offered Services",
    icon: "book",
    isAllowed: (permissions, role) => ["admin","service"].includes(role.toLowerCase()),
  },
  {
    key: "home.parts",
    name: "Parts",
    icon: "api",
    isAllowed: (permissions, role) => ["admin","service","engineer"].includes(role.toLowerCase()),
  },
  {
    key: "home.contact",
    name: "Enquiries",
    icon: "question-circle",
    isAllowed: (permissions, role) => ["admin","service"].includes(role.toLowerCase()),
  },

  {
    key: "home.users",
    name: "Users",
    icon: "team",
    isAllowed: (permissions, role) => role.toLowerCase() === "admin",
  },

  {
    key: "home.permission",
    name: "Permissions",
    icon: "carry-out",
    isAllowed: (permissions, role) => role === "admin"
  },
  {
    key: "home.roles",
    name: "Roles",
    icon: "idcard",
    role: "admin",
    isAllowed: (permissions, role) => role.toLowerCase() === "admin",
  },

  {
    key: "home.email-template",
    name: "Email Templates",
    icon: "mail",
    isAllowed: (permissions, role) => role.toLowerCase() === "admin",
  },
  {
    key: "home.pdf-template",
    name: "PDF Templates",
    icon: "file-pdf",
    isAllowed: (permissions, role) => role.toLowerCase() === "admin",
  },

  {
    key: "home.sms-template",
    name: "SMS Templates",
    icon: "mobile",
    isAllowed: (permissions, role) => role.toLowerCase() === "admin",
  },
  {
    key: "home.theme",
    name: "Theming",
    icon: "block",
    isAllowed: (permissions, role) => role.toLowerCase() === "admin",
  },
  {
    key: "home.gateway",
    name: "Payment Gateways",
    icon: "dollar",
    isAllowed: (permissions, role) => role.toLowerCase() === "admin",
  },
  {
    key: "home.backup",
    icon: "sync",
    name: "Backup",
    isAllowed: (permissions, role) => role.toLowerCase() === "admin",
  },
  {
    key: "home.configuration",
    name: "Configurations",
    icon: "file-protect",
    isAllowed: (permissions, role) => role.toLowerCase() === "admin",
  },
  {
    key: "home.settings",
    name: "Settings",
    icon: "setting",
  },
];

export default menuKeys;
