import React, { useCallback, useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import timegrid from "@fullcalendar/timegrid";
import { ActionNames, createAction } from "app-redux/actions";
import preprocess from "../preprocess";
import moment from "moment";
import { ModalTrigger } from "core-components/modal";
import { DatePicker } from "antd";
import AutoSearch from "../autoSearch";
import "./styles.scss";
const Scheduler = ({ getMyJobs, role }) => {
  let [events, setEvents] = useState([]);
  const [assigned, setAssigned] = useState([]);
  const calendarRef = useRef(null);
  const getData = async () => {
    const { payload } = await getMyJobs({
      current: role === "ENGINEER",
    });
    setEvents(payload && payload.data ? payload.data.data : []);
  };
  useEffect(() => {
    getData();
  }, []);
  if (assigned.length > 0) {
    events = events.filter(({ assignedTo }) => {
      return assigned.length > 0
        ? assignedTo.find((item) => assigned.includes(item))
        : true;
    });
  }
  const renderEventContent = useCallback((eventInfo) => {
    const formId = "ServiceForm";
    return (
      <ModalTrigger
        modalId={formId}
        modalProps={{
          title: "Edit",
          className: "edit-record-modal " + formId,
          footer: null,
          contentProps: {
            data: eventInfo.event._def.extendedProps.record,
            getTableData: getData,
          },
        }}
      >
        <div class={eventInfo.event._def.extendedProps.jobStatus}>
          <p>{eventInfo.timeText}</p>
          <p>{eventInfo.event.id}</p>
          <p>{eventInfo.event._def.extendedProps.appliance}</p>
          <p>
            {eventInfo.event._def.extendedProps.record.siteAddress ||
              eventInfo.event._def.extendedProps.record.address}
          </p>
        </div>
      </ModalTrigger>
    );
  }, []);
  return (
    <div className="scheduler">
      <div className="scheduler-filter">
        <DatePicker
          defaultValue={moment()}
          onChange={(value) =>
            calendarRef.current.calendar.gotoDate(value.toDate())
          }
        />
        {["admin", "service"].includes((role || "").toLowerCase()) && (
          <AutoSearch
            placeholder="Type to Search Assignees"
            entityName="users"
            searchField="displayName"
            onChange={setAssigned}
            formatter={(item) => {
              const { uid, displayName, email } = item;
              return {
                label: `${displayName} (${email})`,
                value: uid,
              };
            }}
            filterData={({ role, emailVerified }) => {
              return (
                !!emailVerified &&
                (role || "").toLowerCase() !== "admin" &&
                (role || "").toLowerCase() !== "customer"
              );
            }}
          />
        )}
      </div>
      <FullCalendar
        ref={calendarRef}
        height={"90vh"}
        plugins={[timegrid]}
        initialView={["timeGridDay"]}
        allDaySlot={false}
        events={events.map((record, index) => {
          const {
            key,
            appliance,
            jobStatus,
            scheduledDate,
            revisitDate,
            repairCompletedDate,
          } = record;
          let start;
          switch (jobStatus) {
            case "Scheduled":
              start = scheduledDate;
              break;
            case "Revisit Scheduled":
              start = revisitDate;
              break;
            case "Completed":
              start = repairCompletedDate;
              break;
          }
          start = start || scheduledDate || new Date();
          return {
            id: key,
            appliance,
            start,
            end: moment(start).add(3, "hours").toDate(),
            record,
            jobStatus
          };
        })}
        eventContent={renderEventContent}
        headerToolbar={{
          left: "",
          center: "title",
          right: "",
        }}
      />
    </div>
  );
};

const bindAction = (dispatch, props) => {
  return {
    getMyJobs: (query) => {
      return dispatch(
        createAction(
          ActionNames.GET_ENTITIES,
          {
            entityName: "service",
            sort: "timestamp",
            sortType: "desc",
            from: -1,
            type: "scheduler",
            ...query,
          },
          true
        )
      );
    },
  };
};
const mapStateToProps = ({ user }) => {
  const { role } = user || {};
  return {
    role,
  };
};
export default preprocess(Scheduler, {
  connect: [mapStateToProps, bindAction],
  localize: false,
});
