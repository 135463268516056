import React, { useEffect, useState } from "react";
import preprocess from "../preprocess";
import { Button, DatePicker, Row, Select, Col, Icon, message } from "antd";
import moment from "moment";
import AutoSearch from "../autoSearch";
import { ActionNames, createAction } from "app-redux/actions";

const isBehindTarget = (current, expected, shouldBeLesserThanExpected) => {
  return (
    ((shouldBeLesserThanExpected ? current - expected : expected - current) /
      expected) *
    100
  );
};

const CARDS = [
  {
    label: "First time fix",
    key: "firstTimeFix",
    icon: "file-search",
    color: "primary",
    isBehindTarget: (val) => isBehindTarget(val, 75) > 10,
  },
  {
    label: "Completion rate",
    key: "completionRate",
    icon: "file-search",
    color: "primary",
    isBehindTarget: (val) => isBehindTarget(val, 80) > 10,
  },
  {
    label: "BER",
    key: "ber",
    icon: "file-search",
    color: "primary",
    isBehindTarget: (val) => isBehindTarget(val, 10, true) > 10,
  },
  {
    label: "Recall",
    key: "recall",
    icon: "file-search",
    color: "primary",
    isBehindTarget: (val) => isBehindTarget(val, 5, true) > 10,
  },
];

const Card = ({ color, icon, count, label }) => (
  <Col xs={24} sm={12} md={12} lg={12} xl={6}>
    <div className={"card " + color}>
      <div className="info">
        <div className="icon">
          <Icon type={icon} />
        </div>
        <div className="details">
          <p className="count">{count}</p>
          <p className="label">{label}</p>
        </div>
      </div>
    </div>
  </Col>
);

const Component = ({ isAdmin, getMonitors, userId }) => {
  const [dates, setDates] = useState({
    fromDate: moment().subtract(moment().quarter(), "months").valueOf(),
    toDate: moment().valueOf(),
  });
  const [assigned, setAssigned] = useState(isAdmin ? undefined : userId);
  const [data, setData] = useState(undefined);
  useEffect(() => {
    if (dates.fromDate && dates.toDate) {
      window.startSpinning(".dashboard");
      getMonitors({
        ...dates,
        userId: assigned,
      })
        .then(({ payload, error }) => {
          if (!error) {
            setData(payload.data);
          } else {
            message.error("Error while loading the data");
          }
        })
        .finally(() => window.stopSpinning(".dashboard"));
    }
  }, [assigned, dates]);
  return (
    <div className="dashboard">
      <div className="content">
        <p className="page-title mobile-only">Target Monitor</p>
        <div className="dashboard-header form">
          <div className="item">
            {isAdmin && (
              <AutoSearch
                placeholder="Search User"
                entityName="users"
                searchField="displayName"
                onChange={setAssigned}
                mode="default"
                allowClear = {true}
                formatter={(item) => {
                  const { uid, displayName, email } = item;
                  return {
                    label: `${displayName} (${email})`,
                    value: uid,
                  };
                }}
                filterData={({ role, emailVerified }) => {
                  return (
                    !!emailVerified && (role || "").toLowerCase() === "engineer"
                  );
                }}
              />
            )}
          </div>
          <div className="item">
            <Select
              className="select"
              placeholder="Select Quarter"
              dropdownStyle={{ minWidth: 200 }}
              value = {moment(dates.toDate).quarter()}
              onChange={(value) => {
                setDates({
                  fromDate: moment().subtract(value, "months").valueOf(),
                  toDate: moment().valueOf(),
                });
              }}
            >
              <Select.Option value={1}>Jan - March</Select.Option>
              <Select.Option value={2}>April - June</Select.Option>
              <Select.Option value={3}>July - September</Select.Option>
              <Select.Option value={4}>October - December</Select.Option>
            </Select>
          </div>
          <div className="item">
            <Button
              onClick={() => {
                setDates({
                  fromDate: moment().subtract(7, "days").valueOf(),
                  toDate: moment().valueOf(),
                });
              }}
              className="default-btn"
            >
              Last 7 Days
            </Button>
          </div>
          <div className="item">
            <Button
              onClick={() => {
                setDates({
                  fromDate: moment().subtract(30, "days").valueOf(),
                  toDate: moment().valueOf(),
                });
              }}
              className="default-btn"
            >
              Last 30 Days
            </Button>
          </div>
          <div className="item">
            <DatePicker.RangePicker
              onChange={(date) => {
                setDates({
                  fromDate: date[0].valueOf(),
                  toDate: date[0].valueOf(),
                });
              }}
              value={[moment(dates.fromDate), moment(dates.toDate)]}
              placeholder="Select Date Range"
            />
          </div>
          <div className="item">
            <DatePicker.MonthPicker
              onChange={(date) => {
                setDates({
                  fromDate: moment(date).startOf("month").valueOf(),
                  toDate: moment(date).endOf("month").valueOf(),
                });
              }}
              placeholder="Select month"
            />
          </div>
        </div>
        {data && (
          <Row gutter={16}>
            {CARDS.map((item, index) => {
              const value = data[item.key] || 0;
              return (
                <Card
                  key={index}
                  color={
                    item.isBehindTarget(value) ? "behind-target" : item.color
                  }
                  label={item.label}
                  count={`${value} %`}
                />
              );
            })}
          </Row>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    isAdmin: ["admin", "service"].includes((user.role || "").toLowerCase()),
    userId: user.uid,
  };
};

const bindAction = (dispatch) => {
  return {
    getMonitors: (query) => {
      return dispatch(
        createAction(
          ActionNames.GET_ENTITIES,
          {
            entityName: "dashboard/targetMonitor",
            ...query,
          },
          true
        )
      );
    },
  };
};

export const TargetMonitor = preprocess(Component, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
