import React from 'react'
import './styles.scss'
import { Input, Button, Form, Radio } from 'antd'
import Link from '../../components/link'

const FormItem = Form.Item
/**
 * Returns the JSX Markup for the view
 * @returns {XML}
 */
var view = function () {
    const {translate, form, ui} = this.props
    const {getFieldDecorator} = form
    const {email} = this.validations
    return (
        <div className="page">
            <div className="general-form forgetPassword">
                <div className="content">
                    <div className="form">
                        <div className="fields">
                            <p className="title">{translate('forgot.title')}</p>
                            <p className="description">{translate('forgot.subTitle')}</p>
                            <Form onSubmit={this.handleSubmit.bind(this)}>
                                <FormItem hasFeedback={true}>
                                    {
                                        getFieldDecorator('email', email)(<Input
                                            placeholder={translate('common.email.placeholder')}
                                            className="input" />)
                                    }
                                </FormItem>
                                <div className="action">
                                    <Button htmlType={'submit'} className="btn green-btn">{translate('forgot.submit')}</Button>
                                </div>
                            </Form>
                        </div>
                        <div className="options shared">
                            <div className="option">
                                <p className="optionLabel">{translate('forgot.mistake')}</p>
                                <Link
                                    className="textLink"
                                    onClick={ui.activeTab ? this.toggleForm.bind(this, 'login') : null}
                                    routeKey={ui.activeTab ? null : 'login'}
                                >{translate('common.login')}
                                </Link>
                            </div>
                            <div className="option">
                                <p className="optionLabel">Need Support?</p>
                                <Link
                                    className="textLink"
                                    routeKey={'support'}
                                >
                                    Support
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default view
