import React from "react";
import { formatDate } from "utils/date-util";
import service, { ViewJob, SendJob, PrintJob } from "./service";
const modifiedColumns = [...service.columns];
modifiedColumns[0] = {
  ...modifiedColumns[0],
  render: (customer, { customerName, customerType, siteContactName }) => {
    if (customerType === "Trade") {
      customer = siteContactName;
    } else {
      if (typeof customer === "object") {
        customer = customer.customerName;
      } else {
        customer = customerName;
      }
    }
    return <p>{customer}</p>;
  },
};
export default {
  entityName: "My Service Jobs",
  formId: "ServiceForm",
  ...service,
   getRequestParams: (props) => {
    return {
      current: true,
      ...props.location.query,
    };
  },
  columns: [
    {
      title: "Customer Name",
      dataIndex: "customer",
      key: "customerName",
      render: (customer, { customerName, customerType, siteContactName }) => {
        if (customerType === "Trade") {
          customer = siteContactName;
        } else {
          if (typeof customer === "object") {
            customer = customer.customerName;
          } else {
            customer = customerName;
          }
        }
        return <p>{customer}</p>;
      },
    },

    {
      title: "Job Number",
      dataIndex: "key",
    },

    {
      title: "Status",
      dataIndex: "jobStatus",
    },
    {
      title: "Date Added",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return <p>{formatDate(date)}</p>;
      },
    },
    {
      title: "Service Type",
      dataIndex: "serviceType",
      key: "serviceType",
    },
  ],
  extraSecurityCheck: (allowedPermissions, allowedRoles, permissions, role) => {
    return role !== "admin";
  },
  deleteAction: false,
  rowActions: [ViewJob, PrintJob],
};
