import React, { Component } from "react";
import ComponentView from "./view";
import preProcess from "../../preprocess";
import { Form, message } from "antd";
import { getObjectsDiff } from "../../../utils/common";
import { createAction, ActionNames } from "../../../redux/actions";
import moment from "moment";
import {
  DEFAULT_CUSTOMER_TYPE,
  isPrivateCustomer,
} from "../../container-helpers/customer";
import {
  CALLOUT_PART,
  isDomesticAndGeneral,
} from "../../container-helpers/service-job";
const spinningSelector = ".new-form.form.service";

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      partOptions: [],
      serviceOptions: [],
      customerProducts: [],
      users: [],
      customers: [],
      customer: null,
      fetchingCustomers: false,
      customerType: props.data ? null : DEFAULT_CUSTOMER_TYPE,
      partsValue: (this.props.data && this.props.data.parts) || [],
      invoiceItems: (this.props.data && this.props.data.invoiceItems) || [],
    };
    this.onSearch = this.onSearch.bind(this);
    this.getCustomerDetails = this.getCustomerDetails.bind(this);
  }

  /**
   * get Customer Parts
   */
  async getCustomerDetails(customerValue) {
    const { getSingleCustomer, getProducts } = this.props;
    try {
      if (customerValue) {
        if (customerValue !== "create-new") {
          const { error, payload } = await getSingleCustomer(customerValue);
          if (error) {
            throw payload.response;
          }
          let { products } = payload.data;
          products = products || [];
          if (!Array.isArray(products)) {
            products = [products];
          }
          this.setState({
            customerProducts: products.map(({ key, make, model, colour }) => {
              return { key, make, model, colour };
            }),
            customer: payload.data,
            customers: [payload.data],
            customerType: payload.data.customerType || DEFAULT_CUSTOMER_TYPE,
            invoiceItems: [
              ...this.state.invoiceItems,
              // For domestic & general - all new jobs will have an invoice item - Call Out & Labour Charge £55+vat 
              ...(!this.props.data && isDomesticAndGeneral(payload.data) ? [CALLOUT_PART] : []),
            ],
          });
        } else {
          this.setState({
            customerProducts: [],
            customer: null,
            customers: [],
            customerType: DEFAULT_CUSTOMER_TYPE,
          });
        }
      }
    } catch (e) {
      console.error("Error in fetching Customer Products", { e });
    }
  }

  async onSearch(search) {
    const { getCustomers } = this.props;
    try {
      if (search) {
        this.setState({
          fetchingCustomers: true,
        });
        const { error, payload } = await getCustomers(search);
        if (error) {
          throw payload.response;
        }
        this.setState({
          customers: payload.data.data,
          fetchingCustomers: false,
        });
      }
    } catch (e) {
      console.error("Error in searching customers", { e });
      this.setState({
        fetchingCustomers: false,
      });
    }
  }

  /**
   * Sets Validations for fields
   */
  setValidations() {
    const { data, form } = this.props;
    const { jobNumber } = this.state;
    const statusValue =
      (form && form.getFieldValue("jobStatus")) || (data && data.jobStatus);
    const isCompleted = statusValue === "Completed";
    this.validations = {
      key: {
        rules: [
          {
            required: true,
            message: "Enter Asg Job Number",
          },
        ],
        initialValue: data ? data.key : jobNumber,
      },
      corporateNumber: {
        initialValue: data ? data.corporateNumber : null,
      },
      customer: {
        rules: [
          {
            required: true,
            message: "Select Customer",
          },
        ],
        initialValue:
          data && data.customer && typeof data.customer === "string"
            ? data.customer
            : undefined,
      },
      date: {
        rules: [
          {
            required: true,
            message: "Enter Date Added",
          },
        ],
        initialValue: data ? moment(new Date(data.date)) : moment(new Date()),
      },
      // productType: {
      //   rules: [
      //     {
      //       required: true,
      //       message: "Enter Product Type",
      //     },
      //   ],
      //   initialValue: data ? data.productType : null,
      // },
      // productBrand: {
      //   rules: [
      //     {
      //       required: true,
      //       message: "Enter Product Brand",
      //     },
      //   ],
      //   initialValue: data ? data.productBrand : null,
      // },
      // model: {
      //   rules: [
      //     {
      //       required: true,
      //       message: "Enter Model",
      //     },
      //   ],
      //   initialValue: data ? data.model : null,
      // },
      jobStatus: {
        rules: [
          {
            required: true,
            message: "Select Job Status",
          },
        ],
        ...(data
          ? {
              initialValue: data.jobStatus,
            }
          : { initialValue: "Pending" }),
      },
      sign: {
        initialValue: data ? data.sign : null,
      },
      faultReported: {
        rules: [
          {
            required: true,
            message: "Enter Fault Reported",
          },
        ],
        initialValue: data ? data.faultReported : null,
      },
      faultDiagnosed: {
        rules: [
          {
            required: isCompleted,
            message: "Please enter the fault diagnosed",
          },
        ],
        initialValue: data ? data.faultDiagnosed : null,
      },
      serviceDetails: {
        rules: [
          {
            required: isCompleted,
            message: "Please enter the work carried out",
          },
        ],
        initialValue: data ? data.serviceDetails : null,
      },
      repairCompletedDate: {
        rules: [
          {
            required: true,
            message: "Select Completion Date",
          },
        ],
        ...(data && data.repairCompletedDate
          ? {
              initialValue: moment(new Date(data.repairCompletedDate)),
            }
          : {}),
      },
      partsUsed: {
        initialValue: data ? data.partsUsed : null,
      },
      partsCosts: {
        initialValue: data ? data.partsCosts : null,
      },
      labourTransportCosts: {
        initialValue: data ? data.labourTransportCosts : null,
      },
      generalRemarks: {
        initialValue: data ? data.generalRemarks : null,
      },
      attachment: {
        initialValue: data ? data.attachment : null,
      },
      /**
       * Remember if initialValue is not defined, setFieldsValue and resetFields also doesn't work somehow
       * also don't use data && data.parts as it still doesn't work that way
       */
      parts: {
        initialValue: data ? data.parts : [],
      },
      services: {
        initialValue: data ? data.services : [],
      },
      product: {
        rules: [
          {
            required: true,
            message: "Product is Required",
          },
        ],
        ...(data &&
        data.productType &&
        data.productBrand &&
        data.model &&
        data.serialNumber
          ? {
              initialValue: JSON.stringify({
                key: data.serialNumber,
                make: data.productBrand,
                model: data.model,
                colour: data.productType,
              }),
            }
          : {}),
      },
      assignedTo: {
        rules: [
          {
            required: true,
            message: "Select Assignee",
          },
        ],
        initialValue: data && data.assignedTo,
      },
      discount: {
        initialValue: data ? data.discount : undefined,
      },
      customerJobNumber: {
        initialValue: data ? data.customerJobNumber : undefined,
      },
      customerPoNumber: {
        initialValue: data ? data.customerPoNumber : undefined,
      },
      siteAddress: {
        rules: [
          {
            required: !isPrivateCustomer(this.state.customerType),
            message: "Enter Site Address",
          },
        ],
        initialValue: data ? data.siteAddress : undefined,
      },
      siteContactName: {
        rules: [
          {
            required: !isPrivateCustomer(this.state.customerType),
            message: "Enter Site Contact Name",
          },
        ],
        initialValue: data ? data.siteContactName : undefined,
      },
      sitePhoneNumber: {
        rules: [
          {
            required: !isPrivateCustomer(this.state.customerType),
            message: "Enter Site Phone Number",
          },
        ],
        initialValue: data ? data.sitePhoneNumber : undefined,
      },
      siteMobileNumber: {
        rules: [
          {
            required: !isPrivateCustomer(this.state.customerType),
            message: "Enter Site Mobile Number",
          },
        ],
        initialValue: data ? data.siteMobileNumber : undefined,
      },
      postCode: {
        rules: [
          {
            required: true,
            message: "Enter Post Code",
          },
        ],
        initialValue: data ? data.postCode : undefined,
      },
      preferredAppointmentDate: {
        rules: [
          {
            required: true,
            message: "Select Preferred Appointment Date",
          },
        ],
        ...(data && data.preferredAppointmentDate
          ? {
              initialValue: moment(new Date(data.preferredAppointmentDate)),
            }
          : {}),
      },
      serviceType: {
        rules: [
          {
            required: true,
            message: "Select Service Type",
          },
        ],
        initialValue: data ? data.serviceType : undefined,
      },
      category: {
        rules: [
          {
            required: true,
            message: "Select Category",
          },
        ],
        initialValue: data ? data.category : undefined,
      },
      appliance: {
        rules: [
          {
            required: true,
            message: "Select Appliance",
          },
        ],
        initialValue: data ? data.appliance : undefined,
      },
      applianceBrand: {
        rules: [
          {
            required: true,
            message: "Enter Appliance Brand",
          },
        ],
        initialValue: data ? data.applianceBrand : undefined,
      },
      applianceType: {
        rules: [
          {
            required: true,
            message: "Select Appliance Type",
          },
        ],
        initialValue: data ? data.applianceType : undefined,
      },
      applianceConnection: {
        rules: [
          {
            required: true,
            message: "Select Appliance Connection",
          },
        ],
        initialValue: data ? data.applianceConnection : undefined,
      },
      applianceModelNumber: {
        rules: [
          {
            required: isCompleted,
            message: "Please enter the model number",
          },
        ],
        initialValue: data ? data.applianceModelNumber : undefined,
      },
      applianceSerialNumber: {
        rules: [
          {
            required: isCompleted,
            message: "Please enter the serial number",
          },
        ],
        initialValue: data ? data.applianceSerialNumber : undefined,
      },
      faultCode: {
        initialValue: data ? data.faultCode : undefined,
      },
      scheduledDate: {
        rules: [
          {
            required: true,
            message: "Select Scheduled Date",
          },
        ],
        ...(data && data.scheduledDate
          ? {
              initialValue: moment(new Date(data.scheduledDate)),
            }
          : {}),
      },
      revisitDate: {
        rules: [
          {
            required: true,
            message: "Select Revisit Date",
          },
        ],
        ...(data && data.revisitDate
          ? {
              initialValue: moment(new Date(data.revisitDate)),
            }
          : {}),
      },
      manualAdjustment: {
        initialValue: data ? data.manualAdjustment : undefined,
      },
      repairLimit: {
        initialValue: data ? data.repairLimit : undefined,
      },
      partsPreordered: {
        initialValue: data ? data.partsPreordered : undefined,
      },
      engineerETA: {
        initialValue: data ? data.engineerETA : undefined,
      },
      policyNumber: {
        initialValue: data ? data.policyNumber : undefined,
      },
      claimNumber: {
        initialValue: data ? data.claimNumber : undefined,
      },
    };
  }

  /**
   * ComponentDidMount Hook
   */
  async componentDidMount() {
    const { getJobNumber, data, getUsers } = this.props;
    try {
      if (!data) {
        const { error, payload } = await getJobNumber();
        if (error) {
          throw payload.response;
        }
        this.setState({
          jobNumber: payload.data,
        });
      }
      if (data && data.customer && typeof data.customer === "string") {
        this.getCustomerDetails(data.customer);
      }
      if (data && data.assignedTo && data.assignedTo.length) {
        const { error, payload } = await getUsers(data.assignedTo.join(","));
        if (error) {
          throw payload.response;
        }
        if (Array.isArray(payload.data)) {
          this.setState({
            users: payload.data,
          });
        } else {
          this.setState({
            users: [payload.data],
          });
        }
      }
    } catch (e) {
      console.error("Error while mounting service job", { e });
    }
  }

  getCustomerFormData() {
    return new Promise((resolve) => {
      try {
        this.customerFormRef.props.form.validateFieldsAndScroll(
          (errors, values) => {
            resolve({
              errors,
              values,
            });
          }
        );
      } catch (e) {
        resolve(e);
      }
    });
  }

  //   isDateChanged(date1, date2) {
  //     if (date1 && date2) {
  //       return new Date(date1).getTime() !== new Date(date2).getTime();
  //     } else {
  //       return true;
  //     }
  //   }

  handleSubmit(event) {
    event.preventDefault();
    const {
      role,
      getTableData,
      hideModal,
      createRecord,
      updateRecord,
      uploadImage,
      data,
      form,
      uid,
      permissions,
    } = this.props;
    const { validateFieldsAndScroll } = form;
    validateFieldsAndScroll(async (errors, values) => {
      const { errors: partErrors } = this.partsForm
        ? await this.partsForm.validate()
        : {};
      const form = await this.getCustomerFormData("customer");
      errors = partErrors ? partErrors : errors;
      errors = form.errors
        ? {
            ...form.errors,
          }
        : errors;
      if (errors) {
        return;
      }

      const { errors: invoiceItemsErrors, values: invoiceItemsValues } = this
        .invoiceItemsForm
        ? await this.invoiceItemsForm.validate()
        : {};

      errors = invoiceItemsErrors ? invoiceItemsErrors : errors;
      errors = form.errors
        ? {
            ...form.errors,
          }
        : invoiceItemsErrors;
      if (errors) {
        return;
      }

      /*if (isUserAllowed(
        {
          permissions: ["SERVICE_EDIT"],
        },
        {
          permissions,
          role,
        }
      )) {
        values.assignedTo = [uid];
      }*/
      if (data && data.jobStatus === "Completed") {
        if (role !== "admin") {
          message.error("This job is already completed");
          return;
        }
      }
      if (values.customer === "create-new") {
        values.customer = form.values;
      }
      // if (typeof values.product === "string") {
      //   values.product = JSON.parse(values.product);
      // }
      values.parts = this.state.partsValue;
      values.invoiceItems = invoiceItemsValues;
      // const { key, make, colour, model } = values.product;
      // values.productBrand = make;
      // values.productType = colour;
      // values.model = model;
      // values.serialNumber = key;
      values.saveCustomer = true;
      values.discount = values.discount || 0;
      if(values.jobStatus === 'Completed' && role.toLowerCase() === 'engineer'){
        values.repairCompletedDate = new Date()
      }
      // delete values.product;
      window.startSpinning(spinningSelector);
      try {
        const { attachment } = values;
        if (attachment) {
          values.attachment = [];
          await Promise.all(
            attachment.map(async (file) => {
              if (typeof file === "object") {
                const { dataUri, name, url, type } = file;
                if (dataUri) {
                  const { error, payload } = await uploadImage({
                    name,
                    file: dataUri,
                    type,
                  });
                  if (error) {
                    throw payload.response;
                  }
                  values.attachment.push({
                    name,
                    url: payload.data.url,
                    type,
                  });
                } else {
                  values.attachment.push({
                    name,
                    url,
                    type,
                  });
                }
              }
            })
          );
        } else {
          values.attachment = [];
        }
        if (data) {
          const { error, payload } = await updateRecord({
            entityId: data.key,
            ...getObjectsDiff(values, data),
            ...(JSON.stringify(values.assignedTo) ===
            JSON.stringify(data.assignedTo)
              ? {}
              : { assignedTo: values.assignedTo }),
            parts: values.parts || [],
            invoiceItems: values.invoiceItems || [],
            attachment: values.attachment,
          });
          if (error) {
            throw payload.response;
          }
        } else {
          const { error, payload } = await createRecord(values);
          if (error) {
            throw payload.response;
          }
        }
        if (hideModal instanceof Function) {
          hideModal();
        }
        if (getTableData instanceof Function) {
          getTableData();
        }
        if (data) {
          message.success("Service Job Updated Successfully");
        } else {
          message.success("Service Job Created Successfully");
        }
      } catch (e) {
        console.log(e);
        if (data) {
          message.error("Error while Updating Service");
        } else {
          if (e.status === 404) {
            message.error("Service Job with this Job Number already Exists.");
          } else {
            message.error("Error while Creating Service");
          }
        }
      }
      window.stopSpinning(spinningSelector);
    });
  }

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    getUsers: (entityId) => {
      return dispatch(
        createAction(ActionNames.GET_ENTITY, {
          entityName: "users",
          entityId,
        })
      );
    },
    getProducts: (entityId) => {
      return dispatch(
        createAction(ActionNames.GET_ENTITY, {
          entityName: "products",
          entityId,
        })
      );
    },
    getSingleCustomer: (entityId) => {
      return dispatch(
        createAction(ActionNames.GET_ENTITY, {
          entityId,
          entityName: "customer",
        })
      );
    },
    searchEntity: (data) => {
      return dispatch(createAction(ActionNames.GET_ENTITIES, data));
    },
    createRecord: (data) => {
      const action = createAction(ActionNames.CREATE_ENTITY, {
        entityName: "service",
        ...data,
      });
      action.type = action.type + "_service";
      return dispatch(action);
    },
    updateRecord: ({ entityId, ...data }) => {
      const action = createAction(ActionNames.UPDATE_ENTITY, {
        entityName: "service",
        entityId,
        ...data,
      });
      action.type = action.type + "_service";
      return dispatch(action);
    },
    uploadImage: (file) => {
      return dispatch(
        createAction(ActionNames.CREATE_ENTITY, {
          entityName: "storage",
          ...file,
          fileCode: "serviceAttachment",
        })
      );
    },
    getJobNumber: () => {
      return dispatch(
        createAction(ActionNames.GET_ENTITY, {
          entityName: "service",
          entityId: "getJobNumber",
        })
      );
    },
    getCustomers: (search) => {
      const action = createAction(ActionNames.GET_ENTITIES, {
        entityName: "customer",
        searchField: "customerName",
        from: -1,
        search,
      });
      action.type = action.type + "_customer";
      return dispatch(action);
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ user, customer, config }) => {
  const { configuration } = config || {};
  const { CURRENCY } = configuration || {};
  const { data } = customer || {};
  const { role, permissions, uid } = user || {};
  return {
    customers: data,
    role,
    CURRENCY,
    permissions,
    uid,
  };
};
Main.displayName = "Service-Form";
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
