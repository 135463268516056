import React from "react";
import Invoice from "./invoice";

import {Select} from "antd";
const { Option } = Select;

export default {
  entityName: "My Invoices",
  ...Invoice,
  addAction: false,
  extraSecurityCheck: (allowedPermissions, allowedRoles, permissions, role) => {
    return role !== "admin";
  },
  headerActions : [],
  search: {
    filters: [
      {
        title: "Invoice Number",
        type: "input",
        key: "id",
      },
      {
        title: "Is Paid",
        type: "select",
        key: "isPaid",
        options: ["Yes", "No"].map((item, index) => {
          return (
            <Option key={index} value={(item === "Yes").toString()}>
              {item}
            </Option>
          );
        }),
      },
      {
        title: "Invoice Date",
        type: "date",
        key: "createdDate",
      },
    ],
    searchOnSubmit: true,
  },
};
