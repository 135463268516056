import React, {Component} from 'react';
import ComponentView from './view';
import preProcess from '../preprocess';

/**
 * @description Login Form Container
 * @type Container
 * @author Jasjot
 */
class Main extends Component {

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);

    }

    /**
     * ComponentDidMount Hook
     */
    componentDidMount() {
    }


    /**
     * Render Method
     * @returns {*}
     */
    render() {
        return (ComponentView.bind(this))();
    }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {}
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
    return {
        emitter: state.emitter
    }
};
Main.displayName = "Settings-Form";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
    connect: [mapStateToProps, bindAction],
    localize: true,
    firebase: true
});
