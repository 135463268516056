import React from "react";
import "./styles.scss";
import UserDashboard from "./userDashboard";
import AdminDashboard from "./adminDashboard";
var view = function() {
  const { user } = this.props;
  return user && [ "admin","service"].includes((user.role || "").toLowerCase()) ? (
    <AdminDashboard />
  ) : (
    <UserDashboard />
  );
};
export default view;
