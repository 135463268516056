import { Icon, Input } from "antd";
import React from "react";

export function PhoneLink({ value, ...props }) {
  return (
    <a className="phone-link" {...props} href={value ? `tel:${value}` : undefined}>
      <Icon type="phone" />
    </a>
  );
}

export default function PhoneInput(props) {
  return (
    <Input
      className="input"
      {...props}
      addonAfter={
        <PhoneLink value={props.value}/>
      }
    />
  );
}
