import React from 'react'
import './styles.scss'
import {Select} from 'antd'

/**
 * Returns the JSX Markup for the view
 * @returns {XML}
 */
var view = function () {
    const {tags} = this.state;
    const {disabled} = this.props;
    return (
        <div className="tags">
            <Select
                mode='tags'
                value={tags}
                onChange={this.onChange}
                dropdownStyle={{
                    display: 'none'
                }}
                disabled = {disabled}
            />
        </div>
    )
}
export default view
