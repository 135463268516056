import React from 'react';
import './country.scss';
import './styles.scss';
import Flags from './flags.png';
import ReactTelInput from 'react-telephone-input';

/**
 * Returns the JSX Markup for the view
 * @returns {XML}
 */
var view = function () {
    return (
        <ReactTelInput
            defaultCountry="in"
            flagsImagePath={Flags}
            {
                ...this.props
            }
        />
    )
}
export default view;
