import React from "react";
import "./styles.scss";
import {
  Form,
  Input,
  InputNumber,
  Icon,
  Button,
  Select,
  DatePicker,
  Row,
  Col,
  message,
  Modal,
} from "antd";
import FilePicker from "core-components/file-picker";
import CustomerForm from "../customer-form";
import SignatureCapture from "core-components/signature-capture";
import { getAllISOCodes } from "iso-country-currency";
import AutoSearch from "../../autoSearch";
import ClientSearch from "../../clientSearch";
import { parseJSON } from "utils/web";
import { isUserAllowed } from "../../with-security";
import {
  APPLIANCE,
  APPLIANCE_TYPE,
  CALLOUT_PART,
  CATEGORY,
  CONNECTION,
  ENGINEER_ETA,
  JOB_STATUSES,
  PARTS_PREORDERED,
  SERVICE_TYPES,
  getDomesticAndGeneralPartCost,
  isDomesticAndGeneral,
} from "../../container-helpers/service-job";
import { isPrivateCustomer } from "../../container-helpers/customer";
import MultiRecordForm from "../../multi-record-form";
import moment from "moment";
import { DEFAULT_FORMAT } from "utils/date-util";
import PhoneInput, { PhoneLink } from "core-components/phoneInput";
const { TextArea } = Input;
const { Item } = Form;
const { Option } = Select;
function disabledDate(current) {
  // Can not select days before today and today
  return current < moment().startOf("day");
}
var view = function () {
  this.setValidations();
  const {
    form,
    hideModal,
    data,
    translate,
    fetchingCustomers,
    view,
    role,
    permissions,
    CURRENCY,
    uid,
  } = this.props;
  const { symbol } =
    getAllISOCodes().find((item) => {
      return item.currency === CURRENCY;
    }) || {};

  const { customerProducts, customers, partReactKey, serviceReactKey } =
    this.state;
  const {
    customer,
    key,
    date,
    jobStatus,
    sign,
    faultReported,
    faultDiagnosed,
    serviceDetails,
    repairCompletedDate,
    labourTransportCosts,
    generalRemarks,
    attachment,
    corporateNumber,
    parts,
    services,
    product,
    assignedTo,
    discount,
    siteAddress,
    customerJobNumber,
    customerPoNumber,
    preferredAppointmentDate,
    serviceType,
    category,
    appliance,
    applianceBrand,
    applianceType,
    applianceConnection,
    applianceModelNumber,
    applianceSerialNumber,
    faultCode,
    scheduledDate,
    revisitDate,
    manualAdjustment,
    siteContactName,
    sitePhoneNumber,
    siteMobileNumber,
    partsPreordered,
    engineerETA,
    repairLimit,
    claimNumber,
    policyNumber,
    postCode,
  } = this.validations;
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  let initialCustomerValue = "";
  if (data) {
    initialCustomerValue =
      typeof data.customer === "object" ? "create-new" : data.customer;
  }
  const customerValue = getFieldValue("customer") || initialCustomerValue;
  let productValue = getFieldValue("product") || (data && data.product);
  if (productValue) {
    productValue = parseJSON(productValue);
  }
  const isTradeCustomer = !isPrivateCustomer(this.state.customerType);
  const statusValue = getFieldValue("jobStatus") || (data && data.jobStatus);
  const disabled =
    /*(data && data.jobStatus === "Completed") ||*/ view ||
    !["admin", "service"].includes(role.toLowerCase());
  const partsValue =
    this.state
      .partsValue; /*getFieldValue("parts") || (data && data.parts) || [];*/
  const invoiceItems = this.state.invoiceItems;
  const discountValue = getFieldValue("discount") || 0;
  const servicesValue =
    getFieldValue("services") || (data && data.services) || [];
  const labourTransportCostsValue =
    getFieldValue("labourTransportCosts") ||
    (data && data.labourTransportCosts);
  const array = [
    ...(invoiceItems || []).map((item) => {
      const { partName, partCost, quantity } = item;
      return {
        label: partName,
        value:
          parseFloat(isNaN(partCost) ? 0 : partCost) *
          (isNaN(quantity) ? 0 : quantity),
      };
    }),
  ];

  if (servicesValue) {
    servicesValue.map((item) => {
      const { title, price } = item;
      array.push({
        label: title,
        value: price,
      });
    });
  }
  if (labourTransportCostsValue) {
    array.push({
      label: "Labour Transport Costs",
      value: parseFloat(labourTransportCostsValue),
    });
  }
  const total = array.reduce((sum, item) => {
    const { value } = item;
    return sum + value;
  }, 0);
  const adjustment = getFieldValue("manualAdjustment") || 0;
  const showToEngineer = !(role.toLowerCase() === "engineer" && !view);
  const isAdmin = role && ["admin", "service"].includes(role.toLowerCase());
  const isCustomer = role && role.toLowerCase() === "customer";
  const jobStatuses = [
    ...(isAdmin
      ? JOB_STATUSES
      : JOB_STATUSES.filter((item) => {
          return item !== "Pending";
        })),
  ];
  const assignedToValue =
    getFieldValue("assignedTo") || (data && data.assignedTo) || [];
  const repairLimitValue = getFieldValue("repairLimit");
  return (
    <div className="new-form form service">
      <Form onSubmit={this.handleSubmit.bind(this)}>
        {/* {data && data.jobStatus === "Completed" && (
          <p className="label done">
            <Icon type="check-circle" /> This job is already completed. It can
            not be edited.
          </p>
        )} */}
        <div className="section">
          <p className="label">Service Job Details</p>
          <div className="content">
            <div className="inline">
              {/* <Item hasFeedback={true} label="EW/Customer/Corporate Number">
                {getFieldDecorator(
                  "corporateNumber",
                  corporateNumber
                )(
                  <Input
                    disabled={disabled}
                    className="input"
                    placeholder="Enter Number"
                  />
                )}
              </Item> */}
              <Item hasFeedback={true} label="ASG Job Number">
                {getFieldDecorator(
                  "key",
                  key
                )(
                  <Input
                    disabled={true}
                    className="input"
                    placeholder="Enter Asg Job Number"
                  />
                )}
              </Item>
              {isAdmin && (
                <React.Fragment>
                  <Item hasFeedback={true} label="Customer Job Number">
                    {getFieldDecorator(
                      "customerJobNumber",
                      customerJobNumber
                    )(
                      <Input
                        disabled={disabled}
                        className="input"
                        placeholder="Enter Customer Job Number"
                      />
                    )}
                  </Item>
                  <Item hasFeedback={true} label="Customer PO Number">
                    {getFieldDecorator(
                      "customerPoNumber",
                      customerPoNumber
                    )(
                      <Input
                        disabled={disabled}
                        className="input"
                        placeholder="Enter Customer PO Number"
                      />
                    )}
                  </Item>
                </React.Fragment>
              )}
              <Item hasFeedback={true} label="Claim Number">
                {getFieldDecorator(
                  "claimNumber",
                  claimNumber
                )(<Input className="input" placeholder="Enter Claim Number" />)}
              </Item>
              <Item hasFeedback={true} label="Plan Number">
                {getFieldDecorator(
                  "policyNumber",
                  policyNumber
                )(
                  <Input className="input" placeholder="Enter Plan Number" />
                )}
              </Item>
            </div>
            <div className="inline">
              {data && data.invoiceNumber && (
                <Item hasFeedback={true} label="Invoice Number">
                  <Input
                    className="input"
                    value={data.invoiceNumber}
                    disabled={true}
                  />
                </Item>
              )}
              <Item hasFeedback={true} label="Date Added">
                {getFieldDecorator(
                  "date",
                  date
                )(<DatePicker disabled={disabled} />)}
              </Item>
              <Item hasFeedback={true} label="Preferred Appointment Date">
                {getFieldDecorator(
                  "preferredAppointmentDate",
                  preferredAppointmentDate
                )(
                  <DatePicker disabled={disabled} disabledDate={disabledDate} />
                )}
              </Item>
              <Item hasFeedback={true} label="Service Type">
                {getFieldDecorator(
                  "serviceType",
                  serviceType
                )(
                  <Select
                    disabled={disabled}
                    className="select"
                    placeholder="Select Service Type"
                  >
                    {[
                      ...SERVICE_TYPES.map((item, index) => {
                        return (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        );
                      }),
                    ]}
                  </Select>
                )}
              </Item>
              <Item hasFeedback={true} label="Category">
                {getFieldDecorator(
                  "category",
                  category
                )(
                  <Select
                    disabled={disabled}
                    className="select"
                    placeholder="Select Category"
                  >
                    {[
                      ...CATEGORY.map((item, index) => {
                        return (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        );
                      }),
                    ]}
                  </Select>
                )}
              </Item>
            </div>
            {!(role.toLowerCase() === "engineer" && isTradeCustomer) && (
              <div className="inline">
                <Item hasFeedback={true} label="Customer Name">
                  {getFieldDecorator(
                    "customer",
                    customer
                  )(
                    <Select
                      showSearch={true}
                      onSearch={this.onSearch}
                      onChange={this.getCustomerDetails}
                      disabled={disabled}
                      className="select"
                      placeholder="Search by Name or Phone"
                      showArrow={false}
                      filterOption={false}
                      loading={fetchingCustomers}
                    >
                      {[
                        ...(customers || []).map((customer, index) => {
                          const { key, customerName } = customer;
                          return (
                            <Option key={index} value={key}>
                              {customerName + " (" + key + ")"}
                            </Option>
                          );
                        }),
                      ]}
                      {isUserAllowed(
                        {
                          permissions: ["CUSTOMER_CREATE"],
                        },
                        {
                          permissions,
                          role,
                        }
                      ) && (
                        <Option
                          placeholder="Search Customer By Mobile"
                          value={"create-new"}
                          key="create-new"
                        >
                          {"Or Create New Customer"}
                        </Option>
                      )}
                    </Select>
                  )}
                </Item>
                {customerValue && <PhoneLink value={customerValue} />}
              </div>
            )}
            {(customerValue === "create-new" || view || disabled) &&
              (isAdmin
                ? true
                : role.toLowerCase() === "engineer" &&
                  isPrivateCustomer(this.state.customerType)) && (
                <div className="appended-form">
                  <p>Customer Form</p>
                  <CustomerForm
                    service={true}
                    disabled={disabled}
                    translate={translate}
                    onProductChange={(products) => {
                      this.setState({ customerProducts: products });
                    }}
                    onCustomerTypeChange={(type) => {
                      this.setState({ customerType: type });
                    }}
                    data={(function () {
                      try {
                        if (!data) {
                          return;
                        }
                        let customer = {
                          ...data,
                        };
                        if (typeof data.customer === "string") {
                          let customerData = customers.find(
                            (item) => item.key === data.customer
                          );
                          customer = {
                            ...customerData,
                            ...data,
                          };
                        } else {
                          customer = {
                            ...customer,
                            ...data.customer,
                          };
                        }
                        if (data.customerKey) {
                          customer = {
                            ...customer,
                            key: data.customerKey,
                          };
                        }
                        return customer;
                      } catch (e) {
                        console.log(e);
                      }
                    })()}
                    wrappedComponentRef={(ref) => {
                      this.customerFormRef = ref;
                    }}
                  />
                </div>
              )}
            {isTradeCustomer && (
              <div className="inline">
                <Item hasFeedback={true} label="Site Contact Name">
                  {getFieldDecorator(
                    "siteContactName",
                    siteContactName
                  )(
                    <Input
                      disabled={disabled}
                      className="input"
                      placeholder="Enter Site Contact Name"
                    />
                  )}
                </Item>
                <Item hasFeedback={true} label="Site Phone Number">
                  {getFieldDecorator(
                    "sitePhoneNumber",
                    sitePhoneNumber
                  )(
                    <PhoneInput
                      disabled={disabled}
                      placeholder="Enter Site Phone Number"
                    />
                  )}
                </Item>
                <Item hasFeedback={true} label="Site Mobile Number">
                  {getFieldDecorator(
                    "siteMobileNumber",
                    siteMobileNumber
                  )(
                    <PhoneInput
                      disabled={disabled}
                      placeholder="Enter Site Mobile Number"
                    />
                  )}
                </Item>

                {/* <Item hasFeedback={true} label="Site Post Code">
                    {getFieldDecorator(
                      "postCode",
                      postCode
                    )(
                      <Input
                        disabled={disabled}
                        className="input"
                        placeholder="Site Post Code"
                      />
                    )}
                  </Item> */}
              </div>
            )}
            {isTradeCustomer && (
              <div className="inline">
                <Item hasFeedback={true} label="Site Address">
                  {getFieldDecorator(
                    "siteAddress",
                    siteAddress
                  )(
                    <Input
                      disabled={disabled}
                      className="input"
                      placeholder="Enter Site Address"
                    />
                  )}
                </Item>
              </div>
            )}
          </div>
        </div>
        {customerValue && (
          <React.Fragment>
            <div className="section">
              <p className="label">Job Status</p>
              <div className="content">
                <div className="inline">
                  <Item hasFeedback={true} label="Job Status">
                    {getFieldDecorator(
                      "jobStatus",
                      jobStatus
                    )(
                      <Select
                        disabled={disabled && showToEngineer}
                        className="select"
                        placeholder={"Select Job Status"}
                      >
                        {jobStatuses.map((status, index) => {
                          return (
                            <Option key={index} value={status}>
                              {status}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Item>

                  {statusValue === "Completed" && isAdmin && (
                    <Item hasFeedback={true} label="Repair Complete Date">
                      {getFieldDecorator(
                        "repairCompletedDate",
                        repairCompletedDate
                      )(
                        <DatePicker
                          disabled={disabled && showToEngineer}
                          disabledDate={disabledDate}
                          showTime={{
                            use12Hours: true,
                          }}
                        />
                      )}
                    </Item>
                  )}
                  {statusValue === "Scheduled" && (
                    <Item hasFeedback={true} label="Scheduled Date">
                      {getFieldDecorator(
                        "scheduledDate",
                        scheduledDate
                      )(
                        <DatePicker
                          showTime={{
                            use12Hours: true,
                          }}
                          disabled={disabled && showToEngineer}
                          disabledDate={disabledDate}
                          format={DEFAULT_FORMAT}
                        />
                      )}
                    </Item>
                  )}
                  {statusValue && statusValue === "Revisit Scheduled" && (
                    <Item hasFeedback={true} label="Revisit Date">
                      {getFieldDecorator(
                        "revisitDate",
                        revisitDate
                      )(
                        <DatePicker
                          showTime={{
                            use12Hours: true,
                          }}
                          format={DEFAULT_FORMAT}
                          disabled={disabled && showToEngineer}
                          disabledDate={disabledDate}
                        />
                      )}
                    </Item>
                  )}
                </div>
                {statusValue === "Completed" && (
                  <Item hasFeedback={true} label="Signature">
                    {getFieldDecorator(
                      "sign",
                      sign
                    )(<SignatureCapture disabled={disabled} />)}
                  </Item>
                )}
              </div>
            </div>

            <div className="section">
              <p className="label">Appliance Details</p>
              <div className="content">
                <div className="inline">
                  <Item hasFeedback={true} label="Appliance">
                    {getFieldDecorator(
                      "appliance",
                      appliance
                    )(
                      <Select
                        disabled={disabled}
                        className="select"
                        placeholder="Select Appliance"
                      >
                        {[
                          ...APPLIANCE.map((item, index) => {
                            return (
                              <Option key={index} value={item}>
                                {item}
                              </Option>
                            );
                          }),
                        ]}
                      </Select>
                    )}
                  </Item>
                  <Item hasFeedback={true} label="Brand">
                    {getFieldDecorator(
                      "applianceBrand",
                      applianceBrand
                    )(
                      <Input
                        disabled={disabled}
                        className="input"
                        placeholder="Enter Brand"
                      />
                    )}
                  </Item>
                  <Item hasFeedback={true} label="Type">
                    {getFieldDecorator(
                      "applianceType",
                      applianceType
                    )(
                      <Select
                        disabled={disabled}
                        className="select"
                        placeholder="Select Type"
                      >
                        {[
                          ...APPLIANCE_TYPE.map((item, index) => {
                            return (
                              <Option key={index} value={item}>
                                {item}
                              </Option>
                            );
                          }),
                        ]}
                      </Select>
                    )}
                  </Item>
                  <Item hasFeedback={true} label="Connection">
                    {getFieldDecorator(
                      "applianceConnection",
                      applianceConnection
                    )(
                      <Select
                        disabled={disabled}
                        className="select"
                        placeholder="Select Connection"
                      >
                        {[
                          ...CONNECTION.map((item, index) => {
                            return (
                              <Option key={index} value={item}>
                                {item}
                              </Option>
                            );
                          }),
                        ]}
                      </Select>
                    )}
                  </Item>
                </div>
                <div className="inline">
                  <Item hasFeedback={true} label="Model Number">
                    {getFieldDecorator(
                      "applianceModelNumber",
                      applianceModelNumber
                    )(
                      <Input
                        disabled={disabled && showToEngineer}
                        className="input"
                        placeholder="Enter Model Number"
                      />
                    )}
                  </Item>
                  <Item hasFeedback={true} label="Serial Number">
                    {getFieldDecorator(
                      "applianceSerialNumber",
                      applianceSerialNumber
                    )(
                      <Input
                        disabled={disabled && showToEngineer}
                        className="input"
                        placeholder="Enter Serial Number"
                      />
                    )}
                  </Item>
                </div>
              </div>
            </div>
            {isAdmin && (
              <div className="section">
                <p className="label">Services Performed</p>
                <div className="content">
                  <div className="inline">
                    <Item label="Services" hasFeedback>
                      {getFieldDecorator(
                        "services",
                        services
                      )(
                        <ClientSearch
                          disabled={disabled}
                          key={serviceReactKey} //SetFieldsValue and resetFields also doesn't work somehow  without random key
                          placeholder="Select Services"
                          entityName="product-services"
                          searchField="title"
                          keyName="key"
                          filterData={(service) => {
                            const { product } = service;
                            if (product && product.length) {
                              return (
                                service.enabled &&
                                product.indexOf(productValue.key) !== -1
                              );
                            }
                            return true;
                          }}
                          formatter={(item) => {
                            const { key, title, price } = item;
                            const value = {
                              key,
                              price: parseFloat(price),
                              title,
                            };
                            return {
                              label: title,
                              value,
                            };
                          }}
                        />
                      )}
                    </Item>
                    {isUserAllowed(
                      {
                        permissions: ["SERVICE_EDIT"],
                      },
                      {
                        permissions,
                        role,
                      }
                    ) && (
                      <Item label="Assigned To" hasFeedback>
                        {getFieldDecorator(
                          "assignedTo",
                          assignedTo
                        )(
                          <AutoSearch
                            disabled={disabled}
                            placeholder="Type to Search Assignees"
                            entityName="users"
                            searchField="displayName"
                            formatter={(item) => {
                              const { uid, displayName, email } = item;
                              return {
                                label: `${displayName} (${email})`,
                                value: uid,
                              };
                            }}
                            filterData={({ role, emailVerified }) => {
                              return (
                                !!emailVerified &&
                                (role || "").toLowerCase() === "engineer"
                              );
                            }}
                          />
                        )}
                      </Item>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="section">
              <p className="label">Repair Details</p>
              <div className="content">
                <div className="inline">
                  <Item hasFeedback={true} label="Fault Code">
                    {getFieldDecorator(
                      "faultCode",
                      faultCode
                    )(
                      <Input
                        disabled={disabled}
                        className="input"
                        placeholder="Enter Fault Code"
                      />
                    )}
                  </Item>
                  <Item hasFeedback={true} label="Fault Reported">
                    {getFieldDecorator(
                      "faultReported",
                      faultReported
                    )(
                      <TextArea
                        disabled={disabled}
                        placeholder="Enter Fault Reported"
                        className="input"
                      />
                    )}
                  </Item>
                  <Item hasFeedback={true} label="Fault Diagnosed">
                    {getFieldDecorator(
                      "faultDiagnosed",
                      faultDiagnosed
                    )(
                      <TextArea
                        disabled={disabled && showToEngineer}
                        placeholder="Enter Fault Diagnosed"
                        className="input"
                      />
                    )}
                  </Item>
                  <Item hasFeedback={true} label="Work Carried Out">
                    {getFieldDecorator(
                      "serviceDetails",
                      serviceDetails
                    )(
                      <TextArea
                        disabled={disabled && showToEngineer}
                        placeholder="Enter Details"
                        className="input"
                      />
                    )}
                  </Item>
                </div>
                <div className="inline">
                  <Item
                    hasFeedback={true}
                    label={"Repair Limit (" + symbol + ")"}
                  >
                    {getFieldDecorator(
                      "repairLimit",
                      repairLimit
                    )(
                      <InputNumber
                        disabled={disabled}
                        className="input"
                        min={0}
                        placeholder={"Enter Repair Limit (" + symbol + ")"}
                      />
                    )}
                  </Item>

                  <Item hasFeedback={true} label="Parts Pre-ordered?">
                    {getFieldDecorator(
                      "partsPreordered",
                      partsPreordered
                    )(
                      <Select
                        disabled={disabled && showToEngineer}
                        className="select"
                        placeholder={"Parts Pre-ordered?"}
                      >
                        {PARTS_PREORDERED.map((status, index) => {
                          return (
                            <Option key={index} value={status}>
                              {status}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Item>
                  <Item hasFeedback={true} label="Engineer to Call with ETA">
                    {getFieldDecorator(
                      "engineerETA",
                      engineerETA
                    )(
                      <Select
                        disabled={disabled}
                        className="select"
                        placeholder={"Engineer to Call with ETA"}
                      >
                        {ENGINEER_ETA.map((status, index) => {
                          return (
                            <Option key={index} value={status}>
                              {status}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Item>
                </div>
              </div>
            </div>
            {!isCustomer && (
              <div className="section">
                <p className="label">Part Details</p>
                <div className="content">
                  <Item label="Parts Search">
                    <ClientSearch
                      disabled={disabled && showToEngineer}
                      mode={"default"}
                      placeholder="Select Parts"
                      entityName="parts"
                      searchField="partName"
                      onChange={(part, setValue) => {
                        const allowedQuantity = (part.quantity || []).find(
                          ({ userId }) => {
                            return assignedToValue.indexOf(userId) !== -1;
                          }
                        );

                        const partsValue = [...(this.state.partsValue || [])];
                        const partIndex = partsValue.findIndex(
                          ({ key }) => part.key === key
                        );
                        if (partIndex !== -1) {
                          if (
                            allowedQuantity &&
                            partsValue[partIndex].quantity + 1 <=
                              allowedQuantity.quantity
                          ) {
                            partsValue[partIndex] = {
                              ...partsValue[partIndex],
                              quantity: partsValue[partIndex].quantity + 1,
                            };
                          } else {
                            message.error("Quantity limit exceeded");
                          }
                        } else {
                          partsValue.push({
                            partKey: part.partKey,
                            key: part.key,
                            partName: part.partName,
                            partCost:
                              part.partCost *
                              (1 + (part.customerMarkup || 0) / 100),
                            quantity: 1,
                          });
                        }
                        this.setState({
                          partsValue,
                        });
                        setValue(undefined);
                      }}
                      filterData={(part) => {
                        const { quantity } = part;
                        const quantityItem = quantity.find(({ userId }) => {
                          return assignedToValue.indexOf(userId) !== -1;
                        });
                        return quantityItem && quantityItem.quantity > 0;
                      }}
                      formatter={(part) => {
                        const { partName, quantity, key } = part;
                        return {
                          label:
                            partName +
                            " Quantity - (" +
                            quantity.find(({ userId }) => {
                              return assignedToValue.indexOf(userId) !== -1;
                            }).quantity +
                            ")",
                          value: part,
                        };
                      }}
                    />
                  </Item>

                  {/* {getFieldDecorator(
                      "parts",
                      parts
                    )(
                      <ClientSearch
                        disabled={disabled}
                        key={partReactKey} //SetFieldsValue and resetFields also doesn't work somehow  without random key
                        placeholder="Select Parts"
                        entityName="parts"
                        searchField="name"
                        filterData={(part) => {
                          const { product } = part;
                          if (product && product.length) {
                            return (
                              part.enabled &&
                              product.indexOf(productValue.key) !== -1
                            );
                          }
                          return true;
                        }}
                        formatter={(item) => {
                          const { key, make, price, name } = item;
                          const value = {
                            partCost: price,
                            partKey: key,
                            partName: name,
                          };
                          return {
                            label: name + " (" + make + ")",
                            value,
                          };
                        }}
                      />
                    )} */}
                  {/* {partsValue && partsValue.length > 0 && ( */}
                  <div className="inline">
                    <Item
                      label="Parts"
                      extra="Grey highlighted parts are manually added and are not present in the system"
                      hasFeedback
                    >
                      <MultiRecordForm
                        className="parts-form"
                        //disableAdd={true}
                        disabled={disabled && showToEngineer}
                        wrappedComponentRef={(ref) => {
                          this.partsForm = ref;
                        }}
                        onAdd={() => {
                          if (
                            1.2 * (total - (discountValue || 0) - adjustment) >
                            repairLimitValue
                          ) {
                            Modal.warning({
                              title: "Warning",
                              content:
                                "Job has exceeded repair limit and quote to customer required",
                            });
                          }
                        }}
                        onChange={(value) => {
                          if (isDomesticAndGeneral(this.state.customer)) {
                            this.setState({
                              partsValue: value,
                              invoiceItems: [
                                // ...this.state.invoiceItems.filter(
                                //   ({ isPart }) => !isPart
                                // ),
                                CALLOUT_PART,
                                ...(value || []).map((item) => {
                                  return {
                                    ...item,
                                    //isPart : true,
                                    partCost: getDomesticAndGeneralPartCost(
                                      item.partCost
                                    ),
                                  };
                                }),
                                /*.filter(
                                  (item) =>
                                    item.partName &&
                                    !this.state.invoiceItems.find(
                                      ({ partName, isPart }) =>
                                        isPart && partName === item.partName
                                    )
                                ).map()*/
                              ],
                            });
                          } else {
                            this.setState({
                              partsValue: value,
                            });
                          }
                        }}
                        data={partsValue || []}
                        fields={(translate, item, index) => {
                          const disabled =
                            this.state.partsValue &&
                            this.state.partsValue[index] &&
                            this.state.partsValue[index].key;

                          return [
                            {
                              key: "key",
                              inputProps: {
                                disabled,
                              },
                              itemProps: {
                                style: {
                                  display: "none",
                                },
                                className: disabled ? "" : "manual-added-part",
                              },
                            },
                            {
                              key: "partKey",
                              label: "Part Code",
                              placeholder: "Part Code",
                              inputProps: {
                                disabled,
                              },
                            },
                            {
                              key: "quantity",
                              label: "Quantity",
                              placeholder: "Quantity",
                              type: "number",
                              inputProps: {
                                disabled,
                              },
                            },
                            {
                              key: "partCost",
                              label: "Part Cost (" + symbol + ")",
                              placeholder: "Part Cost",
                              type: "number",
                              inputProps: {
                                disabled,
                              },
                            },
                            {
                              key: "partName",
                              label: "Part Description",
                              placeholder: "Part Description",
                              inputProps: {
                                disabled,
                              },
                            },
                          ];
                        }}
                        getRules={(key, num, translate, { label }) => [
                          {
                            required: key !== "key",
                            message: label + " is required",
                          },
                        ]}
                      />
                    </Item>
                  </div>
                  {/* )} */}

                  {/* <Item hasFeedback={true} label="">
                    <Button className="btn green-btn save">Add New</Button>
                  </Item> */}
                </div>
              </div>
            )}

            {isAdmin && (
              <div className="section">
                <p className="label">Invoice Item Details</p>
                <div className="content">
                  <div className="inline">
                    <Item label="Invoice Items" hasFeedback>
                      <MultiRecordForm
                        disabled={
                          (disabled && showToEngineer) ||
                          isDomesticAndGeneral(this.state.customer)
                        }
                        wrappedComponentRef={(ref) => {
                          this.invoiceItemsForm = ref;
                        }}
                        onChange={(value) => {
                          this.setState({
                            invoiceItems: value,
                          });
                        }}
                        data={invoiceItems || []}
                        fields={(translate) => [
                          /*{
                            key: "partKey",
                            label: "Part Code",
                            placeholder: "Part Code",
                          },*/
                          {
                            key: "quantity",
                            label: "Quantity",
                            placeholder: "Quantity",
                            type: "number",
                          },
                          {
                            key: "partCost",
                            label: "Item Cost (" + symbol + ")",
                            placeholder: "Item Cost",
                            type: "number",
                          },
                          {
                            key: "partName",
                            label: "Item Description",
                            placeholder: "Item Description",
                          },
                        ]}
                        getRules={(key, num, translate, { label }) => [
                          {
                            required: true,
                            message: label + " is required",
                          },
                        ]}
                      />
                    </Item>
                  </div>
                </div>
              </div>
            )}

            {/* {!isCustomer && (
              <div className="section">
                <p className="label">Additional Costs</p>
                <div className="content">
                  <div className="inline">
                    <Item
                      hasFeedback={true}
                      label={"Labour Transport Costs (" + symbol + ")"}
                    >
                      {getFieldDecorator(
                        "labourTransportCosts",
                        labourTransportCosts
                      )(
                        <InputNumber
                          disabled={disabled}
                          className="input"
                          placeholder="Enter Labour Transport Cost"
                        />
                      )}
                    </Item>
                  </div>
                </div>
              </div>
            )} */}

            <div className="section">
              <p className="label">Job Notes</p>
              <div className="content">
                <Item hasFeedback={true} label="Enter Job Notes">
                  {getFieldDecorator(
                    "generalRemarks",
                    generalRemarks
                  )(
                    <TextArea
                      disabled={disabled && showToEngineer}
                      placeholder="Enter Remarks"
                      className="input"
                    />
                  )}
                </Item>
              </div>
            </div>

            <div className="section">
              <p className="label">
                Attachments (Please ensure you obtain rating plate photo)
              </p>
              <div className="content">
                <Item hasFeedback={true}>
                  {getFieldDecorator(
                    "attachment",
                    attachment
                  )(
                    <FilePicker
                      uploadProps={{
                        accept: "*/*",
                        multiple: true,
                        disabled: disabled && showToEngineer,
                      }}
                      disabled={disabled && showToEngineer}
                    />
                  )}
                </Item>
              </div>
            </div>
            {isAdmin && (
              <div>
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="section">
                      <p className="label">Credit</p>
                      <div className="content">
                        <div className="inline">
                          <Item
                            hasFeedback={true}
                            label={"Credit (" + symbol + ")"}
                          >
                            {getFieldDecorator(
                              "discount",
                              discount
                            )(
                              <InputNumber
                                disabled={disabled}
                                className="input"
                                min={0}
                                max={total}
                                placeholder={"Enter Credit (" + symbol + ")"}
                              />
                            )}
                          </Item>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="estimation">
                      <div className="heading">
                        <p>Cost</p>
                      </div>
                      <div className="list">
                        {array.map((item, index) => {
                          const { label, value } = item;
                          return (
                            <div key={index} className="item">
                              <p className="label">{label}</p>
                              <p className="value">
                                {symbol} {value}
                              </p>
                            </div>
                          );
                        })}
                        {discountValue ? (
                          <div className="item">
                            <p className="label">Credit</p>
                            <p className="value">
                              {symbol} {discountValue}{" "}
                            </p>
                          </div>
                        ) : null}
                        {array.length === 0 ? (
                          <p className="empty">No Items Added</p>
                        ) : null}
                      </div>
                      {/* <div className="section">
                        <p className="label">Finalize Cost</p>
                        <div className="content">
                          <div className="inline">
                            <Item
                              hasFeedback={true}
                              label={"Price Adjustment (" + symbol + ")"}
                            >
                              {getFieldDecorator(
                                "manualAdjustment",
                                manualAdjustment
                              )(
                                <InputNumber
                                  disabled={disabled}
                                  className="input"
                                  min={0}
                                  max={total - (discountValue || 0)}
                                  placeholder={
                                    "Price Adjustment (" + symbol + ")"
                                  }
                                />
                              )}
                            </Item>
                          </div>
                        </div>
                      </div> */}
                      <div className="total">
                        <p className="label">VAT</p>
                        <p className="value">
                          {symbol}
                          {(
                            0.2 *
                            (total - (discountValue || 0) - adjustment)
                          ).toFixed(2)}
                        </p>
                      </div>
                      <div className="total">
                        <p className="label">Total</p>
                        <p className="value">
                          {symbol}{" "}
                          {(
                            1.2 *
                            (total - (discountValue || 0) - adjustment)
                          ).toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </React.Fragment>
        )}
        {!(disabled && showToEngineer) && (
          <div className="actions">
            <Button htmlType={"submit"} className="btn green-btn save">
              Save
            </Button>
            <Button
              htmlType={"button"}
              className="btn red-btn-text"
              onClick={hideModal}
            >
              Cancel
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};
export default view;
