import React from "react";
import "./styles.scss";
import { Button, Form, Select } from "antd";

const { Item } = Form;
var view = function () {
  const { record, form } = this.props;
  const { email, customerKey } = record || {};
  const { getFieldDecorator } = form;
  return (
    <div className="send" onSubmit={this.send}>
      <Form>
        <div className="form">
          <Item hasFeedback={true} label="Email">
            {getFieldDecorator("email", {
              rules: [{ required: true, message: "Email is Required" }],
              initialValue: email,
            })(
              <Select className="select" placeholder = "Select Email">
                {[record.email, ...(record.emailAddresses || [])].map((item, index) => {
                  return (
                    <Select.Option key={index} value={item}>
                      {item}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </Item>
          {/* <Item hasFeedback={true} label="Phone Number">
                        {getFieldDecorator('phoneNo', {
                            initialValue: customerKey,
                        })(<Input className="input"/>)}
                    </Item> */}
          <div className="actions">
            <Button htmlType="submit" className="btn green-btn">
              Send
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default view;
