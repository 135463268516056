import React from "react";
import "./styles.scss";
import { Form, Input, Button, Select, Spin, Switch, InputNumber } from "antd";
import AutoSearch from "../../autoSearch";
import ClientSearch from "../../clientSearch";
import TagInput from "../../../components/tagInput";
import { emailRegex } from "../../../utils/common";
const { Option } = Select;
const { Item } = Form;
var view = function () {
  const {
    form,
    hideModal,
    service,
    disabled,
    data,
    role,
    onCustomerTypeChange,
  } = this.props;
  this.setValidations();
  const {
    customerName,
    address,
    homeNumber,
    key,
    officeNumber,
    email,
    products,
    createUser,
    customerType,
    companyName,
    emailAddresses,
    automaticInvoice,
    autoPaidInvoices,
    manualInvoicing,
    dueTerm
  } = this.validations;
  const { getFieldDecorator } = form;
  const isAdmin = role && role.toLowerCase() === "admin";
  return (
    <div className="new-form form">
      <Form onSubmit={this.handleSubmit.bind(this)}>
        <div className="inline">
          <Item hasFeedback={true} label="Customer Name">
            {getFieldDecorator(
              "customerName",
              customerName
            )(
              <Input
                disabled={disabled}
                className="input"
                placeholder="Enter Customer Name"
              />
            )}
          </Item>
          {isAdmin && (
            <Item hasFeedback={true} label="Customer Type">
              {getFieldDecorator(
                "customerType",
                customerType
              )(
                <Select
                  className="select"
                  placeholder={"Select Customer Type"}
                  onChange={(customerType) => {
                    if (onCustomerTypeChange instanceof Function) {
                      onCustomerTypeChange(customerType);
                    }
                  }}
                >
                  {["Private", "Trade"].map((type, index) => {
                    return (
                      <Option value={type} key={index}>
                        {type}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Item>
          )}
          <Item hasFeedback={true} label="Address">
            {getFieldDecorator(
              "address",
              address
            )(
              <Input
                disabled={disabled}
                className="input"
                placeholder="Enter Address"
              />
            )}
          </Item>
          {isAdmin && (
            <Item hasFeedback={true} label="Email">
              {getFieldDecorator(
                "email",
                email
              )(
                <Input
                  disabled={disabled}
                  className="input"
                  placeholder="Enter Email"
                />
              )}
            </Item>
          )}
        </div>

        <div className="inline">
          <Item hasFeedback={true} label="Home Phone Number">
            {getFieldDecorator(
              "homeNumber",
              homeNumber
            )(
              <Input
                disabled={disabled}
                className="input"
                placeholder="Enter Home Number"
              />
            )}
          </Item>
          <Item hasFeedback={true} label="Mobile Number">
            {getFieldDecorator(
              "key",
              key
            )(
              <Input
                disabled={disabled}
                className="input"
                placeholder="Enter Mobile Number"
              />
            )}
          </Item>
          <Item hasFeedback={true} label="Office Phone Number">
            {getFieldDecorator(
              "officeNumber",
              officeNumber
            )(
              <Input
                disabled={disabled}
                className="input"
                placeholder="Enter Office Number"
              />
            )}
          </Item>
          <Item hasFeedback={true} label="Invoice Due Term">
              {getFieldDecorator(
                "dueTerm",
                dueTerm
              )(
                <InputNumber
                  maxLength={3}
                  className="input"
                  placeholder="Enter due term for invoice"
                />
              )}
            </Item>
        </div>
        {!this.isPrivate() && isAdmin && (
          <div className="inline">
            <Item hasFeedback={true} label="Company Name">
              {getFieldDecorator(
                "companyName",
                companyName
              )(
                <Input
                  disabled={disabled}
                  className="input"
                  placeholder="Enter Company Name"
                />
              )}
            </Item>
            <Item hasFeedback={true} label="Email Addresses">
              {getFieldDecorator(
                "emailAddresses",
                emailAddresses
              )(
                <TagInput
                  regex={emailRegex}
                  disabled={disabled}
                  className="input"
                  placeholder="Enter Email Addresses"
                />
              )}
            </Item>           
          </div>
        )}

        {/* <Item hasFeedback={true} label="Products">
          {getFieldDecorator(
            "products",
            products
          )(
            <ClientSearch
              disabled={disabled}
              placeholder="Type to Search Products"
              entityName="products"
              formatter={(item) => {
                const { key, model } = item;
                return {
                  label: key + " (" + model + ")",
                  value: key,
                };
              }}
              onChangeValue={(values, fullData) => {
                if (onProductChange instanceof Function) {
                  onProductChange(
                    fullData.filter((item) => {
                      return values.indexOf(item.key) !== -1;
                    })
                  );
                }
              }}
            />
          )}
        </Item> */}
        {isAdmin && (
          <div className="inline">
            {!data && (
              <Item label="Create User" hasFeedback>
                {getFieldDecorator("createUser", createUser)(<Switch />)}
              </Item>
            )}
            <Item label="Auto Invoice Emails" hasFeedback>
              {getFieldDecorator(
                "automaticInvoice",
                automaticInvoice
              )(<Switch disabled={disabled} />)}
            </Item>
            <Item label="Manual Invoicing" hasFeedback>
              {getFieldDecorator(
                "manualInvoicing",
                manualInvoicing
              )(<Switch disabled={disabled} />)}
            </Item>
            <Item label="Auto Paid Invoices" hasFeedback>
              {getFieldDecorator(
                "autoPaidInvoices",
                autoPaidInvoices
              )(<Switch disabled={disabled} />)}
            </Item>
          </div>
        )}
        {disabled || service ? null : (
          <div className="actions">
            <Button htmlType={"submit"} className="btn green-btn save">
              Save
            </Button>
            <Button
              htmlType={"button"}
              className="btn cancel red-btn-text"
              onClick={hideModal}
            >
              Cancel
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};
export default view;
