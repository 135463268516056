import React from 'react'
import './styles.scss'
import { Form, Input, Button, Select } from 'antd'

const FormItem = Form.Item
const {Option} = Select
var view = function () {
    const {permissions} = this.state
    const {translate, form, hideModal, data} = this.props
    const {
        code,
        description,
        permissions: permissionValidation,
    } = this.validations
    const {getFieldDecorator, getFieldValue} = form

    return (
        <Form onSubmit={this.handleSubmit.bind(this)}>
            <div className="new-form roles-form">
                <div className="form">
                    <FormItem
                        hasFeedback={true}
                        label={translate('role.permission.title')}
                    >
                        {getFieldDecorator(
                            'permissions',
                            permissionValidation
                        )(
                            <Select
                                mode="multiple"
                                className="select multi-select"
                                placeholder={translate('role.permission.placeholder')}
                            >
                                {permissions.map((item, index) => {
                                    const {key, description, requiredPermissions} = item
                                    return (
                                        <Option key={index} value={key}>
                                            {`${description} (${
                                                (requiredPermissions || []).length
                                                } Required Permissions)`}
                                        </Option>
                                    )
                                })}
                            </Select>
                        )}
                    </FormItem>
                    <FormItem hasFeedback={true} label={translate('role.code.title')}>
                        {getFieldDecorator(
                            'code',
                            code
                        )(
                            <Input
                                maxLength={100}
                                type={'text'}
                                disabled={!!data}
                                placeholder={translate('role.code.title')}
                                className="input uppercase"
                            />
                        )}
                    </FormItem>
                    <FormItem
                        hasFeedback={true}
                        label={translate('role.description.title')}
                    >
                        {getFieldDecorator(
                            'description',
                            description
                        )(
                            <Input
                                maxLength={200}
                                placeholder={translate('role.description.title')}
                                className="input"
                            />
                        )}
                    </FormItem>
                    {
                        (this.getRequiredPermissions(getFieldValue('permissions')).length > 0) ? (
                            <div className="final-permissions">
                                <p className="label">Final Permissions:</p>
                                <ul>
                                    {
                                        this.getRequiredPermissions(getFieldValue('permissions')).map(
                                            (permission, index) => {
                                                return <li key={index} className="permission">{permission}</li>
                                            }
                                        )
                                    }
                                </ul>
                            </div>
                        ) : null
                    }
                </div>
                <div className="actions">
                    <Button htmlType={'submit'} className="btn green-btn">Save</Button>
                    <Button htmlType={'button'} onClick={hideModal} className="btn red-btn-text">Cancel</Button>
                </div>
            </div>
        </Form>
    )
}
export default view
