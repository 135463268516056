import React from 'react'
import './styles.scss'
import { Layout, Icon, Button, Form, Input } from 'antd'
import Link from 'core-components/link'

import ChangeLanguage from 'core-components/change-language'

const {Header} = Layout
var view = function () {
    const {scrolled} = this.state
    const {WEBSITELOGO, translate} = this.props
    const route = this.getCurrentRoute()
    return (
        <Header
            className={'public-header fixed ' + (scrolled ? 'scrolled animated slideInDown ' : '') + (route.routeKey === 'landing' ? 'landing' : '')}>
            <div className="bottom-section">
                <div className="container">
                    <div className="content">
                        <div className="left">
                            <div className="logo-container">
                                <Link routeKey="login" className="logo">
                                    {WEBSITELOGO && <img src={WEBSITELOGO} alt={'logo'}/>}
                                </Link>
                            </div>
                        </div>
                        <div className="right">
                            <ul>
                                <li>
                                    <ChangeLanguage/>
                                </li>
                                {/* <li>
                                    <a href="mailto:engineers@asgrepair.com" className="documentation"
                                       target="_blank">
                                        <Icon type="customer-service"/>
                                        <span className="text">Support</span>
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Header>
    )
}
export default view
