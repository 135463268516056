import React, { useState } from "react";
import preProcess from "containers/preprocess";
import "./styles.scss";
import { Button, message, Icon } from "antd";
import { createAction, ActionNames } from "app-redux/actions";
/**
 * @description Refresh Index
 * @type Container
 * @author Inderdeep
 */
const Main = function({
  apiRequest,
  markup,
  title,
  icon,
  successMessage,
  errorMessage,
  url,
  data
}) {
  const [loading, setLoading] = useState(false);
  const onClick = async () => {
    setLoading(true);
    try {
      const {payload} = await apiRequest({
        url,
        ...data
      });
      if(payload.status === 200){
        message.success(successMessage || "Request Processed successfully");
      } else {
        throw payload;
      }
    } catch (e) {
      message.error(errorMessage || "Error while processing your request");
      console.error(errorMessage || "Error while processing your request", e);
    }
    setLoading(false);
  };
  return (
    markup || (
      <Button loading={loading} onClick={onClick} className="btn green-btn">
        {icon && (
          <span className="icon">
            <Icon type={icon} theme="outlined" spin = {loading} />
          </span>
        )}
        {title || "Request"}
      </Button>
    )
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = dispatch => {
  return {
    apiRequest: data => {
      return dispatch(createAction(ActionNames.API_REQUEST, data));
    }
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = state => {
  return {};
};
Main.displayName = "Api-Trigger";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true
});
