/**
 This file is used to add middlewares. Add middlewares here
 */
import {routerMiddleware} from 'react-router-redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import useRouterHistory from 'react-router/lib/useRouterHistory';
import createBrowserHistory from 'history/lib/createBrowserHistory'
import {HOST} from '../api'
import io from 'socket.io-client';
import socketIO, {configureSocketListeners} from './socket-io';
import { isSSR } from 'utils/web';
const history = isSSR() ? undefined : useRouterHistory(createBrowserHistory)({
    basename : `/${window.app.basename || ''}`
})//browserHistory
/**
 * Configure socket-io middleware
 */
const socket = null;//null;io.connect(HOST);
export {
    configureSocketListeners,
    socket,
    history
};
const socketIoMiddleware = socketIO(socket);
/*
 Create History
 */
const middleware = [
    thunk,
    promise,
    routerMiddleware(history),
    //socketIoMiddleware
]
export default middleware
