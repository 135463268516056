import React from "react";
import LongText from "core-components/longtext";
import { formatDate } from "utils/date-util";
export default {
  entityName: "Enquiries",
  entity: "contact",
  role: "admin",
  // deleteAction: false,
  pagination: {
    type: "server",
  },
  defaultSort: {
    sort: "createdAt",
    sortType: "desc",
  },
  selection: true,
  columns: ({ sharedState: { translate } }) => [
    {
      title: translate("support.email.label"),
      dataIndex: "email",
    },
    {
      title: translate("support.name.label"),
      dataIndex: "name",
    },
    {
      title: translate("support.message.label"),
      dataIndex: "message",
      render: (message) => {
        return <LongText text={message} limit={25} title="Message" />;
      },
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (date) => <p>{formatDate(date, "DD/MM/YYYY hh:mm a")}</p>,
    },
  ],
  search: {
    filters: [
      {
        title: "Name",
        type: "input",
        key: "name",
      },
      {
        title: "Email",
        type: "input",
        key: "email",
      },
      {
        title: "Message",
        type: "input",
        key: "message",
      },
    ],
    searchOnSubmit: true,
  },
};
