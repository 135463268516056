import React from "react";
import { formatDate } from "../utils/date-util";
import ResendInvoice from "../containers/resendInvoice";
import MarkInvoiceAsPaid from "../containers/markInvoiceAsPaid";
import InvoiceHeader from "../containers/invoiceHeader";
import { Button, Select, Icon, Tooltip, Row } from "antd";
import BillTo from "../containers/bill-to";
import { ModalTrigger } from "core-components/modal";
import Print from "../components/print";
import { getAllISOCodes } from "iso-country-currency";
import { nFormatter } from "../utils/common";
import withSecurity from "containers/with-security";
import { getServiceCostSummary } from "containers/container-helpers/common";
import CsvGenerator from "../containers/csv-generator";
import moment from "moment";

const { Option } = Select;

const keys = {
  customerName: "*ContactName",
  id: "*InvoiceNumber",
  //email: "Email",
  generatedDate: "*InvoiceDate",
  dueDate: "*DueDate",
  partName: "*Description",
  quantity: "*Quantity",
  partCost: "*UnitAmount",
  accountCode : "*AccountCode",
  taxType : "*TaxType",
  //discount: "Credit",
  vat: "TaxAmount",
  currency: "Currency",
  //discountTax: "Tax Amount",
};

const createDiscountObj = (oldObj) => {
  let obj = {};
  obj.partName = "Credit";
  obj.quantity = 1;
  obj.vat = 0;
  obj.partCost = `-${oldObj.discount}`;
  return obj;
};

const createObjects = (obj) => {
  if (obj.invoiceItems && obj.invoiceItems.length > 0) {
    let allObjects = [];
    let newObj = obj.invoiceItems.map((item) => item);
    newObj.push(createDiscountObj(obj));
    newObj.forEach((item) => {
      allObjects.push({ ...obj, ...item });
    });
    return allObjects;
  } else {
    let newObj = [];
    newObj.push(createDiscountObj(obj));
    newObj.forEach((item) => Object.assign(item, obj));
    return obj;
  }
};

const formatData = (data) => {
  const newObjects = [];

  for (let i = 0; i < data.length; i++) {
    const newdata = createObjects(data[i]);
    newObjects.push(...newdata);
  }

  const newModifiedArray = newObjects.map((item) => {
    let newObj = {};

    newObj.currency = "GBP";
    newObj.discount = `${item.discount == 0 ? "0" : "-" + item.discount}`;
    newObj.accountCode = '200'
    newObj.taxType = '20% (VAT ON INCOME)'
    newObj.dueDate = moment(item.dueDate).format("DD/MM/YY");
    newObj.generatedDate = moment(item.generatedDate).format("DD/MM/YY");
    const sortedObj = { ...item, ...newObj };
    return sortedObj/*Object.keys(sortedObj)
      //.sort()
      .reduce((obj, key) => {
        obj[key] = sortedObj[key];
        return obj;
      }, {})*/;
  });

  return newModifiedArray;
};

export default {
  entityName: "Invoices",
  entity: "invoice",
  deleteAction: true,
  search: {
    filters: [
      {
        title: "Invoice Number",
        type: "input",
        key: "id",
      },
      {
        title: "Is Paid",
        type: "select",
        key: "isPaid",
        options: ["Yes", "No"].map((item, index) => {
          return (
            <Option key={index} value={(item === "Yes").toString()}>
              {item}
            </Option>
          );
        }),
      },
      {
        title: "Customer Name",
        type: "input",
        key: "customerName",
      },
      {
        title: "Contact Number",
        type: "input",
        key: "mobileNumber",
      },
      {
        title: "Invoice Date",
        type: "date",
        key: "createdDate",
        operator : "equals"
      },
    ],
    searchOnSubmit: true,
  },
  defaultSort: {
    sort: "timestamp",
    sortType: "desc",
  },
  columns: ({ sharedState }) => {
    const { config } = sharedState || {};
    const { configuration } = config || {};
    const { CURRENCY } = configuration || {};
    const { symbol } =
      getAllISOCodes().find((item) => {
        return item.currency === CURRENCY;
      }) || {};
    return [
      {
        title: "Invoice Number",
        dataIndex: "id",
      },
      {
        title: "Date",
        dataIndex: "date",
        render: (date) => <p>{formatDate(date, "DD/MM/YYYY")}</p>,
      },
      {
        title: "Invoice Amount",
        dataIndex: "totalPayable",
        render: (totalPayable) => {
          return <p>{symbol + nFormatter(totalPayable)}</p>;
        },
      },
      {
        title: "Customer Name",
        dataIndex: "customer",
        render: (customer, { customerName }) => {
          if (typeof customer === "object") {
            customer = customer.customerName;
          } else {
            customer = customerName;
          }
          return <p>{customer}</p>;
        },
      },
      {
        title: "Is Paid",
        dataIndex: "paid",
        render: (payment) => {
          return <p>{!!payment ? "Paid" : "Not Paid"}</p>;
        },
      },
      {
        title: "Sent",
        dataIndex: "sent",
        render: (payment) => {
          return <p>{!!payment ? "Sent" : "Not Sent"}</p>;
        },
      },
    ];
  },
  pagination: {
    type: "server",
  },
  headerActions: (parentProps) => [
    () => (
      <CsvGenerator
        api={"invoice"}
        columns={keys}
        formatData={formatData}
        query={{
          ...parentProps.getRequestQuery(),
          from: -1,
        }}
      />
    ),
    ({ props }) => {
      return (
        <div className="invoice-header">
          <Row gutter={16}>
            <InvoiceHeader {...props} />
          </Row>
        </div>
      );
    },
  ],
  rowActions: [
    MarkInvoiceAsPaid,
    ResendInvoice,
    ({ record }) => {
      const { key, customer, date, dueDate, parts, labourTransportCosts } =
        record;

      return (
        <Print
          template={"INVOICE" + (customer.customerType || "").toUpperCase()}
          data={{
            invoiceKey: key,
            ...record,
            customer:
              typeof customer === "string"
                ? {
                    ...record,
                    customerKey: customer,
                  }
                : customer,
            invoiceKey: record.key,
            date: formatDate(date, "DD MMM YYYY"),
            dueDate: formatDate(dueDate, "DD MMM YYYY"),
            // ...getServiceCostSummary(record),
          }}
        />
      );
    },
    withSecurity(
      (props) => {
        const { record } = props;
        return (
          <ModalTrigger
            content={<BillTo {...props} record={record} />}
            modalProps={{
              title: "Invoice To",
              footer: null,
            }}
          >
            <Tooltip placement="top" title={"Invoice To"}>
              <Button className="btn light-blue-btn-text">
                <Icon type="idcard" />
              </Button>
            </Tooltip>
          </ModalTrigger>
        );
      },
      {
        permissions: ["INVOICE_MANAGE"],
      }
    ),
  ],
  reducer: {
    stateKeys: ["config"],
  },
};
