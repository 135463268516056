import React, { Component } from "react";
import ComponentView from "./view";
import preProcess from "../../preprocess";
import { ActionNames, createAction } from "../../../redux/actions";
import { goToRoute, getRoute } from "../../../routes";
import MenuKeys from "../../sider/config";
import { isUserAllowed } from "../../with-security";
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      fromDate: null,
      toDate: null,
    };
    this.getDashboard = this.getDashboard.bind(this);
  }

  /**
   * ComponentDidMount Hook
   */
  async componentDidMount() {
    const { permissions, role } = this.props;
    if(role !== 'CUSTOMER'){
      this.getDashboard();
    } else {
      let menus = MenuKeys.filter((item) => {
        const routeProps = (getRoute(item.key) || {}).routeProps || {};
        return (
          isUserAllowed(
            {
              ...routeProps,
            },
            {
              permissions,
              role,
            }
          ) &&
          (item.isAllowed instanceof Function
            ? !!item.isAllowed(permissions, role)
            : true)
        );
      });
      if (menus.length > 0) {
        goToRoute(menus[0].key);
      }
    }
   
    
  }
  async getDashboard() {
    try {
      const action = await this.props.getDashboard({
        ...this.state,
      });
      if (action.error) {
        throw action;
      }
    } catch (e) {
      console.warn("Error while getting dashboard", e);
    }
  }
  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    getDashboard: (data) => {
      const action = createAction(ActionNames.GET_ENTITIES, {
        entityName: "dashboard",
        url: "stats",
        ...data,
      });
      action.type = ActionNames.GET_DASHBOARD;
      return dispatch(action);
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ user,dashboard }) => {
  const { permissions, role } = user || {};
  return {
    permissions,
    role,
    user,
    dashboard,
  };
};
Main.displayName = "Dashboard";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
