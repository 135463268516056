import React, {Component} from 'react';
import ComponentView from './view';
import preProcess from 'containers/preprocess';
import {ActionNames, createAction} from "app-redux/actions";
import {message} from 'antd';

/**
 * @description Container to update a entity
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {

    /**
     * Container
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            approved: false
        }
    }

    /**
     * ComponentDidMount Hook
     */
    componentDidMount() { 

    }

    /**
     * Update Entity
     */
    async updateEntity(data) {
        const {record, updateEntity, translate,entityName,entityIdPropName} = this.props;
        if(typeof data === "undefined"){
            console.warn("No data defined. Please define  data to be updated");
            return;
        }
        this.setState({
            loading: true
        });
        try {            
            const action = await updateEntity({
                entityId : record[entityIdPropName],
                entityName ,
                ...data
            });
            if (action.error) {
                throw action.payload;
            }
            message.success(translate("common.changes.save.success"));
        } catch (e) {
            console.error("Update Entity",e);
            message.error(translate("common.changes.save.error"));
        }
        this.setState({
            loading: false
        });
    }


    /**
     * Render Method
     * @returns {*}
     */
    render() {
        return (ComponentView.bind(this))();
    }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {
        updateEntity: (data) => {
            return dispatch(createAction(ActionNames.UPDATE_ENTITY, {
                ...data
            }))
        }
    }
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state,ownProps) => {
    let output = {};
    (ownProps.stateKeys||[]).map((key)=>{
        if(typeof key === "object" && key.fn instanceof Function){
            output[key.key] = key.fn(state);
        } else {
            output[key] = state[key];
        }        
    })
    return output;
};
Main.displayName = "Update-Entity";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
    connect: [mapStateToProps, bindAction],
    localize: true
});
