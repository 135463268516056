import React from "react";
import TestForm from "containers/test-form";
import DuplicateForm from "containers/duplicate-entity";
import { ModalTrigger } from "core-components/modal";
import { Button, Tooltip, Icon } from "antd";

export default {
  entity: "email-template",
  selection: false,
  deleteAction: false,
  addAction: false,
  rowActions: [
    (props) => {
      const { record, getData } = props;
      const { key } = record;
      return (
        <DuplicateForm
          entityName="email-template"
          entityId={key}
          getData={getData}
        />
      );
    },
    (props) => {
      const { record } = props;
      const { key } = record;
      return (
        <ModalTrigger
          modalProps={{
            title: "Test Email Template",
            footer: null,
            className: "test-email-template-modal small-width",
          }}
          content={
            <TestForm
              fieldName="to"
              actionData={{
                template: key,
                entityName: "emails",
                url: "test",
              }}
              rules={[
                {
                  required: true,
                  message: "Email is Required",
                },
                {
                  type: "email",
                  message: "Valid Email is Required",
                },
              ]}
              placeholder="Enter Email"
              label="Email"
            />
          }
        >
          <Tooltip title="Test">
            <Button className="btn blue-btn-text">
              <Icon type="file-done" />
            </Button>
          </Tooltip>
        </ModalTrigger>
      );
    },
  ],
  //addAction: false,
  defaultSort: {
    sort: "createdAt",
    sortType: "desc",
  },
  deleteAction: false,
  entityName: "Email Templates",
  columns: ({ sharedState: { translate } }) => [
    {
      title: translate("template.key.label"),
      dataIndex: "key",
    },
    {
      title: translate("template.from.label"),
      dataIndex: "subject",
    },
  ],
  formId: "TemplateForm",
  pagination: {
    type: "server",
  },
  search: {
    filters: [
      {
        title: "Code",
        type: "input",
        key: "key",
      },
    ],
    searchOnSubmit: true,
  },
};
